import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { COMMON_TEXT, BUTTON, WIDGET_SETTING, CREATE_DASHBOARD_TEMPLATE } from '../../../../app/const/text-common';
import { ProcessLoadingService } from '../../../../app/services/loading.service';
import { Router } from '@angular/router';
import { LocalStorageHelper } from '../../../../app/_helper/local-storage.helper';
import { ROUTE_PATH } from '../../../../app/const/route-path';
import { Office } from '../../../../app/models/response/office.ro';
import { TableData } from '../../../../app/models/table-model';
import { PivotTableConfig} from '../../../../app/models/response/widget.ro';
import { ButtonType, DataSourceType, DatasourceValueConfig, DateFormat, DialogType, GraphType, InputType, LambdaStatus, PrimengDateFormat, ScreenMode, SearchType, WidgetSelectDataType } from '../../../../app/enum/common-enum';
import { ButtonItem, ListItemParams, ModalParam, SearchParams, WidgetGraphSetting } from '../../../../app/models/common-model';
import { makePivotConfig, PivotTableData } from '../../../../app/_helper/helper';
import { WidgetResultService } from '../../../../app/services/modules/widget-result.service';
import { DataSourceService } from '../../../../app/services/modules/data-source.service';
import { FilterConditionRequest, WidgetDetail, WidgetRequest, WidgetResultRequest } from '../../../../app/models/request/widget.dto';
import Utils from '../../../../app/util/utils';
import { WidgetService } from '../../../../app/services/modules/widget.service';
import { cloneDeep, join, orderBy, uniqBy } from 'lodash';
import { MstCommonService } from '../../../../app/services/modules/mstcommon.service';
import { ConfirmDialogComponent } from '../../../../app/component/common/confirm-dialog/confirm-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ColFilterGroup, customPeriod, DATA_SOURCE_DEFAULT_HEADERS, MstFilterDataTimeItem } from '../../../../..../../app/const/const';
import { FOLDER_TYPE } from '../../../../app/models/response/folder.ro';
import { FolderService } from '../../../../app/services/modules/folder.service';
import { AuthenticationService } from '../../../../app/services/authentication.service';
import { Subject } from 'rxjs';
import { ErrorHandleService } from '../../../../app/services/error-handle.service';
import { StepFunctionService } from '../../../../app/services/modules/step-function.service';
import { ClearProcessService } from '../../../../app/services/clear-process.service';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { DialogModalComponent } from '../../../../app/component/common/dialog-modal/dialog-modal.component';
import { DashboardService } from '../../../../app/services/modules/dashboard.service';
import { DashboardDetailModel } from '../../../../app/models/response/dashboard.ro';
import { v4 } from 'uuid';
import { OfficeAPIService } from 'src/app/services/modules/office.service';
import * as moment from 'moment';
import { CorpMstService } from 'src/app/services/modules/corpmst.service';
import { DialogSelectDataSourceComponent } from '../dialog-select-datasource/dialog-select-datasource.component';
import { SearchLogService } from 'src/app/services/modules/search-log.service';

@Component({
    selector: 'pivot-create-widget-template',
    templateUrl: './create-widget-template.component.html',
    styleUrls: ['./create-widget-template.component.scss']
})
export class CreateWidgetTemplateComponent implements OnInit, AfterViewInit {

    @Input() widget: any = null;
    @Input() keyLocalStorage: string = '';
    @ViewChild('period', { static: false }) periodOp: any;
    @ViewChild('splitter', { static: false }) splitter: ElementRef;

    COMMON_TEXT = COMMON_TEXT;
    CREATE_DASHBOARD_TEMPLATE = CREATE_DASHBOARD_TEMPLATE;
    WIDGET_SETTING = WIDGET_SETTING;
    BUTTON = BUTTON;
    isDspOfficeModal: boolean = false;
    isSavedGraphConfig: boolean = false;
    isDspDashboardModal: boolean = false;
    isDspDatasourceModal: boolean = false;
    datasourceData: any = {};
    officeSelecteds: Array<Office> = [];
    isShowMsgExistDs: boolean = false;
    datasourceNm: string = '';
    dsType: number = 0;
    existDatasources: any[] = []
    dashboardSelecteds: Array<any> = [];
    dataSource: TableData = {
        config: {
        id: 'data-source-table',
        caption: '',
        emptyMessage: COMMON_TEXT.NO_DATA,
        showIndex: false,
        showCheckbox: false,
        hoverShowCheckbox: false,
        showPagination: false,
        isResponsive: false
        },
        header: [],
        body: []
    };
    dashboards: any[] = [];
    confirmModalData: any = {};
    isValidateFolder: boolean = false;
    isValidateOffice: boolean = false;
    isValidateDashboard: boolean = false;
    isValidatePeriod: boolean = false;
    isValidateDtsFolder: boolean = false;
    chartData: any | undefined;
    tableData: PivotTableData | undefined;
    cols: ListItemParams;
    rows: ListItemParams;
    value: ListItemParams;
    footers: ListItemParams;
    hiddens: ListItemParams;
    summaryColumns: ListItemParams;
    widgetCd: any = "WD00004351";
    filteredTable: any[] = [];
    filterCondition: FilterConditionRequest = new FilterConditionRequest();
    widgetcd: string = '';
    config: PivotTableConfig = new PivotTableConfig()
    datasourceCd: string = '';
    folderList: any[] = [];
    selectedFolder: any = null;
    selectedDtsFolder: any = null;
    folderDatasources: any[] = [];
    officeCds: string[] = [];
    dashboardSelected: any = null;
    offices: any[] = [];
    headers: any[] = [];
    dashboardFolders: any[] = [];
    currentHeaders: any[] = [];
    folderDbSelected: any = null;
    officeLabel: string = '';
    dateFormat: any = PrimengDateFormat.FULL_SHORT_DATE.toLocaleLowerCase();
    now: Date = new Date();
    startDate: Date = new Date();
    endDate: Date = new Date();
    holder: any = {}
    isDecided: boolean = false;
    startMonth = 1;
    selectedRangeDate: any = {};
    dtsSelected: any = null;
    selectedPeriod: any = {}
    groupedPeriod: any = [];
    _groupedPeriod: any = []
    isLegendDisplay: boolean = true;
    isShowDlgConfirm: boolean = false;
    isAxisDisplay: boolean = true;
    isDisableRangeDate: boolean = true;
    isSelectAllOffice: boolean = false;
    isShowFolder: boolean = false;
    isSupporterAdmin: boolean = false;
    folderType = FOLDER_TYPE;
    isStackedChartSetting: boolean = false;
    isNotStackedChart: boolean = true;
    GraphType = GraphType;
    buttonType = ButtonType;
    graphConfig: any[] = [];
    chartTypeOptions: any[] = [{value: 0, title: "棒"}, {value: 1, title: '折れ線'}];
    showGraphSetting: Subject<void> = new Subject<void>();
    isShowChartData: boolean = true;
    widgetResult: any = null;
    panelSizes: any[] = [50,50];
    panelBottomHeight: number = 400;
    intervalIdList: any[] = [];
    isDisplayWarning: boolean = false;
    firstTimeCalling: boolean = true;
    warningMessage: string = '';
    colFilterStr : string = ColFilterGroup + '_';
    widgetDetails: any[] = [];
    periodName: string = '';
    optionSelected: any = null;
    chartType: any = null;
    selectedFolderRow: any = {};
    dashboardServiceData: any = {};
    // input textbox description params
    inputDesParams: any = {
        type: InputType.text,
        validate: false,
        borderFill: true,
        maxLength: 200,
        disabled: false
    };
    isOptionCreate: boolean = true;
    existDatasource: any = null;
    selectedExistDs: any[] = [];
    itemCharts: any[] = [];

    searchFolderParams: SearchParams = {
		type: SearchType.combo,
		cssStyle: { height: '34px' },
		readonly: false,
		disabled: false,
		maxLength: 100,
		defaultValue: '',
		comboDisplayText: 'name',
        placeholder: COMMON_TEXT.SELECTED
	};

    constructor( 
        private loadingService : ProcessLoadingService,
        private routerNavigate: Router,
        private datasourceService: DataSourceService,
        private widgetResultService: WidgetResultService,
        private widgetService: WidgetService,
        private mstService: MstCommonService,
        private modalService: DialogService,
        private folderService: FolderService,
        private authenticationService: AuthenticationService,
        private errorHandleService: ErrorHandleService,
        private stepFunctionService: StepFunctionService,
        private clearProcessService: ClearProcessService,
        private dashboardService: DashboardService,
        private officeService: OfficeAPIService,
        private corpMstService: CorpMstService,
        private searchLogService: SearchLogService,
    ) {
        this.itemCharts = [
            { value: GraphType.BAR_CHART_HORIZONTAL, class: 'bar-chart-horizontal' },
            { value: GraphType.BAR_CHART_VERTICAL, class: 'bar-chart-vertical' },
            { value: GraphType.LINE_BAR_COMBINE_CHART, class: 'combo-chart' },
            { value: GraphType.LINE_CHART, class: 'line-chart' },
            { value: GraphType.DOUGHNUT_CHART, class: 'doughnut-chart' },
            { value: GraphType.NUMBER_CHART, class: 'number-chart' },
            { value: GraphType.PIE_CHART, class: 'pie-chart' },
            { value: GraphType.STACKED_BAR, class: 'stacked-bar-chart' },
            { value: GraphType.TABLE_CHART, class: 'table-chart' }
        ];
     }

    async ngOnInit() {
        this.loadingService.isLoading.emit(true);
        this.widget = this.widgetService.getWidgetDetail();
        await this.getListFolderWidget();
        if(this.widget) {
            this.isSupporterAdmin = await this.authenticationService.isAdminOrSupporter();
            if(this.widget?.charttype == GraphType.STACKED_BAR)
                this.isNotStackedChart = false;
            this.widget.widgetPosition = null;
            this.widget.width = null;
            this.widget.height = null;
            this.setWidgetSize();
            this.datasourceCd = !Utils.isNullOrEmpty(this.widget.datasourceCd) ?  this.widget.datasourceCd : this.widget.datasourcecd;
            if(this.widget.widgetdetails && this.widget.widgetdetails?.length > 0) {
                const { rows, columns, values, footers, summaryColumns, hiddens } = makePivotConfig(this.widget.widgetdetails)
                this.config = { rows: rows, columns: columns, values: values, footers: footers, summaryColumns: summaryColumns, hiddens: hiddens};
            }
            await this.getWidget();
            if(this.widget.charttype == GraphType.LINE_BAR_COMBINE_CHART) 
                this.loadHeadersForGraphSetting();
        }
        else this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.LIST_ITEM_TEMPLATE)]);
    }

    selectAllOffice(isSelectAll: boolean) {
        this.isSelectAllOffice = isSelectAll;
        if(this.isSelectAllOffice) this.officeLabel = COMMON_TEXT.SELECT_ALL_OFFICE;
    }

    changeDecided() {
        this.isDecided = !this.isDecided;
    }

    sortDatasourceHeader() {
        let defaultHeaderDts = DATA_SOURCE_DEFAULT_HEADERS;
        let defaultHeader = cloneDeep(this.currentHeaders).filter( hd => hd.sortno > 0 && hd.sortno < 16);
        defaultHeader?.forEach((h: any) => {
            // set sortno for default header
            let findHeader = defaultHeaderDts.find(header => header.field?.toLowerCase()?.trim() == h.field?.toLowerCase()?.trim() && header.visible == true);
            if(findHeader)
            {
                h.sortno = findHeader.sortno;
                h.visible = true;
            }  
            else h.visible = false;
            switch(h.field?.toLowerCase()?.trim()) {
                case 'objcd':
                case 'daicd':
                    h.visible = true;
                    h.sortno = 1;
                break;
                case 'objnm':
                    h.visible = true;
                    h.sortno = 2;
                break;
                case 'blocknm':
                    h.visible = true;
                    h.sortno = 5;
                break;
                case 'unitnm':
                    h.visible = true;
                    h.sortno = 6;
                break;
                case'roomnm':
                    h.visible = true;   
                    h.sortno = 7;
                break;
                case'schedbedcd':
                    h.visible = true;
                    h.sortno = 8;
                break;
                case'ownstfnm':
                    h.visible = true;
                    h.sortno = 10;
                break;
            }
        })

        this.headers = this.headers.concat(defaultHeader.filter(s=>s.visible == true));
        // set sortno for column user selected
        let headerUserSelected = cloneDeep(this.currentHeaders).filter(hd => (hd.sortno < 0 ||  hd.sortno > 15) && !hd.field.toLowerCase().startsWith("ttd") );
        let sortnoUserSelect = 16;
        let sortnoMax = Number.MAX_SAFE_INTEGER - 32;
        headerUserSelected?.forEach(h => {
            if(h.field == 'NUMBEROFDAYS')   h.sortno = DatasourceValueConfig.LASTEST_SORTNO;
            else {
                sortnoUserSelect++;
                h.sortno = sortnoUserSelect;
            }
        });
        this.headers = this.headers.concat(headerUserSelected);

        // set sortno for total day header
        let totalDayHeader = cloneDeep(this.currentHeaders).filter(hd => hd.field.toLowerCase().startsWith("ttd"));
        totalDayHeader?.sort((item1, item2) => {
            return item1.field?.slice(4) - item2.field?.slice(4) 
        });
        totalDayHeader?.forEach(ttd => {
            sortnoMax ++;
            ttd.sortno = sortnoMax;
        })
        this.headers = this.headers.concat(totalDayHeader);
        this.headers.sort((item1, item2) => item1.sortno - item2.sortno);
    }

    // show dialog dashboard
    showDialogDashboardSelect() {
        this.isDspDashboardModal = true;
        this.dashboardServiceData = {
            dashboards: this.dashboards,
            dashboardSelected: this.dashboardSelected,
            dashboardFolders: this.dashboardFolders,
            folderDbSelected: this.folderDbSelected
        }
    }

    //Show dialog DATASOURCE
    showDialogDatasource() {
        let data = {
            dashboardSelecteds: this.dashboardSelecteds,
            existDatasources: this.existDatasources,
            isOptionCreate: this.isOptionCreate,
            isShowMsgExistDs: this.isShowMsgExistDs,
            selectedPeriod: this.selectedPeriod,
            selectedRangeDate: this.selectedRangeDate,
            datasourceCd: this.datasourceCd,
            datasourceNm: this.datasourceNm,
            isSupporterAdmin: this.isSupporterAdmin,
            officeSelecteds: this.officeSelecteds,
            folderList: this.folderDatasources,
            selectedDtsFolder:  this.selectedDtsFolder,
            selectedExistDs: this.selectedExistDs
        }
        this.datasourceData = data;
        this.isDspDatasourceModal = true;
    }

    // close dashboard modal
    closeDashboardModal(data: any) {
        this.dashboardFolders = data;
        this.isDspDashboardModal = false;
    }

    closeDataSourceModal(data: any) {
        this.isDspDatasourceModal = false;
        if(!data.isCancel) {
            this.datasourceNm = data.datasourceNm;
            this.isOptionCreate = data.isOptionCreate;
            this.isValidateDtsFolder = false;
            this.isShowMsgExistDs = false;
            this.existDatasource = data.existDatasource;
            this.selectedDtsFolder = data.selectedDtsFolder;
            this.selectedExistDs = data.selectedExistDs;
        }
        this.folderDatasources = data.folderList
    }

    async ngAfterViewInit() {
        this.offices = await this.officeService.getAllOfficeService();
        this.selectedPeriod = '003-002'; 
        await this.getDatasourceTemplate();
        await this.prepopulatePeriodData();
        this.setRangeDate();
        this.loadingService.isLoading.emit(false);
    }

    async getListFolderWidget() {
        this.folderService.getListFolderHavePermissionEdit(FOLDER_TYPE.Widget.toString(), this.isSupporterAdmin).then((resutl: any) => {
            if (resutl.statuscode == 200) {
                this.folderList = resutl?.data || [];
                this.searchFolderParams.dataSource = this.folderList?.map(x => {
                    x.value = x.folderCd
                    return x;
                  });
                this.searchFolderParams = cloneDeep(this.searchFolderParams);
            }
        });
    }


    showDialogSelectFolder() {
        this.isShowFolder = true;
    }

    onFilterFolder(filterData: any) {
        this.selectedFolder = filterData[0];
        this.isValidateFolder = false;
    }

    async getDatasourceTemplate() {
        if(this.datasourceCd == '') return;
        this.loadingService.isLoading.emit(true);
        this.datasourceService.getDatasourceTemplate(this.datasourceCd).then(async res => {
            if(res.statuscode == 200) {
                this.dtsSelected = res.data;
                let period = res.data?.period;
                this.datasourceNm = res.data?.dsname;
                this.dsType = res.data?.dstype;
                this.selectedPeriod = period;
                if( this.selectedPeriod?.includes('~')) {
                    this.selectedPeriod = "-1";
                    let range = period?.split('~');
                    this.selectedRangeDate = {startDate: range[0], endDate: range[1]}
                    this.startDate = new Date(range[0]?.toString()?.trim());
                    this.endDate = new Date(range[1]?.toString()?.trim());
                    this.periodName = COMMON_TEXT.CUSTOMIZATION;
                    this.startDate = new Date(this.selectedRangeDate.startDate);
                    this.endDate = new Date(this.selectedRangeDate.endDate);
                }
                this.setRangeDate();
            }
        })
    }

    // on selected dashboard
    selectedDashboardData(data: any) {
        if(!data) return;
        this.dashboards = data?.dashboards || [];
        this.dashboardSelected = data?.dashboardSelected;
        this.dashboardFolders = data?.dashboardFolders || [];
        this.folderDbSelected = data?.folderDbSelected;
        this.isValidateDashboard = false;
    }

    //#region handle exsit datasources
    async getListExistDatasource() {
        if(this.officeSelecteds.length == 0 || this.selectedPeriod == null ) {
            return
        };

        this.loadingService.isLoading.emit(true);

        const officeCds = this.officeSelecteds?.map(of => of.officeCd).sort((a,b) => {
        return Number(a) - Number(b)
        });

        const officeIdLst = join(officeCds, "-");
        const startDate = this.selectedRangeDate.startDate;
        const endDate = this.selectedRangeDate.endDate;
        const period = this.getPeriodData();
        const data: any = {
            period: period, 
            officeIdLst: officeIdLst, 
            datasourceCdTemplate: this.datasourceCd, 
            startDate: startDate, 
            endDate: endDate
        }
        const res =  await this.datasourceService.getExistDatasources(data);
        if (res.statuscode === 200 && res.data?.length) {
            this.isShowMsgExistDs = true;
            this.existDatasources = res.data;
            this.loadingService.isLoading.emit(false);
        } else {
            this.isShowMsgExistDs = false;
            this.isOptionCreate = true;
            this.inputDesParams.disabled = false;
            this.existDatasources = [];
            this.loadingService.isLoading.emit(false);
        }
    }

    // back page
    backPage() {
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.LIST_ITEM_TEMPLATE)]);
    }
    
    // on show dialog selected office
    showDialogSelectOffice() {
        this.isDspOfficeModal = true;
    }

    // on close office modal
    closeOfficeModal() {
        this.isDspOfficeModal = false;
    }

    // on change chart type
    async onChangeChartType(type: any) {
        if(!this.widget) return;
        this.chartData = null;
        this.widget.isLoadWidgetResultComplete = false;
        this.widget.type = parseInt(type);
        this.widget.charttype = this.widget.type;
        if(this.widget.charttype == GraphType.LINE_BAR_COMBINE_CHART) 
            this.loadHeadersForGraphSetting();
        await this.getWidget(); 
        if(this.widget.charttype == GraphType.STACKED_BAR) this.isNotStackedChart = false;
        else this.isNotStackedChart = true;
        this.widget.isLoadWidgetResultComplete = true;
    }

    // on selected office
    async selectedOffice(data: any) {
        if(!data) return;
        this.officeSelecteds = data;
        this.isValidateOffice = false;
        this.officeCds = this.officeSelecteds.map(o => o.officeCd).sort((a,b) => {
            return Number(a) - Number(b)
        });
        this.setOfficeLabel(data, this.isSelectAllOffice);
        await this.getListExistDatasource();
    }

    setOfficeLabel(offices: any[], isSelectAll : boolean) {
        if(offices.length == 1) this.officeLabel = offices[0].officeNm? offices[0].officeNm 
                            : this.offices.find(o => o.officeCd == offices[0])?.officeNm;
        else if(isSelectAll) this.officeLabel = COMMON_TEXT.SELECT_ALL_OFFICE;
        else this.officeLabel = offices.length + COMMON_TEXT.SELECTED_OFFICE;
    }

    validateData() {
        this.isValidateFolder = false;
        this.isValidateDashboard = false;
        this.isValidateOffice = false;
        this.isValidateDtsFolder = false;
        if(this.isCanSave(true)) {
            if(!this.selectedFolder) this.isValidateFolder = true;
            if(!this.dashboardSelected) this.isValidateDashboard = true;
            if(this.officeCds.length == 0) this.isValidateOffice = true;
            if(!this.selectedDtsFolder) this.isValidateDtsFolder = true;
            return true;
        }
        else return false;
    }

    // on save widget data
    saveWidget() {
        const api = this.widgetService.getWidgetTemplate(this.widget.widgetcd);
        api
        .then(async res => {
            let data = res.data as any[]
            if(res.statuscode != 200) {
                this.errorHandleService.backURLSub.next(ROUTE_PATH.WIDGET_TEMPLATE_LIST)
            }
            data.map((item: any) => item.datasourcecd = (item.datasourcecd == "DS-CUSTOM" || item.datasourcecd == "DS-TARGET")   ? item.datasourcecd : this.datasourceCd);
            this.widget.id = null
            this.widget.widgetname = data[0].widgetname
            this.widget.widgetdesc = data[0].widgetdesc
            this.widget.charttype = data[0].charttype === -1 ? null : data[0].charttype
            this.widget.insdate = data[0].insdate
            this.widget.insstfcd = data[0].insstfcd
            this.widget.sortcoltype = data[0].sortcoltype;
            this.widgetDetails = data.map(x =>  new WidgetDetail(x))
            this.widgetDetails.filter((x: any) => x.columnname?.includes(ColFilterGroup)).map(x => {
                let columnMap = this.widgetDetails?.find(s => s.columnname == x.columnname?.replace(this.colFilterStr, ''));
                if(columnMap) x.displayname = columnMap.displayname;
            });
        });

        this.createDataFromTemplate(false);
    }

    async onshowDialogConfirm() {
        if(this.validateData()) return;
        let startDate = this.selectedRangeDate.startDate.trim();
        let endDate = this.selectedRangeDate.endDate?.trim();
        this.confirmModalData = {
            officeLabel: this.officeLabel,
            periodName: `${moment(startDate).format(DateFormat.JP_FULL_LONG_DATE)} ~ ${moment(endDate).format(DateFormat.JP_FULL_LONG_DATE)}` + '【'+ this.periodName + '】',
            folderDatasource: this.isOptionCreate? (this.selectedDtsFolder?.name || '') : (this.existDatasource?.foldername || ''),
            datasourceName: this.datasourceNm,
            dashboardName: this.dashboardSelected?.name,
            widgetName: this.widget?.widgetName,
            forderWidget: this.selectedFolder?.folderName,

        }
        this.isShowDlgConfirm = true;
        await this.insertSearchLog(true);
    }

    // save datasource, widget and dashboard setting.
    confirmSaveData(data: boolean) {
        this.isShowDlgConfirm = false;
        if(data == null) return;
        else {
            if(!data) this.saveWidget();
            else this.createDataFromTemplate(true);
        }
    }

    checkIfStopInterval(screenCode: any, intervalId: any): boolean {
        let result: boolean = false;
        if(this.firstTimeCalling) {
          this.firstTimeCalling = false;
          this.clearProcessService.setScreenCode(screenCode);
        }
        const holderCode = this.clearProcessService.getScreenCode();
        if(holderCode != screenCode) {
          clearInterval(intervalId);
          this.removeIntervalIdToList(intervalId);
          result = true;
        }
    
        return result;
    }

    getPeriodData() {
        let period = ''
        let startDate = this.selectedRangeDate.startDate?.trim();
        let endDate = this.selectedRangeDate.endDate?.trim();
        if(this.selectedPeriod === '-1'){
          period = `${startDate} ~ ${endDate}`
        }
        else {
          period = this.selectedPeriod;
        }
        return period;
    }
    
    getTemplateCreateRequest() {
        let period = this.getPeriodData();
        return {
            dsstructcd: this.datasourceCd,
            widgetcd: this.widget.widgetcd,
            foldercd:  this.selectedDtsFolder ? this.selectedDtsFolder?.folderCd : this.selectedFolder?.id,
            startdate: this.selectedRangeDate.startDate,
            enddate: this.selectedRangeDate.endDate,
            officecds: this.officeCds,
            dsName: this.datasourceNm,
            period: period,
            dsname: this.datasourceNm
        }
    }

    isCanSave(isCreateDashboard: boolean) {
        if(!this.selectedFolder || this.officeCds.length == 0 || (isCreateDashboard &&  !this.dashboardSelected)|| this.isValidatePeriod 
        || (!this.selectedDtsFolder && this.isOptionCreate))
            return true;
        return false;
    }

    createDataFromTemplate(isCreateWidget: boolean = false) {
        let datasourceTemplateRequest = this.getTemplateCreateRequest();
        this.loadingService.isLoading.emit(true);
        if (this.isOptionCreate) { 
            this.datasourceService.checkTitleExist(datasourceTemplateRequest).then(ttlRes =>{
            if (ttlRes.statuscode === 200 && ttlRes.data) {
                this.datasourceService.cloneDataSourceTemplate(datasourceTemplateRequest).then(templateRes => {
                    if (templateRes.statuscode === 200) {
                        const screenCodeFor2rdProcess = 1 + Math.random();
                        let intervalId = setInterval(() => {
                        if(this.checkIfStopInterval(screenCodeFor2rdProcess, intervalId)) {
                            return;
                        }
                        this.stepFunctionService.checkStatus(templateRes.data.lambdaresponse.executionarn).then(resLamda => {
                            if(resLamda.statuscode && resLamda.statuscode == 500) {
                            clearInterval(intervalId);
                            this.removeIntervalIdToList(intervalId);
                            }
                            if (resLamda.data.status === LambdaStatus.SUCCEEDED) {
                            clearInterval(intervalId);
                            this.removeIntervalIdToList(intervalId);
                            this.datasourceService.getResultOnS3(templateRes.data.dsstructcd).then(async res => {
                                let resultData = JSON.parse(res.data);
                                if (res.statuscode == 200 && resultData.status === true) {
                                    this.datasourceCd = templateRes.data.dsstructcd;
                                    this.insertDataForTemplate(isCreateWidget);
                                }
                            })
                            } else if (resLamda.data.status != LambdaStatus.RUNNING && resLamda.data.status != "") {
                            clearInterval(intervalId);
                            this.removeIntervalIdToList(intervalId);
                            this.loadingService.isLoading.emit(false);
                            }
                        })
                        }, 5000);
                        this.addIntervalIdToList(intervalId);
                    }
                    else
                        this.errorHandleService.backURLSub.next(ROUTE_PATH.DASHBOARD_WIDGET_TEMPLATE + "/4");
                    });
                }
                else {
                    this.loadingService.isLoading.emit(false);
                    this.showDialogError();
                }
            });
        }
        else {
            this.insertDataForTemplate(isCreateWidget);
        }
    }

    // thêm mới data từ template 
    insertDataForTemplate(isCreateWidget: boolean = false) {
        // nếu isCreateWidget = true thì chạy theo luồng cũ navigate ra màn hình widget-setting rồi sau khi lưu sẽ navigate về dashboard setting
        if(isCreateWidget) {
            // tạo mới datasoủce
            if(this.isOptionCreate) {
                this.widgetService.pass({ widgetCd: undefined, folderCd: this.selectedFolder?.folderCd, mode: ScreenMode.ADD });
                this.widgetService.setCreatingByTemplate({ widgetcdTemplate: this.widget?.widgetcd, dsStructCd: this.datasourceCd, useExistDs: false , lstOffices: this.officeCds, name: this.widget?.widgetName, type: this.widget?.charttype, desc: this.widget?.widgetdesc, graphconfig: this.graphConfig});
                this.dashboardSelected.dashboardCd = this.dashboardSelected.value;
                // set setDashboardItem để lấy được thông tin bên màn hình dashboard setting
                this.dashboardService.setDashboardItem(ScreenMode.EDIT, cloneDeep(this.dashboardSelected));
                this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_DETAIL)]);
            }
            // sử dụng datasource hiện có
            else {
                this.widgetService.setCreatingByTemplate({ widgetcdTemplate: this.widget?.widgetcd, dsStructCd: this.existDatasource?.dsstructcd || '', useExistDs: true , lstOffices: this.officeCds, name: this.widget?.widgetName, type: this.widget?.charttype, desc: this.widget?.widgetdesc, graphconfig: this.graphConfig});
                this.loadingService.isLoading.emit(false)
                this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_DETAIL)]);
            }
        }
        // nếu isCreateWidget = false thì sẽ tạo widget, widget result và gắn widget vừa tạo vào dashboard đây là luồng mới
        else {
            this.loadingService.isLoading.emit(true);
            let dsscd = this.isOptionCreate ? this.datasourceCd : this.existDatasource?.dsstructcd || '';
            this.createWidget(dsscd)
            .then(async res => {
                if(res.statuscode == 200) {
                    let newWidgetCd = res.data;
                    // insert widget result
                    this.widgetResultService.createWidgetResultFromWidgetCdAndDatasourceCd(res.data, dsscd).then( async(res) => {
                        if(res.statuscode == 200) {
                            // insert dashboard setting
                            await this.createDashboardSetting(newWidgetCd).then((result: any) => {
                                if(result.statuscode == 200) {
                                    // pass thông tin widget qua màn hình dashboard-setting để bên dashboard-setting pick về có data.
                                    this.widgetService.pass({ widgetCd: newWidgetCd, folderCd: this.selectedFolder?.folderCd, mode: ScreenMode.PREVIEW , type: this.widget?.chartType,
                                        description: this.widget?.widgetdesc, sortcoltype: this.widget?.sortcoltype, name: this.widget?.widgetname });
                                    this.openDialogSuccess(DialogType.save);
                                    this.dashboardService.setDashboardItem(ScreenMode.PREVIEW , cloneDeep(this.dashboardSelected));;
                                    this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_DETAIL), result.data, ScreenMode.PREVIEW]);
                                }
                            });
                        }
                    });

                }
                this.loadingService.isLoading.emit(false); 
            })
            .catch(() => this.loadingService.isLoading.emit(false))
            .finally();
        }
    }

    addIntervalIdToList(id: any) {
        if(this.intervalIdList.includes(id)) return;
        this.intervalIdList.push(id);
        this.clearProcessService.setIntervalId(id);
    }

    // open dialog success
    openDialogSuccess(dialogType: DialogType) {
        this.modalService.open(ConfirmDialogComponent, {
          data: {
            dialogType: dialogType,
          }
        });
    }

    // create widget
    createWidget(datasourceCd: string) {
        let widgetDetails: any[] = cloneDeep(this.widget.widgetdetails?.filter((w: any) => !w.delflg)) || [];
        widgetDetails.forEach((item: any) => {
            item.datasourceCd = datasourceCd;
        });
        this.widget.widgetdetails = widgetDetails;
        this.widget.id = undefined;
        this.widget.chartType = this.widget.type;
        this.widget.widgetcd = undefined;
        this.widget.foldercd = this.selectedFolder.folderCd;
        this.widget.folderCd = this.selectedFolder.folderCd;
        this.graphConfig = this.graphConfig || [];
        this.graphConfig = this.graphConfig.length > 0
        ? this.graphConfig.map((config: any) => ({ ...config, isStackedChartSetting: this.isStackedChartSetting }))
        : [{ Checked: null, GraphType: null, IsSecondAxis: null, RowIndex: null, isStackedChartSetting: this.isStackedChartSetting }];

        let widgetconfig = {
            id: this.widget?.widgetConfig ? this.widget.widgetConfig?.id : "",
            targetConfig: this.widget?.widgetConfig ? JSON.stringify(this.widget.widgetConfig?.targetTable) : "",
            graphConfig: this.widget.chartType === GraphType.STACKED_BAR ? this.graphConfig ? JSON.stringify(this.graphConfig) : ""
              : this.graphConfig && this.isSavedGraphConfig ? JSON.stringify(this.graphConfig) : ""
        };
        const request: WidgetRequest = {
            widget: this.widget,
            widgetdetails: this.widget.widgetdetails?.filter((x:any) => !x.delflg || x.id),
            widgetconfig:  { ...widgetconfig }
        }
        this.loadingService.isLoading.emit(true)
        // insert widget
        return this.widgetService.postWidget(request)
    }

    removeIntervalIdToList(id: any) {
        this.intervalIdList = this.intervalIdList.filter(e => e != id);
        this.clearProcessService.removeIntervalIdList(id);
        this.firstTimeCalling = true;
      }

    //populate Period Data
    async prepopulatePeriodData() {
        this.groupedPeriod = [];
        this._groupedPeriod = [];
        const corpMstService = await this.corpMstService.getAll();
        if (corpMstService.statuscode == 200) {
          let monthSetting = corpMstService.data?.find((x:any) => x.contentcd == "0001");
          this.startMonth = +(monthSetting?.value || 1);
        }
        let filterBodyRequest = {
           startmonth: this.startMonth
        }
        let localGroupedPeriod = customPeriod;
        let groupedPeriod = await this.mstService.getMstFilter(filterBodyRequest) as any[];
        localGroupedPeriod.push(...groupedPeriod);
        this._groupedPeriod.push(...localGroupedPeriod);
        this.groupedPeriod.push(...this._groupedPeriod);
        if(this.dsType === DataSourceType.SEIKUY){
            let seikuyGroupedPeriod  = this._groupedPeriod.filter((x: any) => x.value != "-1" && x.value != MstFilterDataTimeItem.ThisYear && x.value != MstFilterDataTimeItem.Today && x.value != MstFilterDataTimeItem.ThisWeek);
            this.groupedPeriod = seikuyGroupedPeriod;
        }
        else {
            this.groupedPeriod = cloneDeep(this._groupedPeriod);
        }
        // map label for period
        this.groupedPeriod.map((group:any) => group.items.map((item:any) => {
            if(this.dsType === DataSourceType.SEIKUY){
              item.label = item.contentseikuy ??  item.label
            }else{
              item.label = item.content ??  item.label
            }
        }));

        if( this.selectedPeriod != "-1") {
            this.groupedPeriod.forEach((group: any) => {
                let selectedItem = group.items.filter((item:any) => item.value === this.selectedPeriod).pop();
                if(selectedItem) {
                    if(this.dsType === DataSourceType.SEIKUY){
                        this.selectedRangeDate = {startDate: selectedItem.startdateseikuy, endDate: selectedItem.enddateseikuy}
                       }else{
                        this.selectedRangeDate = {startDate: selectedItem.startdate, endDate: selectedItem.enddate}
                    }
                }
            });
        }
    }

    async onChangeDate(value: any, type: any) {
        let dateValue = moment(value).format(DateFormat.FULL_DATE_HOUR);
        if(type == 1) {
            this.selectedRangeDate.startDate = dateValue;
        }
        else {
            this.selectedRangeDate.endDate = !this.isDecided ? dateValue : null;
        }
        this.setValidatePeriod();
        if(this.selectedPeriod === '-1'){
           await this.getListExistDatasource();
        }
    }

    setRangeDate() {
        if(this.selectedPeriod && this.selectedPeriod != '-1'){
            this.groupedPeriod.forEach((group: any) => {
                let selectedItem = group.items.filter((item:any) => item.value === this.selectedPeriod).pop();
                if(selectedItem){
                    this.optionSelected = selectedItem;
                    if(this.dsType === DataSourceType.SEIKUY){
                        this.periodName = selectedItem.contentseikuy;
                        this.selectedRangeDate = {startDate: selectedItem.startdateseikuy, endDate: selectedItem.enddateseikuy}
                    }else{
                        this.selectedRangeDate = {startDate: selectedItem.startdate, endDate: selectedItem.enddate}
                        this.periodName = selectedItem.content;
                    }
                    this.startDate = new Date(this.selectedRangeDate.startDate);
                    this.endDate = new Date(this.selectedRangeDate.endDate);
                }
            });
           this.setValidatePeriod();
        }
    }

    setValidatePeriod() {
        if(this.selectedPeriod === '-1') { 
        const diffDays = Math.round(moment(this.endDate, 'YYYY-MM-DD').startOf('day').hour(23).minute(59).seconds(59).diff(moment(this.startDate, 'YYYY-MM-DD').startOf('day'), 'days', true));
        this.isValidatePeriod = diffDays > 365? true : false;
        }
        else this.isValidatePeriod = false;
    }

    // create dashboard setting
    async createDashboardSetting(widgetcd: string) {
        this.loadingService.isLoading.emit(true);
        if(!this.dashboardSelected) return;
        let dashboardcd = this.dashboardSelected.dashboardCd;
        let widgets: any[] = [];
        // lấy dashboard data
        let dashboardInfo: any[] = await this.dashboardService.getSettingDetail(dashboardcd) || [];
        if(dashboardInfo?.length > 0) {
            // lấy list widget trong dashboard setting
            widgets = dashboardInfo[0].setting || [];
        }

        let widgetChanges: DashboardDetailModel[] = [];

        let x = 0, y = 0;
        if (widgets.length > 0) {
            // lấy widget cuối cùng trong danh sách setting
            const lastWiget = widgets.reduce((max, current) => {
                const maxPos = JSON.parse(max.widgetPosition);
                const currentPos = JSON.parse(current.widgetPosition);
                if (currentPos.y > maxPos.y || (currentPos.y === maxPos.y && currentPos.x > maxPos.x)) {
                  return current;
                }
                return max;
            });

            // toạ độ của widget cuối cùng
            let lastWidgetPosition = JSON.parse(lastWiget?.widgetPosition);
            
            // set lại toạ độ dựa theo tạo độ cuối cùng
            // --|------------|  |------------ |
            // --|  x:0;y:0   |  |  x:14;y:0   |
            // --|------------|  |------------ |
            // --|  x:0;y:7   |  |  x:14;y:7   |
            // --|------------|  |------------ |
            // --|  x:0;y:14  |  |  x:14;y:14  |
            // --|------------|  |------------ |
            // --|  x:0;y:21  |  |  x:14;y:21  |
            // --|------------|  |------------ |
            if (lastWidgetPosition.x == 0 && lastWidgetPosition.y == 0) {
                x = 14;
            } else if (lastWidgetPosition.x > 0 && lastWidgetPosition.y > 0 || lastWidgetPosition.x > 0 && lastWidgetPosition.y == 0) {
                x = 0;
                y = lastWidgetPosition.y + 7;
            } else if (lastWidgetPosition.x == 0 && lastWidgetPosition.y > 0) {
                x = 14;
                y = lastWidgetPosition.y;
            }
        }

        let widgetPosition = JSON.stringify({
            x: x,
            y: y,
            rows: 7,
            cols: 14,
            width: 500,
            height: 400 
        })
        
        widgetChanges.push({
            id: v4(),
            dashboardcd: dashboardcd,
            widgetcd: widgetcd,
            // set toạ độ y cho widget thêm mới
            widgetposition: widgetPosition,
            widgetnew: true
        });
        let dashboardSetting: any = {
            listDataChange: widgetChanges,
            listRemove: [],
            featureDuplicates: [],
            isduplicate: false,
            dashboard: {
              dashboardCd: this.dashboardSelected.dashboardCd,
              dashboarddesc: this.dashboardSelected.description,
              dashboardname: this.dashboardSelected.name,
              foldercd: this.dashboardSelected.folderCd,
              publicCd: this.dashboardSelected.publicCd
            }
        };
        let newDashboard = this.dashboardService.insertOrUpdateDbDetail(dashboardSetting);
        this.loadingService.isLoading.emit(false);
        return newDashboard;
    }

    // selected period
    async onSelectedPeriod(option: any) {
        if(option?.value == '-1') {
            this.selectedPeriod = '-1';
            this.isDisableRangeDate = false;
            this.periodName = option.label;
        }
        else {
            this.periodName = this.dsType === DataSourceType.SEIKUY? option?.contentseikuy:  option?.content;
            this.isDisableRangeDate = true;
            this.selectedPeriod = option.value;
            this.setRangeDate();
        }
        await this.getListExistDatasource();
        this.periodOp.hide();
    }

    closeFolderModal() {
        this.isShowFolder = false;
    }

    // get widget data
	async getWidget() {
        if(!this.widget) return;
        await this.getByWidgetCds();
	}

    onRenderChartComplete(){
        this.loadingService.isLoading.emit(false);
    }

    onChangeWidgetName(event: any) {
       if(!this.widget) return;
       this.widget.name = event.target?.value || '';
       this.widget.widgetName = event.target?.value || ''
    }

    onChangeWidgetDesc(event: any) {
        if(!this.widget) return;
        this.widget.widgetDesc = event?.target?.value || ''
    }

    resizeTextArea(id: string) {
        const textarea: any = document.getElementById(id);
        if(!textarea) return;
        textarea.style.height = '34px';
        const newHeight = Math.min(textarea.scrollHeight, 50) < 40 ? 34 : Math.min(textarea.scrollHeight, 50); 
        textarea.style.height = `${newHeight}px`;
    }

    onStartRenderChart(){
        this.loadingService.isLoading.emit(true);
    }

    loadHeadersForGraphSetting() {
        this.graphConfig = this.widget?.widgetconfigs?.graphconfig ? JSON.parse(this.widget?.widgetconfigs?.graphconfig) : []
        let widgetGraphSetting: WidgetGraphSetting[] = [];
        if(this.chartData) {
            if(this.chartData.columns && this.chartData.columns.length > 0) {
                this.chartData.columns.forEach((row : any, rowIndex: number) => {
                    let item: WidgetGraphSetting = { targetColumn: row[0], graphType: {value: 0, title: "棒"}, isSecondAxis: false, rowIndex};
                    widgetGraphSetting.push(item);
                });
            }     
        }   
     
        if(widgetGraphSetting.length) {
          if(this.graphConfig && this.graphConfig.length) {
            let deletedSetting = this.graphConfig.filter((item: any) => widgetGraphSetting.filter((graph: any) => graph.targetColumn == item.targetColumn).length == 0 );
            let addedSettings = widgetGraphSetting.filter((item: any) =>   !this.graphConfig.some(existingItem => existingItem.targetColumn === item.targetColumn) );
            let order = [...addedSettings, ...this.graphConfig];
            if(deletedSetting && deletedSetting.length) {
              order = order.filter((item: any) => deletedSetting.filter((graph: any) => graph.targetColumn == item.targetColumn).length >0 ? false :  true );
            }
    
            let settings = uniqBy(order, 'targetColumn');
            this.graphConfig = orderBy(settings, ["rowIndex"]);
          }
          else {
            let order = [...widgetGraphSetting];
            this.graphConfig = orderBy(order, ["rowIndex"]);
          }
        }
    }

    showDialogError() {
        let modalParam = new ModalParam();
        modalParam.description = COMMON_TEXT.ERROR_MESSAGE_TITLE_NOT_EXIST;
        let btnOk = new ButtonItem();
        btnOk.buttonText = COMMON_TEXT.YES;
        btnOk.buttonType = ButtonType.SECONDARY;
        modalParam.buttonArray = [btnOk];
        this.modalService.open(DialogModalComponent, {
          header: COMMON_TEXT.INFORMATION,
          width: '30em',
          data: { modalParam },
        });
    }

    onCheckSwitchColumnRow() {
        this.isStackedChartSetting = !this.isStackedChartSetting;
        this.graphConfig = this.graphConfig || [];
        this.graphConfig = this.graphConfig.length > 0
        ? this.graphConfig.map((config: any) => ({ ...config, isStackedChartSetting: this.isStackedChartSetting }))
        : [{ Checked: null, GraphType: null, IsSecondAxis: null, RowIndex: null, isStackedChartSetting: this.isStackedChartSetting }];
        this.widget.widgetconfig = this.graphConfig;
        this.widget.widgetConfig = this.graphConfig;
        if(this.widgetResult) this.widgetResult.graphConfigs = this.graphConfig;
    }

    setWidgetSize() {
        let spliterData: any = (this.splitter as any)?.containerViewChild;
        let minHeight: number = 300;
        if (spliterData && spliterData.nativeElement) {
            const height = spliterData.nativeElement.clientHeight;
            const width = spliterData.nativeElement.clientWidth;
            if(this.panelSizes.length > 0) {
                this.widget.height = ((height / 100) * this.panelSizes[0]) - 100;
                this.widget.width = width - 50;
                let settingHeight = ((height / 100) * this.panelSizes[1]) - 50;
                this.displayChartSetting('end', settingHeight);
                this.panelBottomHeight = settingHeight < minHeight ? minHeight : settingHeight;
            }
        }
    }

    // resize panel
    async onResizePanel(event: any) {
        await this.getByWidgetCds(true);
        // min height tính theo %
        let minHeitht = 25;
        let sizes: any[] = event.sizes || [];
        this.panelSizes = sizes;
        this.setWidgetSize();
        this.isShowChartData = (sizes[0] < minHeitht) ? false :  true;       
        this.widget.isLoadWidgetResultComplete = true;
    }

    displayChartSetting(status: string, height: number) {
        let settingChart = document.getElementById('settingChart');
        if(settingChart) {
            settingChart.style.height = status == 'start' ? '0px' : (height > 50 ? 50 : 0) + 'px';
        }
    }

    async getByWidgetCds(isResize: boolean = false) {
        this.filterCondition.isFullData = true;
		let requestBody = {
			widgetCds: [this.widget.widgetcd],
			selectType: WidgetSelectDataType.FULLDATA,
			isTemplate: true,
			filterCondition: this.filterCondition,
            graphType: this.widget.type
		} as WidgetResultRequest
        
		let widgetResultData:  any[] = [];
		await this.widgetResultService.getByWidgetCds(requestBody)
		.then(res => {
			if (res.statuscode == 200) {
				widgetResultData = res.data || []; 
                this.tableData = { table: widgetResultData[0]?.result?.table, config: this.config }
                this.chartData = widgetResultData[0]?.result?.chart;
                this.widget.syncStatus = 1;
                this.widget.x = 0;
                this.widget.y = 0;
                this.widget.widgetConfig = this.widget.widgetconfigs; 
                this.widget.widgetdetails = this.widget.widgetdetails;
                let widgetResult = widgetResultData.filter((x:any) => x.widgetcd == this.widget.widgetcd).pop();
                if(widgetResult?.result) {
                    if(!isResize) this.widgetResult = cloneDeep(widgetResult?.result);
                    this.widget.widgetResult = isResize? this.widgetResult: widgetResult?.result;
                    if(this.widgetResult?.graphConfigs?.length > 0) {
                        this.isStackedChartSetting = this.widgetResult?.graphConfigs[0].isStackedChartSetting || false;
                    }
                }
                this.widget = cloneDeep(this.widget);
			}
		});
    }

    showGraphsetting() {
        this.showGraphSetting.next();
    }

    onResizeStart(event: any) {
        this.isShowChartData = false;
        this.displayChartSetting('start', 100);
    }

    onSaveChartConfig(config: any) {
       if(!config.data) return;
       this.graphConfig = config.data;
       if(this.widgetResult) this.widgetResult.graphConfigs = this.graphConfig;
    }

    async insertSearchLog(inuse: boolean) {
		const processedSearchList = [this.widget?.name];

		if(processedSearchList.length) {
			await this.searchLogService.insert(processedSearchList, inuse);
		}
	}
}
