<div class="pagination" >
  <div class="pagination-group">
      <pivot-icon-button [styleClass]="totalPage === 1 || currentPage === 1 ? 'previous-btn inactive' : 'previous-btn' " (onClick)="currentPage = 1" alt="previous end" icon="assets/images/previous-paginator-end-inactive.svg"> </pivot-icon-button>
      <pivot-icon-button [styleClass]="totalPage === 1 || currentPage === 1 ? 'previous-btn inactive' : 'previous-btn' " (onClick)="currentPage = currentPage - 1" alt="previous" icon="assets/images/previous-paginator-single-inactive.svg"> </pivot-icon-button>
      <span class="description">{{currentPage}}/{{totalPage}}</span>
      <pivot-icon-button [styleClass]="currentPage >= totalPage || totalPage === 1 ? 'previous-btn inactive' : 'previous-btn' " (onClick)="currentPage = currentPage + 1" alt="next single" icon="assets/images/next-paginator-single-inactive.svg"> </pivot-icon-button>
      <pivot-icon-button [styleClass]="currentPage >= totalPage || totalPage === 1 ? 'previous-btn inactive' : 'previous-btn' " (onClick)="currentPage = totalPage" alt="next end" icon="assets/images/next-paginator-end-inactive.svg"> </pivot-icon-button>
  </div>
  <div class="pagination-group" >
    <span>{{COMMON_TEXT.RESULT}}</span>
    <div class="pagination-selection" id='pagination'>
      <div class="pagination-label" (click)="toogleDropdown = !toogleDropdown">{{itemsPerPage}}</div>
      <div class="pagination-dropdown" id="pagination-modal" [ngClass]="{'show' : toogleDropdown}">
        <div *ngFor="let item of [20, 50, 100]; let r = index " (click)="changePageSize(item)" class="pagination-item">
          {{item }}
        </div>
      </div>
    </div>
  </div>
</div>