import { StepFunctionService } from 'src/app/services/modules/step-function.service';
import { Location } from '@angular/common';
import { AfterContentInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import * as gridster from 'angular-gridster2';
import * as FileSaver from 'file-saver';
import * as htmlToImage from 'html-to-image';
import { cloneDeep, uniqBy } from 'lodash';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom, mergeMap, Observable, Subscription } from 'rxjs';
import { Office } from 'src/app/models/response/office.ro';
import { CorpMstService } from 'src/app/services/modules/corpmst.service';
import { MstCommonService } from 'src/app/services/modules/mstcommon.service';
import { OfficeAPIService } from 'src/app/services/modules/office.service';
import { distinctBy, isPubishWidget } from 'src/app/_helper/helper';
import { ButtonType, DataSourceType, DateFormat, DeviceType, DialogType, GraphType, InputType, LambdaStatus, SaveType, ScreenMode, SearchType, UserAction, WidgetSelectDataType } from '../../..//app/enum/common-enum';
import { Dashboard, DashboardDetailModel, DashboardSetting } from '../../..//app/models/response/dashboard.ro';
import { FOLDER_TYPE } from '../../..//app/models/response/folder.ro';
import { DashboardService } from '../../..//app/services/modules/dashboard.service';
import { ConfirmDialogComponent } from '../../../app/component/common/confirm-dialog/confirm-dialog.component';
import { ConfirmUnsavedDataDialogComponent } from '../../../app/component/common/confirm-unsaved-data-dialog/confirm-unsaved-data-dialog.component';
import { MESSAGE_TEXT } from '../../../app/const/message';
import { ROUTE_PATH } from '../../../app/const/route-path';
import { BUTTON, COMMON_TEXT, DASHBOARD_TEMPLATE_SETTING, WIDGET_SETTING } from '../../../app/const/text-common';
import { ButtonItem, IconModal, InputParams, ModalParam, ModalTemplate, SearchParams, TemplatedDialogItem } from '../../../app/models/common-model';
import { Widget } from '../../../app/models/response/widget.ro';
import { ProcessLoadingService } from '../../../app/services/loading.service';
import { DataSourceService } from '../../../app/services/modules/data-source.service';
import { FeatureService } from '../../../app/services/modules/feature.service';
import { FolderService } from '../../../app/services/modules/folder.service';
import { WidgetService } from '../../../app/services/modules/widget.service';
import { SidebarExpandHandleService } from '../../../app/services/sidebar-expand-handle.service';
import Utils from '../../../app/util/utils';
import { LocalStorageHelper, LocalStorageKey } from '../../../app/_helper/local-storage.helper';
import { TemplateDatasourceDialogComponent } from '../../component/common/template-datasource-dialog/template-datasource-dialog.component';
import { Title } from '@angular/platform-browser';
import { ProcessNotificationService } from '../../services/process-notification.service';
import {AuthenticationService} from 'src/app/services/authentication.service';
import { DASHBOARD_CD_NEW } from 'src/app/const/const';
import { DashboardHelper } from 'src/app/_helper/dashboard-helper';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
import { WidgetResultService } from 'src/app/services/modules/widget-result.service';
import { FilterConditionRequest, WidgetResultRequest } from 'src/app/models/request/widget.dto';
import { ClearProcessService } from '../../services/clear-process.service';
import { DialogModalComponent } from 'src/app/component/common/dialog-modal/dialog-modal.component';
import { SAUCER_LOG_ACTION, SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG, SCREEN_NAME } from '../../../app/config/saucer-log.config';

@Component({
  selector: 'pivot-dashboard-template-setting',
  templateUrl: './dashboard-template-setting.component.html',
  styleUrls: ['./dashboard-template-setting.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardTemplateSettingComponent implements OnInit, OnDestroy, AfterContentInit {
  ScreenMode = ScreenMode;
  ButtonType = ButtonType;
  DeviceType = DeviceType;
  GraphType = GraphType;
  DialogType = DialogType;
  FolderType = FOLDER_TYPE;
  BUTTON = BUTTON;
  DASHBOARD_TEMPLATE_SETTING = DASHBOARD_TEMPLATE_SETTING;
  COMMON_TEXT = COMMON_TEXT;
  menuItems: MenuItem[] = [];
  menuItemsBackup: MenuItem[];
  heightDashboard: string | undefined;
  dashboardCd: string = '';
  dashboardSelected: any = null;
  newDashboard: any = null;
  dashboardName: string = '';
  checkPermission: boolean = false;
  gridster!: gridster.GridsterComponent;
  dashboard: gridster.GridsterItem[] = [];
  options!: gridster.GridsterConfig;
  mode: ScreenMode = ScreenMode.PREVIEW;
  titlePage: string = "";
  isDisplayConfirmDeleteModal: boolean = false;
  modalData: ModalTemplate = new ModalTemplate();
  modalAddDashboard: ModalTemplate = new ModalTemplate();
  featureDuplicates: any[] = [];
  isCreate: boolean = false;
  dashboardSettings: DashboardSetting[] = [];
  currentName: string = '';
  dashboards: any[] = [];
  widgets: Widget[] = [];
  widgetRemoves: DashboardDetailModel[] = [];
  widgetChanges: DashboardDetailModel[] = [];
  isDisplayDashboardModal: boolean = false;
  folderSelected: any = null;
  folders: any[] = [];
  isDuplicate: boolean = false;
  duplicateData: any = null;
  newDashboardCd: string = '';
  folderOwners: any[] = [];
  folderCreate: any = null;
  isLoading: boolean = true;
  allWidgets: any[] = [];
  listDetail: any[] = [];
  isSave: boolean = false;
  periods: any[] = [];
  featureCurrents: any[] = [];
  saveType = SaveType;
  widgetPick: any = null;
  dashboardDetailCurrent: any[] = [];
  useCanDeactivate: boolean = true;
  isDisplayToolTip: boolean = false;
  selectedWigdetRadio: string;
  currentSelectedMenuClass: string = "";
  isShowFolder: boolean = false;
  selectedFolder: any = {};
  selectedFolderRow: any = {};
  folderList: any[] = [];
  isDspOfficeModal: boolean = false;
  officeSelecteds: Array<Office> = [];
  officeList: any[] = [];
  templateItemParams: TemplatedDialogItem;
  listDataSourceCd:any[] = [];
  dashboardSettingActionLog = this.mode == ScreenMode.ADD ? SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_CREATION : SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_EDITING;

  nameParams: InputParams = {
    inputStyle: { 'width': '300px' },
    placeholder: COMMON_TEXT.TEXT_INPUT,
    type: InputType.text,
    pencil: true,
    validate: false,
    disabled: false,
    readonly: false,
    maxLength: 100,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT_TEXT,
    borderFill: false,
  };

  searchParam: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { height: '46px', margin: '0 0px 10px 0px' },
    readonly: false,
    disabled: false,
    maxLength: 100
  };

  widgetList: any = []
  isFilterPublish: boolean = false;

  isDashboardTemplate: boolean = false;
  isDAshboardTemplateChoosingPage: boolean = false;
  widgetCdTemplate: string = "";
  datasourceCdTemplate: string = "";
  datasourceNameTemplate: string = "";
  datasourcePeriod: string = "";
  groupedPeriod: any = [];
  selectedRangeDate: any = {};
  ref: DynamicDialogRef;
  isDisplayWarning: boolean = false;
  modalDataWarning: ModalTemplate = new ModalTemplate();
  warningMessage: string = '';
  _processNotificationService: ProcessNotificationService;
  isSupporterAdmin: boolean;
  isByPassConfirmDialog: boolean =  false;

  apiServiceSubscriber: Subscription | undefined;

  routerSub: Subscription;
  backActionFromBrowser: boolean = false;
  //List intervalid on process
  intervalIdList: any[] = [];
  firstTimeCalling: boolean = true;
  logAction: any;

  constructor(
    private router: ActivatedRoute,
    private loadingService: ProcessLoadingService,
    private routerNavigate: Router,
    private location: Location,
    private sidebarExpandHandleService: SidebarExpandHandleService,
    private dashboardService: DashboardService,
    private modalService: DialogService,
    private widgetService: WidgetService,
    private folderService: FolderService,
    private featureService: FeatureService,
    private dataSourceService: DataSourceService,
    private corpMstService: CorpMstService,
    private mstService: MstCommonService,
    private officeService: OfficeAPIService,
    private stepFunctionService: StepFunctionService,
    private titleService: Title,
    private processNotificationService: ProcessNotificationService,
    private authenticationService: AuthenticationService,
    private errorHandleService: ErrorHandleService,
    private widgetResultService: WidgetResultService,
    private clearProcessService: ClearProcessService,
    private saucerLogService: SaucerLogService
  ) { 
    this.isDashboardTemplate = this.routerNavigate.url.includes("dashboard-template") ? true : false;
    this.isDAshboardTemplateChoosingPage = this.routerNavigate.url.includes("widget") ? true : false;
    this._processNotificationService = processNotificationService;

    this.routerSub = this.routerNavigate.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
        this.backActionFromBrowser = true;
        this.firstTimeCalling = false;
        this.clearProcessService.clearTargetInterval(this.intervalIdList, false);
        this.clearProcessService.setScreenCode(0);
        
      }
    });

    if (this.isDAshboardTemplateChoosingPage) {
      //Log
      this.saucerLogService.action({
        content: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.MAIN.VIEW.screenName + ' ＞ ' + CONTENT_LOG.VIEW
      }, { 
        action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.MAIN.VIEW
      });
    }

  }

  async ngOnInit() {
    this.apiServiceSubscriber = this.errorHandleService.isByPassConfirmDialog.subscribe((isSkipAllConfirmDialog: boolean) => {
      if(this.isByPassConfirmDialog != isSkipAllConfirmDialog) this.isByPassConfirmDialog = isSkipAllConfirmDialog;
    })
    this.loadingService.isLoading.emit(true);
    this.errorHandleService.backURLSub.next("");
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    this.isSupporterAdmin = await this.authenticationService.isAdminOrSupporter();
    if(this.isDAshboardTemplateChoosingPage) {
      window.addEventListener('load', (event)=>  {
        this.navigateTo('URL', [LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_LIST)]);
      });
      this.officeList = await this.officeService.getAllOfficeTemplateService();
    }
    else {
      this.officeList = await this.officeService.getAllOfficeService();
    }
    this.hiddenDashboardName();
    this.dashboardDetailCurrent = [];

    let startMonth = 1;
    await this.corpMstService.getAll().then(res => {
      if (res.statuscode == 200) {
        let monthSetting = res.data?.find((x:any) => x.contentcd == "0001");
        startMonth = +(monthSetting?.value || 1);
      }
    });

    let filterBodyRequest = {
      startmonth: startMonth
    }


    let groupedPeriod = await this.mstService.getMstFilter(filterBodyRequest) as any[];
    this.groupedPeriod.push(...groupedPeriod);

    await this.getAllWidget();
    await this.getListFolder();
    await this.getDataSourceFolder();
    this.sidebarExpandHandleService.events$.forEach(() => {
      this.setOptions(this.mode);
    });
    this.router.paramMap.subscribe(paramMap => {
      let mode = paramMap.get('type') as unknown as ScreenMode;
      this.mode = Number(mode);
      if (this.mode !== ScreenMode.TEMPLATE) this.dashboardCd = paramMap.get('dashboardId') as string;
    });
    if (this.menuItems.length > 0) {
      if (this.menuItems[0].items && this.menuItems[0].items?.length > 0) {
        if(this.isDAshboardTemplateChoosingPage){
          this.dashboardCd = this.menuItems[0].items[0].id || '';
        }
      }
    }
    this.setModeInputName();
    await this.initData();
    this.setOptions(this.mode);
    if(this.isDashboardTemplate && !this.isDAshboardTemplateChoosingPage) {
      this.logAction = this.mode == ScreenMode.ADD ? SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_CREATION : SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_EDITING;
      this.saucerLogService.action(
        {
          content: this.logAction.VIEW.screenName + ' ＞ ' + this.logAction.VIEW.content + '(dashboardCd: ' + this.dashboardCd + ', dashboardName: ' + this.dashboardName + ')'
        }, 
        { 
          action: this.logAction.VIEW
        }
      );
    }
    this.setTitlePage(this.mode);
    this.loadWidgetContent();
    this.dashboardSelected = cloneDeep(this.dashboardSelected);
    await this.mapPeriodForWidget();
    this.currentSelectedMenuClass = "sub-menu-" + this.dashboardCd;
    let addClassElement = document.querySelector("." + this.currentSelectedMenuClass) as HTMLElement;
    if(addClassElement) {
      addClassElement.classList.add("active-submenu");
    }
    this.loadingService.isLoading.emit(false);
  }

  ngAfterContentInit(): void {
    this.setOptions(this.mode);
  }

  ngOnDestroy(): void {
    this.apiServiceSubscriber?.unsubscribe();
    this.routerSub.unsubscribe();
    let pathname = window.location.pathname;
    if (!pathname?.includes(ROUTE_PATH.DASHBOARD_TEMPLATE_CREATE) &&
    !pathname?.includes(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL) &&
    !pathname?.includes(ROUTE_PATH.WIDGET_TEMPLATE_LIST) &&
    !pathname?.includes(ROUTE_PATH.WIDGET_TEMPLATE_CREATE) &&
    !pathname?.includes(ROUTE_PATH.WIDGET_TEMPLATE_DETAIL) &&
    !pathname?.includes(ROUTE_PATH.WIDGET_DETAIL)) {
      this.dashboardService.setDuplicateItem(null);
      if (!pathname?.includes(ROUTE_PATH.DASHBOARD_TEMPLATE)) {
        !this.isDAshboardTemplateChoosingPage ? this.dashboardService.setDashboardItem(0, undefined): null;
      }
      this.dashboardService.setListDataRemove([]);
      this.dashboardService.setCurrentTemplateData(null);
      this.dashboardService.setListPublicData([]);
    }
  }

  hiddenDashboardName() {
    if (this.mode === ScreenMode.PREVIEW) {
      this.nameParams.hidden = true;
      this.nameParams = cloneDeep(this.nameParams);
    } else {
      this.nameParams.hidden = false;
      this.nameParams = cloneDeep(this.nameParams);
    }
  }

  async getDataSourceFolder() {
    await this.folderService.getByType(FOLDER_TYPE.Datasource.toString(), this.isSupporterAdmin).then(w => {
      if (w.statuscode == 200) {
        this.folderList = w.data || [];
      }
    });
  }

  async getAllWidget() {
    if(this.isDashboardTemplate) {
      await this.widgetService.getAllTemplates().then(w => {
        if (w.statuscode == 200) {
          //filter only get public widget
          let widgets = [];
          if(this.isDAshboardTemplateChoosingPage) {
            widgets = w.data.filter((item: any) => isPubishWidget(item.publicDate));
          }
          else widgets = w.data;
          this.allWidgets = widgets;
          this.allWidgets?.map((w: any) => w.type = w.chartType);
        }
      });
    }
    else {
      await this.widgetService.getAll().then(w => {
        if (w.statuscode == 200) {
          this.allWidgets = w.data || [];
          this.allWidgets?.map((w: any) => w.type = w.chartType);
        }
      });
    }
    
  }
  async getListFolder() {
    this.menuItems = [];
    this.menuItemsBackup = [];
    let isCheckingPublic = this.isDAshboardTemplateChoosingPage;
    await this.dashboardService.getAllDashboardTemplate(isCheckingPublic).then(res => {
      if(res.statuscode == 200){
        this.folderOwners = res.data;
        this.folderOwners.map((f: any) => {
          f.name = f.foldername;
          f.folderCd = f.foldercd;
          f.id = f.foldercd;
        });
        let folders: any[] = [];
        folders = (this.isDashboardTemplate && !this.isDAshboardTemplateChoosingPage) ? res.data.filter((f:any) => f.dashboards.filter((db: any) => db.dashboardcd != null)?.length > 0) || []: res.data;
        this.menuItems = folders.map((folder: any) => {
          let menu : any = {};
          menu = {
            label: folder.foldername,
            id: folder.foldercd,
            expanded: true,
            items: folder.dashboards.map((db: any) => {
              let dashboard = {
                label: db.dashboardname,
                id: db.dashboardcd,
                command: (event: any) => { this.onSelectDashboard(event); },
                styleClass: 'sub-menu-' + db.dashboardcd,
                icon: this.isOldTemplate(db.upddate) ? '' : 'icon icon-new',
              }
              return dashboard;
            }).sort((a:any, b: any) => {
              const labelA = a.label.toLowerCase();
              const labelB = b.label.toLowerCase();
              return labelA.localeCompare(labelB);
            })
          }
          return menu;
        })
        setTimeout(() => {
          if (this.currentSelectedMenuClass) {
            let addClassElement = document.querySelector("." + this.currentSelectedMenuClass) as HTMLElement;
            if(addClassElement)  addClassElement.classList.add("active-submenu");
          }
        }, 1500);
        this.menuItems = this.menuItems.sort((a:any, b: any) => {
          const labelA = a.label.toLowerCase();
          const labelB = b.label.toLowerCase();
          return labelA.localeCompare(labelB);
        })
        this.menuItemsBackup = cloneDeep(this.menuItems);
      }
    });
  }
  


  setModeInputName() {
    if (this.mode == ScreenMode.PREVIEW) {
      this.nameParams.pencil = false;
      this.nameParams.readonly = true;
      this.nameParams = cloneDeep(this.nameParams);
    }
    else {
      this.nameParams.pencil = true;
      this.nameParams.readonly = false;
      this.nameParams = cloneDeep(this.nameParams);
    }
  }

  async onSelectDashboard(event: any) {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SWITCHING_FAIL);
    if(this.isLoading) return;
    if(this.mode == ScreenMode.EDIT || this.mode == ScreenMode.ADD) {
      const refBackPage = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
        header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
        width: '35%'
      });

      refBackPage.onClose.subscribe(async (x) => {
        if(x == 1 ) {
          await this.handleSaveBtnClick(true);
          this.clearAllSetting(); 
          await this.loadDashboardOffFolder(event);
        }
        else if(x == 2) {
          this.isFilterPublish = false;
          this.clearAllSetting(); 
          this.mode = ScreenMode.PREVIEW;
          await this.loadDashboardOffFolder(event);
        }
        else return;
      });
    }
    else {
      if(!this.isDAshboardTemplateChoosingPage){
        this.mode = ScreenMode.PREVIEW;
      }
      await this.loadDashboardOffFolder(event);
    } 
  }

  async loadDashboardOffFolder(event: any) {
    this.selectedWigdetRadio = "";
    this.isLoading = true;
    this.dashboardCd = event.item.id;
    if (this.currentSelectedMenuClass != "") {
      let removeClassElement = document.querySelector("." + this.currentSelectedMenuClass) as HTMLElement;
      if(removeClassElement) removeClassElement.classList.remove("active-submenu");
    }
    this.currentSelectedMenuClass = "sub-menu-" + this.dashboardCd;
    let addClassElement = document.querySelector("." + this.currentSelectedMenuClass) as HTMLElement;
    if(addClassElement)  addClassElement.classList.add("active-submenu");
    let findDashboard = this.dashboards?.find(d => d.dashboardCd === this.dashboardCd);
    !this.isDAshboardTemplateChoosingPage ? this.dashboardService.setDashboardItem(this.mode, findDashboard): null;
    this.loadingService.isLoading.emit(true);
    this.setModeInputName();
    await this.initData();
    this.setOptions(this.mode);
    this.setTitlePage(this.mode);
    await this.loadWidgetContent();
    //this.dashboardSelected = cloneDeep(this.dashboardSelected);
    this.dashboardService.setCurrentTemplateData(cloneDeep(this.dashboardSelected));
   // this.widgetList = cloneDeep(this.dashboardSelected) ? cloneDeep(this.dashboardSelected.setting)  : []
 //   this.isFilterPublish && (this.widgetList = this.widgetList.filter((item : any) => item.ispublish));
    await this.mapPeriodForWidget();
    this.loadingService.isLoading.emit(false);
    if(!this.isDAshboardTemplateChoosingPage) {
      this.setLocationStateChangeDB(this.dashboardCd);
    }
  }

  getListWidgetStr() {
    let widgetStrs: any[] = [];
    this.dashboardSelected?.setting?.forEach((widget: any) => {
      widgetStrs.push(widget.widgetCd);
    });
    return widgetStrs;
  }

  async mapPeriodForWidget() {
    await this.widgetService.getPeriodInfor(this.getListWidgetStr(),this.isDashboardTemplate).then(request => {
      if (request.statuscode == 200) {
        this.periods = request.data || [];
      }
    });

    this.dashboardSelected?.setting?.map((w: any) => {
      let findPeriod = this.periods?.find(s => s.widgetcd == w.widgetCd);
      if (!Utils.isNullOrEmpty(findPeriod)) {
        w.startdate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.startdateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE): (moment(findPeriod.startdate)).format(DateFormat.JP_FULL_SHORT_DATE);
        w.enddate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.enddateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE) : (moment(findPeriod.enddate)).format(DateFormat.JP_FULL_SHORT_DATE);
      }
    });
  }

  // load widget content
  async loadWidgetContent() {
    if (this.dashboardSelected) {
      setTimeout(() => {
        this.dashboardSelected?.setting?.forEach((widget: any) => {
          let findWidget = this.allWidgets?.find(s=>s.widgetCd == widget.widgetCd);
          let sortCol = !Utils.isNullOrEmpty(findWidget?.sortcoltype)? JSON.parse(findWidget.sortcoltype) : null;
          widget.sortArr = sortCol;
          widget.type = this.allWidgets.find(wdg => wdg.widgetCd == widget.widgetCd)?.chartType;
          let findPeriod = this.periods?.find(s => s.widgetcd == widget.widgetCd);
          if (!Utils.isNullOrEmpty(findPeriod)) {
            widget.startdate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.startdateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE): (moment(findPeriod.startdate)).format(DateFormat.JP_FULL_SHORT_DATE);
            widget.enddate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.enddateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE) : (moment(findPeriod.enddate)).format(DateFormat.JP_FULL_SHORT_DATE);
          }
          widget.isHaveSize = false;
        });
        this.isLoading = false;
      }, 500);
      let widgetCds = this.dashboardSelected?.setting.map( (x: any) => x.widgetCd) as string[];
      await this.loadWidgetResult(widgetCds);  
      this.dashboardSelected = cloneDeep(this.dashboardSelected);
      this.folderSelected = this.folders.find(s => s.folderCd == this.dashboardSelected.folderCd) || null;
      if (this.folderSelected?.act != 1) {
        this.folderCreate = this.folderSelected;
      }
      if (this.folderSelected?.act === UserAction.VIEW) {
        if (this.mode != 3) {
          this.navigateTo(ROUTE_PATH.DASHBOARD_TEMPLATE);
        }
      }
      if (!Utils.isNullOrEmpty(this.folderSelected)) {
        this.checkPermission = this.folderSelected.act == UserAction.VIEW ? true : false;
      }
    }
  }
  
  async loadWidgetResult(widgetCds: string[]) {
    let filterCondition = new FilterConditionRequest();
    filterCondition.isFullData = true;
    
    let requestBody = {
      widgetCds: widgetCds,
      selectType: WidgetSelectDataType.SPECIALCASE,
      isTemplate: true,
      filterCondition: filterCondition
  } as WidgetResultRequest

    let widgetResult = await this.widgetResultService.getByWidgetCds(requestBody);
		if(widgetResult.statuscode == 200) {
      const data = widgetResult.data as any[];
			for (let i = 0; i < data.length; i++) {
				let widgets = this.dashboardSelected?.setting.filter((s: any) => s.widgetCd == data[i].widgetcd)
				if (widgets.length > 0) {
          widgets.map((widget: any) => {
            widget.widgetResult = data[i].result;
            if (widget.type == GraphType.STACKED_BAR){
              var jsonString = JSON.stringify(widget.widgetResult?.graphConfigs); 
              widget.widgetConfig = jsonString;
            }
          });
        }
			}
    }
    this.dashboardSelected?.setting.map((x :any)=> x.isLoadWidgetResultComplete = true);
    this.isLoading = false
	}


  clearWidgetSetting() {
    this.widgetService.pass({ widgetCd: undefined, folderCd: '', mode: ScreenMode.ADD });
    this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
  }

  async initData() {
    await this.getDashboardDetail();
    this.newDashboard = null;
    if(this.dashboardCd &&this.dashboardCd  != DASHBOARD_CD_NEW){
      let dataWidgetSetting = await DashboardHelper.getSettingWidgetDetail(this.dashboardService, this.dashboardCd, this.isDashboardTemplate);

      if(dataWidgetSetting.dashboards){this.dashboards = dataWidgetSetting.dashboards }
      if(dataWidgetSetting.widgets){this.widgets = dataWidgetSetting.widgets}
    }
    this.widgetPick = null;
    if (this.mode !== ScreenMode.TEMPLATE) {
      // get Dashboard item
      let dashboardInfor = this.dashboardService.getDashboardItem();
      this.newDashboard = dashboardInfor?.dashboard || null;
      // add dashboard for list dashboard 
      if (!Utils.isNullOrEmpty(this.newDashboard)) {
        this.dashboardName = this.newDashboard.name;
        this.dashboardCd = this.newDashboard.dashboardCd;

        let findDashboard = this.dashboards?.find(d => d.dashboardCd === this.dashboardCd);
        if (findDashboard != undefined) {
          if (Utils.isNullOrEmpty(this.newDashboard?.setting)) {
            this.newDashboard.setting = findDashboard?.setting;
          }else{
            this.newDashboard?.setting.map((x: any) => {
              let widget = findDashboard?.setting?.find((d: any) => d.widgetCd === x.widgetCd);
              if(widget){
                x.startdate = widget.startdate;
                x.enddate = widget.enddate;
                x.datasourceCd = widget.datasourceCd;
                x.period = widget.period;
                x.datasourceName = widget.datasourceName;
                x.publictype = widget.publictype;
                x.sortArr = widget.sortArr;
                x.widgetConfig = widget.widgetConfig;
                x.widgetdetails = widget.widgetdetails;
              }
  
            })
          }
          this.dashboards = [];
        }
        this.dashboards.push(this.newDashboard);
      }
    }
    this.dashboardSelected = this.dashboards?.find(d => d.dashboardCd === this.dashboardCd) as Dashboard;
    if (!Utils.isNullOrEmpty(this.dashboardSelected)) {
      if (this.dashboardSelected?.dashboardCd) {
        if( this.dashboardSelected){
          if(this.dashboardSelected?.setting){
            this.dashboardSelected?.setting.map((x:any)=>{
              x.dataSource = [];
            })
          }
        }
        if(this.dashboardSelected.dashboadCd != DASHBOARD_CD_NEW && this.dashboardSelected.setting && this.dashboardCd  != DASHBOARD_CD_NEW){
          let dataWidget = await DashboardHelper.getWidget(this.widgets, this.widgetService, this.dashboardSelected.dashboardCd, this.dashboardSelected,this.isDashboardTemplate);
          this.widgets = cloneDeep(dataWidget.widgets);
          this.periods = cloneDeep(dataWidget.periods);
        }
      }
      if (!Utils.isNullOrEmpty(this.dashboardSelected?.publicCd)) {
        this.featureCurrents = await this.getListPublicData(this.dashboardSelected?.publicCd);
      }
      this.currentName = this.dashboardSelected?.name;
      if (Utils.isNullOrEmpty(this.dashboardSelected?.setting)) {
        this.dashboardSelected.setting = [];
      }

      // get duplicate dashboard data
      let dulidateData = this.dashboardService.getDuplicateItem();
      if (!Utils.isNullOrEmpty(dulidateData)) {
        // duplicate list faeture public
        this.featureDuplicates = dulidateData?.featureDuplicates;
        this.dashboardName = dulidateData?.dashboard?.dashboardname;
        this.duplicateData = cloneDeep(dulidateData);
        if (this.duplicateData?.listDataChange?.length > 0) {
          this.duplicateData?.listDataChange.forEach((data: any) => {
            let findWd = this.allWidgets.find(s => s.widgetCd == data.widgetcd);
            if (findWd !== undefined) {
              this.widgets.push(findWd);
            }
          });
        }
        this.isDuplicate = true;
        // set duplicate widget
        if (this.duplicateData?.listDataChange?.length > 0) {
          this.duplicateData.listDataChange.forEach((dlcW: any) => {
            let findWdg = this.allWidgets.find(s => s.widgetCd == dlcW.widgetcd);
            if (findWdg != undefined) {
              this.dashboardSelected.publicCd = this.duplicateData?.dashboard?.publicCd || '';
              this.setDashboardItem(findWdg);
            }
          });
        }
      }
      // get dashboard name
      if (!Utils.isNullOrEmpty(this.dashboardSelected.name)) {
        this.dashboardName = this.dashboardSelected.name || '';
      }
      else {
        this.dashboardName = this.dashboardSelected?.dashboardname || '';
      }
      // get list widget new
      let widgetNews = this.dashboardService.getListNewItem() || [];
      this.dashboardService.setListNewItem([]);
      this.widgetPick = this.widgetService.pick();
      if (!Utils.isNullOrEmpty(this.widgetPick)) {
        let widgetConfigRes = await this.widgetService.getWidgetConfig(this.widgetPick.widgetCd, true)
        if(widgetConfigRes.statuscode === 200 && widgetConfigRes.data) {
          this.widgetPick = {
            ...this.widgetPick, 
            widgetConfig: { graphconfig: widgetConfigRes.data?.graphconfig ?? "" }
          }
        }
        let widgetCd = this.widgetPick?.widgetCd || '';
        if(!this.isDAshboardTemplateChoosingPage)this.clearWidgetSetting();
        if (widgetCd) {
          let indexWidgetNew = this.dashboardSelected?.setting?.findIndex((s: any) => s.widgetCd == widgetCd);
          if (indexWidgetNew == -1) {
            let existWidget = this.allWidgets.find(s => s.widgetCd == widgetCd);
            existWidget = {
              ...existWidget, 
              widgetConfig: { graphconfig: widgetConfigRes.data?.graphconfig ?? "" }
            }
            widgetNews.push(existWidget);
          }
          else {
            this.setSettingItem(this.widgetPick);
          }
        }
      }
      widgetNews = uniqBy(widgetNews, 'widgetCd');
      // set list widget new
      this.dashboardService.setListNewItem(widgetNews);

      if (widgetNews.length > 0) {
        // map widget new
        widgetNews?.map(widget => {
          if(widget) {
            widget.type = widget?.chartType;
            widget.isHaveSize = false;
            // remove duplicate widget
            this.setDashboardItem(widget);
          }
        });
      }

      // remove widget 
      let itemRemove = this.dashboardService.getListDataRemove();
      if (!Utils.isNullOrEmpty(itemRemove) && itemRemove?.length > 0) {
        if (this.dashboardSelected?.setting?.length > 0) {
          itemRemove?.forEach(i => {
            let findIndex = this.dashboardSelected?.setting?.findIndex((s: any) => s.widgetCd == i.widgetCd);
            if (findIndex != -1) {
              if (!Utils.isNullOrEmpty(this.widgetPick)) {
                if (i.widgetCd != this.widgetPick?.widgetCd) {
                  this.dashboardSelected?.setting?.splice(findIndex, 1);
                }
              }
              else {
                this.dashboardSelected?.setting?.splice(findIndex, 1);
              }
            }
          });
        }
      }
      if (this.dashboardSelected?.setting.length > 0) {
        this.dashboardSelected.setting = uniqBy(this.dashboardSelected.setting, 'widgetCd');
      }
      // remove widget is deleted
      let widgetSetting: any[] = [];
      this.dashboardSelected?.setting?.forEach((w: any) => {
        let findWdRemove = this.allWidgets.find(wg => wg.widgetCd == w.widgetCd);
        if (findWdRemove != undefined) {
          widgetSetting.push(w);
        }
      });
      this.dashboardSelected.setting = widgetSetting;
      this.mapDataSetting();
    }
    this.setCurrentDashboard();
  }


  setSettingItem(widget: any) {
    let findWidget = this.allWidgets.find(w => w.widgetCd == widget.widgetCd);
    if (findWidget != undefined) {
      this.dashboardSelected.setting?.map((w: any) => {
        if (w.widgetCd == findWidget.widgetCd) {
          w.type = findWidget.type;
          w.description = findWidget.description;
          w.name = findWidget.name;
        }
      });
    }
  }

  setDashboardItem(widget: any) {
    let findWgIndex = this.dashboardSelected?.setting?.findIndex((s: any) => s.widgetCd === widget.widgetCd);
    if (findWgIndex == -1) {
      this.widgets.push(widget);
      this.dashboardSelected.setting.push(widget);
    }
    else {
      this.setSettingItem(widget);
    }
  }

  mapDataSetting() {
    // map charttype
    if (this.dashboardSelected?.setting?.length > 0) {
      this.dashboardSelected?.setting?.map((widget: any) => {
        let findWidget = this.allWidgets?.find(s => s.widgetCd == widget.widgetCd);
        if (findWidget != undefined) {
          widget.type = findWidget.chartType;
          widget.description = findWidget.description;
          widget.name = findWidget.name;
          widget.widgetCd = findWidget.widgetCd;
          widget.startdate = findWidget.startdate;
          widget.enddate = findWidget.enddate;
          widget.ispublish = findWidget.isPublish;
          widget.isNew = (!findWidget.isPublish) ? false : (this.isOldTemplate(findWidget.upddate ? findWidget.upddate : findWidget.insdate) ? false : true );
        }
      });
    }
  }

  isOldTemplate(upddate: string): boolean {
    const itemUpddate = new Date(upddate);
    itemUpddate.setHours(0,0,0,0);
    const currentDate = new Date();
    currentDate.setHours(0,0,0,0);

    const maxUpddateAllowToShowIcon = new Date();
    //calc date that is 30 day ago => max time to display "new icon"
    maxUpddateAllowToShowIcon.setDate(currentDate.getDate() - 29);
    maxUpddateAllowToShowIcon.setHours(0,0,0,0);

    //if itemUpddate <  thirtyDaysAgo(widget or dashboard hasn't update anything since 30 days ago) => return true
    return itemUpddate < maxUpddateAllowToShowIcon;
}

  checkValidateDashboardName() {
    if (Utils.isNullOrEmpty(this.dashboardName)) {
      this.nameParams.validate = true;
    }
    else {
      this.nameParams.validate = false;
    }
    this.nameParams = cloneDeep(this.nameParams);
  }

  // confirm dialog save data
  async onConfirmSaveData(data: any, isNavigate: boolean = true): Promise<boolean> {
    let actionCreate = SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_CREATION
    //save data
    if (data == this.saveType.SAVE) {
      this.checkValidateDashboardName();
      if (Utils.isNullOrEmpty(this.dashboardName)) {
        return false;
      }
      this.saucerLogService.action(
        {
          content: SCREEN_NAME.UNSAVED_CONFIRM_DIALOG + ' ＞ ' + actionCreate.YES_VIEW.content + ': (' + CONTENT_LOG.DASHBOARD_NAME + ": " + this.dashboardName + ')'
        }, 
        {
          action: actionCreate.YES_VIEW
        }
      );
      await this.handleSaveBtnClick(isNavigate);
      this.saucerLogService.action(
        {
          content: SCREEN_NAME.UNSAVED_CONFIRM_DIALOG + ' ＞ ' + actionCreate.YES.content + ' ＞ ' + CONTENT_LOG.DASHBOARD_NAME + ": " + this.dashboardName
        }, 
        {
          action: actionCreate.YES
        }
      );
      this.dashboardService.setListNewItem([]);
      this.setModeInputName();
    }
    // swidth mode preview
    else if (data == this.saveType.NO) {
      this.logAction = SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_CREATION;
      this.saucerLogService.action(
        {
          content: SCREEN_NAME.UNSAVED_CONFIRM_DIALOG + ' ＞ ' + actionCreate.NO.content
        }, 
        {
          action: actionCreate.NO
        }
      );
      if (this.mode == ScreenMode.ADD) {
        if (isNavigate) this.navigateTo(ROUTE_PATH.DASHBOARD_TEMPLATE);
      }
      else {
        this.dashboardName = this.currentName;
        let dash = cloneDeep(this.dashboardService.getCurrentTemplateData());
        if (!Utils.isNullOrEmpty(dash)) {
          let findDashboard = this.dashboards?.find(d => d.dashboardCd === this.dashboardCd);
          if (findDashboard != undefined) {
            if (Utils.isNullOrEmpty(dash?.setting)) {
              dash.setting = findDashboard?.setting;
            }else{
              dash?.setting.map((x: any) => {
                let widget = findDashboard?.setting?.find((d: any) => d.widgetCd === x.widgetCd);
                if(widget){
                  x.startdate = widget.startdate;
                  x.enddate = widget.enddate;
                  x.datasourceCd = widget.datasourceCd;
                  x.period = widget.period;
                  x.datasourceName = widget.datasourceName;
                  x.publictype = widget.publictype;
                  x.sortArr = widget.sortArr;
                  x.widgetConfig = widget.widgetConfig;
                  x.widgetdetails = widget.widgetdetails;
                }
              })
            }
          }
          let lisWidget = DashboardHelper.checkWidgetNotDetele(dash, this.allWidgets);
          this.dashboardSelected = dash;
          this.dashboardSelected.setting = lisWidget;
        }
        this.loadWidgetContent();
        this.mapDataSetting();
        this.setOptions(this.mode);
        this.clearAllSetting();

        if (!this.isDuplicate) {
          this.dashboardService.setDuplicateItem(null);
        }
        this.setModeInputName();
        this.dashboardService.setListNewItem([]);
        this.switchMode(ScreenMode.PREVIEW);
      }
      this.checkValidateDashboardName();
    }
    else {
      if(this.mode == ScreenMode.ADD) {
        this.saucerLogService.action(
          {
            content: SCREEN_NAME.UNSAVED_CONFIRM_DIALOG + ' ＞ ' + actionCreate.CLOSE.content
          }, 
          {
            action: actionCreate.CLOSE
          }
        );
      }
      this.switchMode(ScreenMode.EDIT);
    }

    return true;
  }

  clearAllSetting() {
    this.dashboardService.setListDataRemove([]);
    this.dashboardService.setListPublicData([]);
    this.dashboardService.setDuplicateItem([]);
    this.dashboardService.setListNewItem([]);
    this.dashboardService.setFeaturePublicChange(null);
  }

  getHeightDashboard() {
    if (!this.gridster) return;
    const minHeight = 'min-height: calc(100vh - 56px)';
    if (this.gridster.mobile) { return `${minHeight}`; }
    const height = this.gridster.curRowHeight * this.gridster.rows + 40;
    return `height: ${height}px; ${minHeight}; `;
  }

  getWidgetType(widgetCd: string) {
    let findItem = this.widgets.find(w => w.widgetCd === widgetCd);
    return findItem ? findItem.chartType : 0;
  }

  getWidgetDescription(widgetCd: string = '') {
    let desc = '';
    let findItem = this.widgets?.find((w: any) => w.widgetCd === widgetCd);
    if (!Utils.isNullOrEmpty(findItem)) {
      desc = findItem?.description || '';
    }
    else {
      let findItem = this.dashboardSelected?.setting?.find((w: any) => w.widgetCd === widgetCd);
      if (!Utils.isNullOrEmpty(findItem)) {
        desc = findItem?.description || '';
      }
    }
    return desc;
  }

  getWidgetWidth(widgetCd: string): number {
    let widgetElement = document.getElementById(widgetCd);
    return widgetElement ? widgetElement?.offsetWidth : 300;
  }

  getWidgetHeight(widgetCd: string): number {
    let widgetElement = document.getElementById(widgetCd);
    return widgetElement ? widgetElement?.offsetHeight : 500;
  }

  // get list dashboard detail
  async getDashboardDetail() {
    let dashboardlLst = await this.findDashboard();
    this.dashboards = dashboardlLst;
    this.dashboardDetailCurrent = dashboardlLst;
  }

  async findDashboard() {
    let db: any[] = [];
    if(!this.isDashboardTemplate) {
      await this.dashboardService.getSettingDetail(this.dashboardCd).then((data: any) => {
          db = data || [];
          db.map((d: any) => {
            d.isHaveSize = true;
          });
      });
    }
    else {
      await this.dashboardService.getSettingDetailTemplate(this.dashboardCd).then((data: any) => {
          db = data|| [];
          db.map((d: any) => {
            d.isHaveSize = true;
          });
        
      });
    }

    return db;
  }


  // get list widget by dashboardCd
  async getWidgetList() {
    if (!Utils.isNullOrEmpty(this.dashboardCd)) {
      if(this.isDashboardTemplate) {
        await this.dashboardService.getWidgetTemplatesByDashboard(this.dashboardCd).then(data => {
          this.widgets = data;
        });
      }
      else {
        await this.dashboardService.getWidgetList(this.dashboardCd).then(data => {
          this.widgets = data || [];
        });
      }
    }
  }

  async remapDashboardInfor() {
    let dataWidgetSetting = await DashboardHelper.getSettingWidgetDetail(this.dashboardService,this.dashboardCd,this.isDashboardTemplate);
    if(dataWidgetSetting.dashboards){this.dashboards = dataWidgetSetting.dashboards }
    if(dataWidgetSetting.widgets){this.widgets = dataWidgetSetting.widgets}
    
    this.dashboardSelected = this.dashboards?.find(d => d.dashboardCd === this.dashboardCd) as Dashboard;
    this.dashboardService.setCurrentTemplateData(cloneDeep(this.dashboardSelected));
    this.dashboardService.setDashboardItem(this.mode, cloneDeep(this.dashboardSelected));
  }

  switchMode(mode: ScreenMode) {
    this.mode = mode;
    this.setOptions(mode);
    this.setFilterPublic(mode);
    this.setTitlePage(mode);
    this.setLocationState(mode);
    this.setModeInputName();
  }

  setFilterPublic(mode: ScreenMode) {
    if(mode == ScreenMode.PREVIEW) {
      this.isFilterPublish = false;
    }
  }

  setOptions(mode: ScreenMode) {
    switch (mode) {
      case ScreenMode.PREVIEW:
        this.titleService.setTitle(DASHBOARD_TEMPLATE_SETTING.TITLE_PREVIEW)
        this.options = {
          compactType: gridster.CompactType.CompactUpAndLeft,
          gridType: gridster.GridType.ScrollVertical,
          defaultItemRows: 7,
          defaultItemCols: 14,
          displayGrid: gridster.DisplayGrid.None,
          disableWindowResize: false,
          enableEmptyCellClick: false,
          enableEmptyCellContextMenu: false,
          enableEmptyCellDrop: true,
          enableEmptyCellDrag: false,
          enableOccupiedCellDrop: true,
          emptyCellDragMaxCols: 50,
          emptyCellDragMaxRows: 50,
          margin: 10,
          minCols: 28,
          minRows: 10,
          maxRows: 1000,
          maxCols: 28,
          minItemRows: 4,
          minItemCols: 7,
          maxItemRows: 28,
          draggable: {
            enabled: false,
            ignoreContent: false,
            dragHandleClass: 'gridster-item-content',
          },
          resizable: {
            enabled: false,
          },
          pushItems: false,
          mobileBreakpoint: 0
        };

        break;
      case ScreenMode.ADD:
      case ScreenMode.EDIT:
        ScreenMode.ADD == mode ? this.titleService.setTitle(DASHBOARD_TEMPLATE_SETTING.TITLE_CREATE): this.titleService.setTitle(DASHBOARD_TEMPLATE_SETTING.TITLE_EDIT)
        this.options = {
          compactType: gridster.CompactType.CompactUpAndLeft,
          gridType: gridster.GridType.ScrollVertical,
          defaultItemRows: 7,
          defaultItemCols: 14,
          displayGrid: gridster.DisplayGrid.Always,
          disableWindowResize: false,
          enableEmptyCellClick: false,
          enableEmptyCellContextMenu: false,
          enableEmptyCellDrop: true,
          enableEmptyCellDrag: false,
          enableOccupiedCellDrop: true,
          emptyCellDragMaxCols: 50,
          emptyCellDragMaxRows: 50,
          margin: 10,
          minCols: 28,
          minRows: 10,
          maxRows: 1000,
          maxCols: 28,
          minItemRows: 4,
          minItemCols: 7,
          maxItemRows: 28,
          draggable: {
            enabled: true,
            ignoreContent: true,
            dragHandleClass: 'gridster-item-content',
          },
          resizable: {
            enabled: true,
          },
          pushItems: true,
          mobileBreakpoint: 0
        };
        break;
      default:
        if(mode == ScreenMode.TEMPLATE) this.titleService.setTitle(DASHBOARD_TEMPLATE_SETTING.TITLE_SELECT)
        break;
    }
  }

  setTitlePage(mode: ScreenMode) {
    this.isDisplayToolTip = false;
    this.nameParams = cloneDeep(this.nameParams);
    switch (mode) {
      case ScreenMode.PREVIEW:
        this.titlePage = DASHBOARD_TEMPLATE_SETTING.TITLE_PREVIEW;
        this.nameParams.hidden = true;
        this.nameParams = cloneDeep(this.nameParams);
        this.isDisplayToolTip = true;
        break;
      case ScreenMode.EDIT:
        this.titlePage = DASHBOARD_TEMPLATE_SETTING.TITLE_EDIT;
        this.nameParams.hidden = false;
        this.nameParams = cloneDeep(this.nameParams);
        break;
      case ScreenMode.ADD:
        this.titlePage = DASHBOARD_TEMPLATE_SETTING.TITLE_CREATE;
        this.nameParams.hidden = false;
        this.nameParams = cloneDeep(this.nameParams);
        break;
      case ScreenMode.TEMPLATE:
        this.titlePage = DASHBOARD_TEMPLATE_SETTING.TITLE_SELECT;
        this.nameParams.hidden = true;
        this.nameParams = cloneDeep(this.nameParams);
        break;
      default:
        break;
    }
  }

  saveBase64Png(id: any, filename: string) {
    this.loadingService.isLoading.emit(true);
    var node = document.getElementById(id);
    if (node == null) return;
    var _self = this;
    htmlToImage.toPng(node, { width: node.scrollWidth, height: node.scrollHeight })
      .then(function (dataUrl) {
        var file = _self.dataURLtoFile(dataUrl, filename);
        FileSaver.saveAs(file);
        _self.loadingService.isLoading.emit(false);
      })
      .catch(function () {
        _self.loadingService.isLoading.emit(false);
      });
  }

  dataURLtoFile(dataurl: any, filename: string) {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getDashboardName(data: any) {
    if(this.mode === ScreenMode.TEMPLATE) return;
    this.dashboardName = data.trim();
    this.dashboardSelected.name = data.trim();
  }

  setLocationState(mode: ScreenMode) {
    switch (mode) {
      case ScreenMode.PREVIEW: {
        const url = LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL) + '/' + this.dashboardCd + '/';
        this.location.replaceState(url + ScreenMode.PREVIEW);
        break;
      }
      case ScreenMode.ADD: {
        const url = LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_TEMPLATE_CREATE) + '/' + this.newDashboard?.dashboardCd + '/';
        this.location.replaceState(url + ScreenMode.ADD);
        break;
      }
      case ScreenMode.EDIT: {
        const url = LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL) + '/' + this.dashboardCd + '/';
        this.location.replaceState(url + ScreenMode.EDIT);
        break;
      }
      default:
        break;
    }
  }

  setLocationStateChangeDB(dbCd: string) {
    const url = LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL) + '/' + dbCd + '/' + this.mode;
    this.location.replaceState(url);
  }

  mapPositionForListWidget(setting: any[]) {
    if (setting?.length > 0) {
      this.widgetChanges?.map(w => {
        let findWg = setting.find(s => s.widgetCd == w.widgetcd);
        if (findWg != undefined) {
          let widgetPosition = JSON.stringify({ x: findWg.x, y: findWg.y, rows: findWg.rows, cols: findWg.cols, width: findWg.width, height: findWg.height });
          w.widgetposition = widgetPosition;
        }
      });
    }
  }

  setCurrentDashboard() {
    let dash = this.dashboardService.getCurrentTemplateData();
    if (Utils.isNullOrEmpty(dash) || this.isSave) {
      this.dashboardService.setCurrentTemplateData(cloneDeep(this.dashboardSelected));
    }
    this.isSave = false;
  }
  backPage() {
    if(this.isDAshboardTemplateChoosingPage) {
      //Log
      this.saucerLogService.action({}, { 
        action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.MAIN.VIEW_RETURN_TO_PREVIOUS_SCREEN
      });
      this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
      this.navigateTo(ROUTE_PATH.WIDGET_DETAIL);
    } 
    else {
      if (this.mode != ScreenMode.PREVIEW) {
        this.saucerLogService.action(
          {
            content: SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + ' ＞ ' + CONTENT_LOG.DASHBOARD_NAME + ': ' + this.dashboardName
          }, 
          {
            action: this.logAction.BACK_SCREEN
          }
        );
        const refBackPage = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
          header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
          width: '35%'
        });
  
        refBackPage.onClose.subscribe((x) => {
          this.onConfirmSaveData(x);
        });
      }
      else {
        let actionEdit = SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_EDITING.BACK_SCREEN;
        this.saucerLogService.action(
          {
            content:  SCREEN_NAME.DASHBOARD_TEMPLATE_EDITING + ' ＞  ' + CONTENT_LOG.DASHBOARD_NAME + ': ' + this.dashboardName
          }, 
          {
            action: actionEdit
          }
        );
        this.dashboardService.setListNewItem([]);
        if (window.location.pathname.includes(ROUTE_PATH.DASHBOARD_TEMPLATE_CREATE) ||
          window.location.pathname.includes(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL)) {
          this.clearAllSetting();
          this.navigateTo(ROUTE_PATH.DASHBOARD_TEMPLATE);
        }
      }
    }
   
  }

  getListDataChange() {
    this.setListWidgetChange();
    this.setListWidgetRemove();

    let param = {
      listDataChange: this.widgetChanges,
      listRemove: this.widgetRemoves,
      dashboard: this.dashboardSelected
    }
    return param;
  }

  async handleSaveBtnClick(isNavigate: boolean = true) {
    if (Utils.isNullOrEmpty(this.dashboardName)) {
      return;
    }
    if (!this.isDisabledButton()) {
      this.switchMode(ScreenMode.PREVIEW);
      this.loadingService.isLoading.emit(true);
      this.mapPositionForListWidget(this.dashboardSelected?.setting);
      // duplicate dashboard 
      if (this.isDuplicate) {
        if (!Utils.isNullOrEmpty(this.duplicateData)) {
          this.getListDataChange();
          this.dashboardName = this.dashboardSelected?.dashboardname == undefined ? this.dashboardSelected?.name == undefined ? '' : this.dashboardSelected?.name : this.dashboardSelected?.dashboardname;

          if (this.duplicateData?.dashboard?.foldercd) {
            this.dashboardSelected.folderCd = this.duplicateData?.dashboard?.foldercd;
          }

          this.dashboardSelected.description = this.dashboardSelected?.dashboarddesc == undefined ? this.dashboardSelected?.description == undefined ? '' : this.dashboardSelected?.description : this.dashboardSelected?.dashboarddesc;
          await this.insertOrUpdateDashboard(this.dashboardSelected, false);
          this.duplicateData.dashboard.dashboardCd = this.dashboardCd;
          this.duplicateData.dashboard.id = this.dashboardSelected?.id;
          let widgetDuplicate = cloneDeep(this.widgetChanges);
          widgetDuplicate?.map(w => {
            w.widgetnew = true;
            w.isupdate = false;
          })
          this.duplicateData.listDataChange = cloneDeep(widgetDuplicate);
          this.duplicateData.listDataChange?.map((dashboard: any) => {
            dashboard.dashboardcd = this.dashboardCd;
          });
          this.duplicateData.listRemove = [];
          let publicCd = this.dashboardSelected.publicCd ? this.dashboardSelected.publicCd : '';
          this.featureDuplicates = await this.getListPublicData(publicCd);
          this.featureDuplicates?.map(fea => {
            fea.featureType = 1;
          });
          this.duplicateData.listFeaturePulic = this.featureDuplicates;
          await this.dashboardService.insertOrUpdateDbDetail(this.duplicateData).then(d => {
            if (d.statuscode == 200) {
              this.newDashboardCd = d.data || '';
            }
          });
        }
        this.isDuplicate = false;
        this.dashboardService.setDuplicateItem(null);
      }
      // update dashboard
      else {
        // insert new dashboard 
        this.errorHandleService.backURLSub.next("");
        let dashboardItem = this.dashboardService.getDashboardItem();
        this.setOptions(this.mode);
        let findDashboard = await this.findDashboard();
        if (dashboardItem.dashboard != null && findDashboard.length == 0) {
          await this.insertOrUpdateDashboard(dashboardItem?.dashboard, false);
        }
        else {
          // update dashboard name
          this.dashboardSelected.id = findDashboard[0]?.id;
          this.dashboardSelected.publiccd = findDashboard[0]?.publicCd;
          this.dashboardSelected.insdate = findDashboard[0]?.insdate;
          this.dashboardSelected.insstfcd = findDashboard[0]?.insstfcd;
          await this.insertOrUpdateDashboard(this.dashboardSelected, true);
        }

        this.newDashboardCd = this.dashboardSelected?.dashboardCd || '';
        // public dashboard
        let dashboardPublicData = this.dashboardService.getFeaturePublicChange();
        if (dashboardPublicData?.listInsert?.length > 0 || dashboardPublicData?.listRemove?.length > 0) {
          dashboardPublicData.dashboardcd = this.newDashboardCd;
          await this.featureService.insertOrUpdate(dashboardPublicData);
        }
        // set param update and insert dashboard detail
        let param = this.getListDataChange();
        let removeDatasourcesPayLoad = {...param, 
          dashboard: {...param.dashboard, setting: (param.dashboard && param.dashboard.setting) ? 
          param.dashboard.setting.map((datasource: any) => {
            return {...datasource, dataSource: []}
          }) : param.dashboard.setting}};
        await this.dashboardService.insertOrUpdateDbDetail(removeDatasourcesPayLoad);
        this.widgetChanges = [];
        this.widgetRemoves = [];
      }
      this.isSave = true;
      await this.remapDashboardInfor();
      await this.initData();
      this.loadWidgetContent();
      await this.getListFolder();
      this.loadingService.isLoading.emit(false);
      this.openDialog(DialogType.save, isNavigate);
      this.clearWidgetData();
      this.setTitlePage(this.mode);
      this.dashboardService.setListDataRemove([]);
      this.dashboardService.setListNewItem([]);
      this.dashboardService.setFeaturePublicChange(null);
    }
  }

  createWidgetTemplate() {
    // this.isDspOfficeModal = true;
    this.showDialog()
  }
  clearWidgetData() {
    this.duplicateData = null;
  }

  setListWidgetRemove() {
    this.widgetRemoves = [];
    let itemRemoves = this.dashboardService.getListDataRemove() || [];
    itemRemoves?.forEach(item => {
      let findWdget = this.getWidgetByWidgetCd(item.widgetCd || '');
      if (findWdget != undefined) {
        let widgetPosition = JSON.stringify({ x: item.x, y: item.y, rows: item.rows, cols: item.cols, width: item.width, height: item.height });
        let findIndex = this.widgetRemoves?.findIndex(s => s.widgetcd == findWdget.widgetCd);
        if (findIndex == -1 && this.widgetPick?.widgetCd != item.widgetCd) {
          this.widgetRemoves.push({
            id: findWdget.id,
            dashboardcd: this.dashboardCd,
            widgetcd: findWdget.widgetCd || '',
            widgetposition: widgetPosition
          });
        }
      }
    })
  }

  handleRemoveWidgetClick($event: MouseEvent | TouchEvent, item: DashboardSetting): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.widgetService.pass({ widgetCd: undefined, folderCd: '', mode: ScreenMode.ADD });
    this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
    // remove widget for list setting
    if (this.dashboardSelected?.setting?.length > 0) {
      this.dashboardSelected?.setting?.splice(this.dashboardSelected?.setting?.indexOf(item), 1);
    }
    // remove widget 
    this.removeItemForWidgetNew(item);
  }

  removeItemForWidgetNew(item: any) {
    // remove widget new
    let listWd = this.dashboardService.getListNewItem() || [];
    let wgIndex = listWd?.findIndex(s => s.widgetCd == item.widgetCd);
    if (wgIndex != -1) {
      listWd?.splice(wgIndex, 1);
    }
    this.dashboardService.setListNewItem(listWd);
    // remove item for list widget
    let indexWg = this.widgets?.findIndex(s => s.widgetCd == item.widgetCd);
    if (indexWg != -1) {
      this.widgets?.splice(indexWg, 1);
    }
    //widgetChanges
    let duplicateIndex = this.duplicateData?.listDataChange?.findIndex((w: any) => w.widgetcd == item.widgetCd);
    if (duplicateIndex != -1) {
      this.duplicateData?.listDataChange?.splice(duplicateIndex, 1);
    }

    let itemRemove = this.dashboardService.getListDataRemove() || [];
    itemRemove.push(item);
    this.dashboardService.setListDataRemove(itemRemove);
    this.saucerLogService.action({
      content: JSON.stringify({
        widgetCd: item?.widgetCd,
        widgetName: (item?.widgetName || item?.name)
      })
    }, { 
			action: this.logAction.DELETE_WIDGET
		});
  }

  handleCreateBtnClick() {
    this.saucerLogService.action(
      {
        content: this.logAction.ADD_WIDGET.screenName + ' ＞ ' + CONTENT_LOG.VIEW
      }, 
      {
        action: this.logAction.ADD_WIDGET
      }
    );
    let dashboardItem = this.dashboardService.getDashboardItem();
    if (!Utils.isNullOrEmpty(dashboardItem?.dashboard?.folderCd)) {
      this.dashboardSelected.folderCd = dashboardItem?.dashboard?.folderCd;
    }
    this.dashboardService.setDashboardItem(this.mode, cloneDeep(this.dashboardSelected));
    this.navigateTo(ROUTE_PATH.WIDGET_TEMPLATE_LIST);
  }

  handleUpdateBtnClick() {
    if (!this.checkPermission) {
      this.switchMode(ScreenMode.EDIT);
      //SaucerLog edit widget in dashboard-template 
      if (this.mode == ScreenMode.EDIT){
        let actionEdit = SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_EDITING.EDIT_DASHBOARD;
        this.saucerLogService.action(
          {
            content: JSON.stringify({
              dashboardCd: this.dashboardCd,
              dashboardName: this.dashboardName
            })
          }, 
          {
            action: actionEdit
          }
        );
      }
      this.setModeInputName();
    }
  }

  //insert or update dashboard info
  async insertOrUpdateDashboard(data: any, isUpdate: boolean) {
    let reqData = [];
    let dash = this.dashboardService.mapDashboardModel(data);
    dash.dashboardname = this.dashboardName;
    dash.upddate = new Date();
    if (Utils.isNullOrEmpty(dash?.publiccd)) {
      dash.publiccd = data?.publiccd || '';
    }
    reqData.push(dash);
    await this.dashboardService.insertOrUpdate(reqData, isUpdate).then(request => {
      if (request.statuscode == 200) {
        this.dashboardSelected.dashboardCd = request.data || '';
        this.dashboardCd = request.data || '';
      }
    });
  }

  async openDialog(dialogType: DialogType, isNavigate: boolean = true) {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    });
    this.dashboardCd = this.newDashboardCd;
    this.loadWidgetContent();

    modalRef.onClose.subscribe(data => {
      switch (this.mode) {
        case ScreenMode.ADD:
          if (isNavigate) this.navigateTo(ROUTE_PATH.DASHBOARD_TEMPLATE_CREATE);
          break;

        case ScreenMode.EDIT:
        case ScreenMode.PREVIEW:
          if (isNavigate) this.navigateTo(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL);
          break;

        default:
          break;
      }
    });
  }

  onDeleteDashboard() {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
    if (!this.checkPermission) {
      this.isDisplayConfirmDeleteModal = true;
      this.modalData.header = COMMON_TEXT.CONFIRM_DELETE;
      this.modalData.style = { width: '310px' }
    }
  }

  async onConfirmDeleteDlg(event: any) {
    this.isDisplayConfirmDeleteModal = false;
    if (event === true) {
      this.loadingService.isLoading.emit(true);
      this.setListWidgetChange();
      this.setListWidgetRemove();
      this.widgetRemoves = this.widgetChanges;
      let param = {
        listRemove: this.widgetRemoves,
        dashboard: {
          dashboardCd: this.dashboardCd,
          dashboardName: this.dashboardName
        }
      }
      this.dashboardService.deleteDashboardAndDetail(param).then(d => {
        if (d.statuscode == 200) {
          this.modalService.open(ConfirmDialogComponent, {
            data: {
              dialogType: DialogType.delete,
            }
          });
          this.navigateTo(ROUTE_PATH.DASHBOARD_TEMPLATE);
        }
      });
      this.widgetChanges = [];
      this.widgetRemoves = [];
      this.loadingService.isLoading.emit(false);
    }
  }

  onDownloadImage() {
    this.saucerLogService.action(
      {
        content: this.logAction.DOWNLOAD_IMAGE.screenName + " " + CONTENT_LOG.DASHBOARD_NAME + ': ' + this.dashboardName
      }, 
      {
        action: this.logAction.DOWNLOAD_IMAGE
      }
    );
    this.saveBase64Png('dashboardSettingDownload', 'dashboard-setting' + '_' + moment(Date.now()).format(DateFormat.FULL_LONG_DATE) + '.png');
  }

  onDuplicateDashboard() {
    this.isDuplicate = true;
    if (Utils.isNullOrEmpty(this.dashboardSelected)) return;
    this.newDashboard = this.dashboardSelected;
    this.newDashboard.name = '';
    this.newDashboard.name = this.dashboardName + COMMON_TEXT.EXTEND_COPY;
    this.modalAddDashboard.header = DASHBOARD_TEMPLATE_SETTING.CP_NEW;
    this.modalAddDashboard.style = { 'width': '557px' };
    this.modalAddDashboard.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDisplayDashboardModal = true;
    this.saucerLogService.action(
      {
        content: this.logAction.CREATE_DASHBOARD.screenName + ' ＞ ' + CONTENT_LOG.SAVE_AS + ' ＞ ' + CONTENT_LOG.DASHBOARD_NAME + ': ' + this.dashboardName + COMMON_TEXT.EXTEND_COPY
      }, 
      {
        action: this.logAction.CREATE_DASHBOARD
      }
    );
  }

  onNewDashboard() {
    this.isDuplicate = false;
    this.isDisplayDashboardModal = true;
    this.widgetService.pass({ widgetCd: undefined, folderCd: '', mode: ScreenMode.ADD });
    this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
    this.dashboardService.setDuplicateItem(null);
    this.dashboardService.setListPublicData([]);
    this.widgets = [];
    this.dashboardSelected.publicCd = '';
    this.newDashboard = null;
    this.dashboardCd = '';
    this.modalAddDashboard.header = DASHBOARD_TEMPLATE_SETTING.CP_NEW;
    this.modalAddDashboard.style = { 'width': '557px' };
    this.modalAddDashboard.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isCreate = true;
    this.saucerLogService.action(
      {
        content: this.logAction.CREATE_DASHBOARD.screenName + ' ＞ ' + CONTENT_LOG.NEW_DASHBOARD 
      }, 
      {
        action: this.logAction.CREATE_DASHBOARD
      }
    );
  }

  async handleEditWidgetClick(wdg: any) {
    this.saucerLogService.action(
      {
        content: JSON.stringify({
          widgetCd: wdg?.widgetCd,
          widgetName: wdg?.widgetName || wdg?.name
        })
      },
      { 
			  action: this.logAction.UPDATE_WIDGET
		  }
    );
    await this.widgetService.getDetail(wdg.widgetCd).then(ws => {
      if (ws.statuscode == 200) {
        if (ws.data?.length > 0) {
          this.dashboardService.setDashboardItem(this.mode, cloneDeep(this.dashboardSelected));
          var widget = { mode: ScreenMode.EDIT, widgetCd: wdg.widgetCd, folderCd: ws.data[0]?.foldercd as string }
          this.widgetService.pass(widget);
          this.navigateTo(ROUTE_PATH.WIDGET_TEMPLATE_DETAIL);
        }
      }
    });
  }

  getWidgetByWidgetCd(widgetCd: string) {
    return this.allWidgets.find(s => s.widgetCd == widgetCd);
  }

  widgetResize(event: any) {
    this.setWidgetPositionAndResize(event);
  }

  setWidgetPositionAndResize(event: any) {
    let position = JSON.stringify({ "x": event?.itemComponent?.$item?.x, "y": event?.itemComponent?.$item?.y, "rows": event?.itemComponent?.$item?.rows, "cols": event?.itemComponent?.$item?.cols, "width": event?.item?.width, "height": event?.item?.height })
    if (this.dashboardSelected?.setting?.length > 0) {
      this.dashboardSelected?.setting?.map((w: any) => {
        if (w.widgetCd == event.item?.widgetCd) {
          w.widgetPosition = position;
          w.type = event?.item?.type;
          w.width = (event?.itemComponent?.width > 20) ? event?.itemComponent?.width - 20 : 500;
          w.height = (event?.itemComponent?.height > 80) ? event?.itemComponent?.height - 80 : 500;
          w.rows = event?.itemComponent?.$item?.rows;
          w.cols = event?.itemComponent?.$item?.cols;
          w.x = event?.itemComponent?.$item?.x;
          w.y = event?.itemComponent?.$item?.y;
        }
      });
    }
    !this.isDAshboardTemplateChoosingPage ? this.dashboardService.setDashboardItem(this.mode, cloneDeep(this.dashboardSelected)): null;
  }

  widgetChange(event: any) {
    this.setWidgetPositionAndResize(event);
  }

  setListWidgetChange() {
    this.widgetChanges = [];
    let settingLst = this.dashboardDetailCurrent[0]?.setting || null;
    // get list new widget
    let widgetNews = this.dashboardService.getListNewItem() || [];
    this.dashboardSelected?.setting.forEach((item: any) => {
      let findWdget = this.getWidgetByWidgetCd(item.widgetCd);
      if (findWdget != undefined) {
        let widgetNew = widgetNews.find(s => s.widgetCd == item.widgetCd);
        let widgetPosition = JSON.stringify({ "x": item.x, "y": item.y, "rows": item.rows, "cols": item.cols, "width": item.width, "height": item.height });
        let isUpdateWg = false;
        let findWidgetCurrent = null;
        if (settingLst?.length > 0) {
          findWidgetCurrent = settingLst?.find((wd: any) => wd.widgetCd == item.widgetCd && wd.dashboardCd == item.dashboardCd);
          if (!Utils.isNullOrEmpty(findWidgetCurrent)) {
            isUpdateWg = true;
          }
        }
        // check widget new
        let findIndex = this.widgetChanges.findIndex(w => w.widgetcd == findWdget.widgetCd);
        if (findIndex == -1) {
          this.widgetChanges.push({
            id: findWdget.id,
            dashboardcd: this.dashboardCd,
            widgetcd: findWdget.widgetCd || '',
            widgetposition: widgetPosition,
            isupdate: (this.isDuplicate == true || !Utils.isNullOrEmpty(widgetNew) || this.mode == 1) ? false : isUpdateWg,
            widgetnew: (!Utils.isNullOrEmpty(widgetNew) || this.mode == 1) ? true : false
          });
        }
        this.widgetChanges = uniqBy(this.widgetChanges, 'widgetcd');
      }
    });
  }

  async getListPublicData(publicCd: any) {
    let listPublic: any[] = [];
    if (!Utils.isNullOrEmpty(publicCd)) {
      await this.featureService.getByType(publicCd).then(p => {
        if (p.statuscode == 200) {
          listPublic = p.data || [];
        }
      });
    }
    return listPublic;
  }

  async onChangeDashboardData(data: any) {
    this.newDashboardCd = data?.dashboardCd;
    if (data == null) {
      this.dashboardSelected.name = this.currentName;
      this.isDisplayDashboardModal = false;
    }
    else {
      this.dashboardName = data.name || '';
      if (this.isDuplicate) {
        this.loadingService.isLoading.emit(true);
        let publicCd = data.publicCd ? data.publicCd : '';
        this.featureDuplicates = await this.getListPublicData(publicCd);
        this.setListWidgetChange();
        this.setListWidgetRemove();
        this.duplicateData = {
          listDataChange: cloneDeep(this.widgetChanges),
          listRemove: cloneDeep(this.widgetRemoves),
          featureDuplicates: this.featureDuplicates,
          isduplicate: true,
          dashboard: {
            dashboardCd: data.dashboardCd,
            dashboarddesc: data.description,
            dashboardname: data.name,
            foldercd: data.folderCd,
            publicCd: publicCd
          }
        }
        this.dashboardService.setCurrentData(this.duplicateData.dashboard);
        this.dashboardService.setDuplicateItem(this.duplicateData);
        this.dashboardService.setDashboardItem(2, cloneDeep(this.duplicateData?.dashboard));
        this.widgetChanges = [];
        this.loadingService.isLoading.emit(false);
        this.switchMode(ScreenMode.ADD);
      }
      else {
        this.newDashboard = data;
        this.dashboardService.setDashboardItem(ScreenMode.ADD, cloneDeep(data));
        this.dashboardService.setDuplicateItem([]);
        if (this.isCreate) {
          this.dashboardSelected = data;
          if (Utils.isNullOrEmpty(this.dashboardSelected?.setting)) {
            this.dashboardSelected.setting = [];
          }
          this.dashboardService.setDuplicateItem(null);
          this.isCreate = false;
        }
        this.switchMode(this.mode);
      }
      this.isDisplayDashboardModal = false;

    }
  }

  isDisabledButton(): boolean {
    if (Utils.isNullOrEmpty(this.dashboardName) || this.dashboardName === "") return true;
    return false;
  }

  isDisabledSetButton(): boolean {
    // if(this.selectedFolder.name == undefined) return true;
    if (!this.selectedWigdetRadio) return true;
    return false
  }

  canDeactivate(url: string | undefined): boolean | Observable<boolean> | Promise<boolean> {
    if (this.useCanDeactivate && !this.backActionFromBrowser) {
      if(this.isByPassConfirmDialog) {
        this.errorHandleService.isByPassConfirmDialogSub.next(false);
        if(this.ref)
          this.ref.destroy();
        return true
      }
      if (this.mode != ScreenMode.PREVIEW) {
        const ref = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
          header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
          width: '35%'
        });

        return firstValueFrom(ref.onClose.pipe(mergeMap(async x => {
          if (!x || x == SaveType.CANCEL) return false;

          return await this.onConfirmSaveData(x, false)
        })))
      }
      else {
        this.dashboardService.setListNewItem([]);
        if (window.location.pathname.includes(ROUTE_PATH.DASHBOARD_TEMPLATE_CREATE) ||
          window.location.pathname.includes(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL)) {
          this.clearAllSetting();
          return true;
        } else {
          return false;
        }
      }
    } else {
      this.backActionFromBrowser = false;
      this.useCanDeactivate = true;
      return true;
    }
  }

  navigateTo(screen: string | undefined, commands: any[] = []) {
    this.useCanDeactivate = false;
    switch (screen) {
      case ROUTE_PATH.DASHBOARD_TEMPLATE:
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_TEMPLATE)]);
        break;
      case ROUTE_PATH.WIDGET_TEMPLATE_LIST:
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_TEMPLATE_LIST)]);
        break;
      case ROUTE_PATH.DASHBOARD_TEMPLATE_CREATE:
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_TEMPLATE_CREATE) + '/' + this.newDashboardCd + '/' + this.mode]);
        break;
      case ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL:
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL) + '/' + this.newDashboardCd + '/' + this.mode]);
        break;
      case ROUTE_PATH.WIDGET_TEMPLATE_DETAIL:
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_TEMPLATE_DETAIL)]);
        break;
      case ROUTE_PATH.WIDGET_DETAIL:
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_DETAIL)]);
        break;
      case "URL":
        this.routerNavigate.navigate(commands);
        break;
      default:
        break;
    }
  }

  onFilterDataMenu(searchValue: any) {
    //#region Log
    let actionLog = this.isDAshboardTemplateChoosingPage ? 
                    SAUCER_LOG_ACTION.TEMPLATE_SELECTION.MAIN.SEARCH: 
                    SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_EDITING.SEARCH;
    this.saucerLogService.action({
      content: actionLog.screenName + ' ＞ ' + actionLog.content + searchValue,
    }, 
    {
      action: actionLog
    });
    //#endregion

    if (searchValue == "") {
      this.menuItems = cloneDeep(this.menuItemsBackup);
    } else {
      this.menuItems = this.filterSearch(cloneDeep(this.menuItemsBackup), searchValue);
    }
  }

  filterSearch(inputArray: any, searchValue: any) {
    let returnArray: { label: any; expanded: boolean; id: any; items: any[]; }[] = [];
    inputArray.forEach((parent: any) => {
      if (parent.label.toLowerCase().trim().includes(searchValue.toLowerCase().trim())) {
        returnArray.push(parent);
      } else {
        let matchedChild = parent.items.filter((child: { label: string; }) => child.label.toLowerCase().trim().includes(searchValue.toLowerCase().trim()));
        if (matchedChild.length > 0) {
          returnArray.push({
            label: parent.label,
            id: parent.id,
            expanded: true,
            items: [...matchedChild]
          });
        }
      }
    });
    return returnArray;
  }

  // showFolderModal() {
  //   this.isShowFolder = true;
  //   if (this.selectedFolder && this.selectedFolder.id) {
  //     this.selectedFolderRow = this.folderList.find(f => f.folderCd === this.selectedFolder.id);
  //   }
  // }

  // closeFolderModal() {
  //   this.isShowFolder = false;
  // }

  // changeFolder(folder: any) {
  //   if (!Utils.isNullOrEmpty(folder)) {
  //     this.selectedFolder.id = folder.folderCd;
  //     this.selectedFolder.name = folder.name;
  //   } else this.selectedFolder = null;
  // }

  getLabelPublish(input: any) {
    if (input.length % 2 === 0) {
      return "公開";
    } else {
      return "非公開";
    }
  }

  getPubicDate(widgetCD: string): string {
    let desc = '';
    let findItem = this.allWidgets?.find((w: any) => w.widgetCd === widgetCD);
    if (!Utils.isNullOrEmpty(findItem)) {
      desc = findItem?.publicDate || '';
    }
    return isPubishWidget(desc) ? "公開" : "非公開";
  }


  // close dialog office
  closeOfficeModal() {
    this.isDspOfficeModal = false;
  }

    // on change data for datasource
  async selectOfficeAndCreateWidget(office: Office[]) {
    this.loadingService.isLoading.emit(true);
    this.useCanDeactivate = false;
    localStorage.setItem(LocalStorageKey.SELECTED_OFFICES, JSON.stringify(office));
  
    let datasourceTemplateRequest = {
      dsstructcd : this.datasourceCdTemplate,
      dsname : this.datasourceNameTemplate,
      period: this.datasourcePeriod,
      widgetcd: this.widgetCdTemplate,
      foldercd: this.selectedFolder.id,
      startdate : this.selectedRangeDate.startDate,
      enddate:  this.selectedRangeDate.endDate,
      officecds: office.map(o => o.officeCd)
    }

  await this.dataSourceService.cloneDataSourceTemplate(datasourceTemplateRequest).then(templateRes => {
    if(templateRes.statuscode === 200)
    {
      const screenCode = 1 + Math.random();
      let intervalId = setInterval(()=> {
        if(this.checkIfStopInterval(screenCode, intervalId)) {
          return;
        }
        this.stepFunctionService.checkStatus(templateRes.data.lambdaresponse.executionarn).then(resLamda => {
          if(resLamda.statuscode && resLamda.statuscode == 500) {
            clearInterval(intervalId);
            this.removeIntervalIdToList(intervalId);
          }
          if(resLamda.data.status === LambdaStatus.SUCCEEDED)
          {
            clearInterval(intervalId);
            this.removeIntervalIdToList(intervalId);
            this.dataSourceService.getResultOnS3(templateRes.data.dsstructcd).then(res =>{
              let resultData = JSON.parse(res.data);
              if(res.status && res.status == 500)  clearInterval(intervalId);
              if(res.statuscode == 200 && resultData.status === true) {
                console.log('created DDS', templateRes.data.dsstructcd);
                this.widgetService.setCreatingByTemplate({widgetcdTemplate: this.widgetCdTemplate, dsStructCd: templateRes.data.dsstructcd, lstOffices: office.map(o => o.officeCd)});
                this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_DETAIL)]);
              }
            })
          }
          else if(resLamda.data.status != LambdaStatus.RUNNING && resLamda.data.status != "") {
            clearInterval(intervalId);
            this.removeIntervalIdToList(intervalId);
            this.loadingService.isLoading.emit(false)
          }
        })
      }, 5000);
      this.addIntervalIdToList(intervalId);
    }
    else
      this.errorHandleService.backURLSub.next(ROUTE_PATH.DASHBOARD_WIDGET_TEMPLATE + "/4"); 
  })
  }

  filterPublicWidgets(){
    this.mapDataSetting();
    this.isFilterPublish = !this.isFilterPublish;

    if(this.isFilterPublish) {
      this.widgetList = cloneDeep(this.dashboardSelected.setting);
      this.dashboardSelected.setting = cloneDeep(this.dashboardSelected.setting.filter((item : any) => item.ispublish));
    }
    else 
    this.dashboardSelected.setting = cloneDeep(this.widgetList);
  }

  chooseTemplate(widgetCdTemplate: string, datasourceCd: string, period: string, datasourceName: string) {
    this.widgetCdTemplate = widgetCdTemplate;
    this.datasourceCdTemplate = datasourceCd;
    this.datasourceNameTemplate = datasourceName;
    this.datasourcePeriod = period; 

    if(period.includes('~')) {
      let range = period.split('~');
      this.selectedRangeDate = {startDate: range[0], endDate: range[1]}
    }
    else {
      this.groupedPeriod.forEach((group: any) => {
        let selectedItem = group.items.filter((item:any) => item.value === period).pop();
        if(selectedItem) {
          this.selectedRangeDate  =  {startDate: selectedItem.startdate, endDate: selectedItem.enddate}
        }
      });
    }
  }

  callTemplateDatasourceDialog(data: any) {
    this.ref = this.modalService.open(TemplateDatasourceDialogComponent, {
      // header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
      header: "設定",
      width: '70vh',
      height: '85vh',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      // maximizable: true,
      data: data
    });

    this.ref.onClose.subscribe((data) => {
      if (!data || data == 'cancel') return
      this.templateItemParams = data;
      this.loadingService.isLoading.emit(true);
      this.useCanDeactivate = false;
      localStorage.setItem(LocalStorageKey.SELECTED_OFFICES, JSON.stringify(data.selectedOffices));

      let datasourceTemplateRequest = {
        dsstructcd: this.datasourceCdTemplate,
        widgetcd: this.widgetCdTemplate,
        foldercd: data.selectedFolder.id,
        startdate: data.selectedRangeDate.startDate,
        enddate: data.selectedRangeDate.endDate,
        officecds: data.officeCds,
        dsName: data.datasourceNm,
        period: data.period
      }

      if (data.isOptionCreate) {
        this.dataSourceService.checkTitleExist(datasourceTemplateRequest).then(ttlRes =>{
          if (ttlRes.statuscode === 200 && ttlRes.data) {
            this.dataSourceService.previewDataSourceTemplate(datasourceTemplateRequest).then(templateRes => {
              if (templateRes.statuscode === 200) {
                const screenCode = 1 +  Math.random();
                let intervalId = setInterval(() => {
                  if(this.checkIfStopInterval(screenCode, intervalId)) {
                    return;
                  }
                  this.stepFunctionService.checkStatus(templateRes.data.lambdaresponse.executionarn).then(resLamda => {
                    if(resLamda.statuscode && resLamda.statuscode == 500) {
                      clearInterval(intervalId);
                      this.removeIntervalIdToList(intervalId);
                    }
                    if (resLamda.data.status === LambdaStatus.SUCCEEDED) {
                      clearInterval(intervalId);
                      this.removeIntervalIdToList(intervalId);
                      this.dataSourceService.cloneDataSourceTemplate(datasourceTemplateRequest).then(templateRes => {
                        if (templateRes.statuscode === 200) {
                          const screenCodeFor2rdProcess = 1 + Math.random();
                          let intervalId = setInterval(() => {
                            if(this.checkIfStopInterval(screenCodeFor2rdProcess, intervalId)) {
                              return;
                            }
                            this.stepFunctionService.checkStatus(templateRes.data.lambdaresponse.executionarn).then(resLamda => {
                              if(resLamda.statuscode && resLamda.statuscode == 500) {
                                clearInterval(intervalId);
                                this.removeIntervalIdToList(intervalId);
                              }
                              if (resLamda.data.status === LambdaStatus.SUCCEEDED) {
                                clearInterval(intervalId);
                                this.removeIntervalIdToList(intervalId);
                                this.dataSourceService.getResultOnS3(templateRes.data.dsstructcd).then(res => {
                                  let resultData = JSON.parse(res.data);
                                  if (res.statuscode == 200 && resultData.status === true) {
                                    this.widgetService.setCreatingByTemplate({ widgetcdTemplate: this.widgetCdTemplate, dsStructCd: templateRes.data.dsstructcd, useExistDs: false, lstOffices: data.officeCds });
                                    this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_DETAIL)]);
                                  }
                                })
                              } else if (resLamda.data.status != LambdaStatus.RUNNING && resLamda.data.status != "") {
                                clearInterval(intervalId);
                                this.removeIntervalIdToList(intervalId);
                                this.loadingService.isLoading.emit(false);
                              }
                            })
                          }, 5000);
                          this.addIntervalIdToList(intervalId);
                        }
                        else
                          this.errorHandleService.backURLSub.next(ROUTE_PATH.DASHBOARD_WIDGET_TEMPLATE + "/4");
                      });
                    } else if (resLamda.data.status != LambdaStatus.RUNNING && resLamda.data.status != "") {
                      clearInterval(intervalId);
                      this.removeIntervalIdToList(intervalId);
                      this.loadingService.isLoading.emit(false);
                      if(resLamda.data.output.isoverlimitrecord) {
                        this.isDisplayWarning = true;
                        this.warningMessage = MESSAGE_TEXT.DS_OVER_LIMIT_RECORDS;
                        this.loadingService.isLoading.emit(false)
                      } 
                    }
                  })
                }, 5000);
                this.addIntervalIdToList(intervalId);
              }
            });
          } else {
            this.loadingService.isLoading.emit(false);
            this.showDialogError();
          }
        })
        
      } else {
        this.widgetService.setCreatingByTemplate({ widgetcdTemplate: this.widgetCdTemplate, dsStructCd: data.existDatasourceCd, useExistDs: true , lstOffices: data.officeCds});
        this.loadingService.isLoading.emit(false)
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_DETAIL)]);
      }

    })
  }

  showDialogError() {
    let modalParam = new ModalParam();
    modalParam.description = COMMON_TEXT.ERROR_MESSAGE_TITLE_NOT_EXIST;
    let btnOk = new ButtonItem();
    btnOk.buttonText = COMMON_TEXT.YES;
    btnOk.buttonType = ButtonType.SECONDARY;
    modalParam.buttonArray = [btnOk];
    this.modalService.open(DialogModalComponent, {
      header: COMMON_TEXT.INFORMATION,
      width: '30em',
      data: { modalParam },
    });
  }
  
  showDialog() {
   this.callTemplateDatasourceDialog({
      selectedOfficeServices: [],
      showModal: false,
      datasourceCdTemplate: this.datasourceCdTemplate,
      datasourceNameTemplate: this.datasourceNameTemplate,
      datasourcePeriod: this.datasourcePeriod
    });

    //Log
    const widgetSelected = this.dashboardSelected?.setting?.find((x:any)=> x.widgetCd === this.selectedWigdetRadio);
    this.saucerLogService.action({
      content: JSON.stringify({
        dashboardCd: this.dashboardSelected.dashboardCd,
        dashboardName: this.dashboardSelected.name,
        datasourceCd: this.datasourceCdTemplate,
        datasourceName: this.datasourceNameTemplate,
        datasourcePeriod: this.datasourcePeriod,
        widgetCd: widgetSelected?.widgetCd,
        widgetName: widgetSelected?.widgetName,
      })
    }, { 
      action: SAUCER_LOG_ACTION.TEMPLATE_SELECTION.MAIN.SHOW_DIALOG
    });
  }

  closeWarning() {
    this.isDisplayWarning = false;
    this.callTemplateDatasourceDialog({
      selectedOfficeServices: [],
      showModal: false,
      datasourceCdTemplate: this.datasourceCdTemplate,
      datasourceNameTemplate: this.datasourceNameTemplate,
      datasourcePeriod: this.datasourcePeriod,
      inputParams: this.templateItemParams,
      tabType: 2
    });
  }

  addIntervalIdToList(id: any) {
    if(this.intervalIdList.includes(id)) return;
    this.intervalIdList.push(id);
    this.clearProcessService.setIntervalId(id);
  }

  removeIntervalIdToList(id: any) {
    this.intervalIdList = this.intervalIdList.filter(e => e != id);
    this.clearProcessService.removeIntervalIdList(id);
    this.firstTimeCalling = true;
  }

  checkIfStopInterval(screenCode: any, intervalId: any): boolean {
    let result: boolean = false;
    if(this.firstTimeCalling) {
      this.firstTimeCalling = false;
      this.clearProcessService.setScreenCode(screenCode);
    }
    const holderCode = this.clearProcessService.getScreenCode();
    if(holderCode != screenCode) {
      clearInterval(intervalId);
      this.removeIntervalIdToList(intervalId);
      result = true;
    }

    return result;
  }
}
