import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { cloneDeep, findIndex } from 'lodash';
import { toFullwidthKana } from 'japanese-string-utils';
import StringUtils from '../../../util/stringUtils';
import { COMMON_TEXT } from '../../../const/text-common';
import { LazyTreeNode, TreeNode } from 'src/app/models/common-model';


@Component({
  selector: 'pivot-zaitaku-treenode-titles',
  templateUrl: './treenode-titles.component.html',
  styleUrls: ['./treenode-titles.component.scss']
})
export class ZaitakuTreeNodeTitlesComponent implements OnInit {

  @Output() _onSearchData = new EventEmitter<any>();
  @Output() _onHide = new EventEmitter<any>();
  @Input() _displayFilter: any;
  @Input() _tempDataFilters: any = [];
  @Input() displayText: string = '';
  @Input() _selectedData: any = null;
  @Input() isGroup: boolean = false;
  @Input() defaultValue: string = '';
  @Input() isFilter: boolean = true;
  @Input() isMutiple: boolean = true;
  @Input() appendToPosition: string = 'body';
  @Input() class: string = 'z-10000'; 
  @Input() scrollHiden = false;
  @Input() cssStyle: any = {};
  @Input() placeHolderFilter: string = '';
  @Input() maxLength: number = 100;

  @Input() objectData: any = {};

  @Output() leafNodeClick = new EventEmitter<any>();
  

  borderStyle: any = {};
  checkSelected: boolean = false;
  itemSelectedDefault: any = null;
  lazyComposedData: LazyTreeNode[] = [];

  subject: Subject<any> = new Subject();
  tempDataFilters: any = [];
  dataFilters = [];
  selectedData: any = null;
  isSearch = false;
  protected clickEventListener: any;
  COMMON_TEXT = COMMON_TEXT;
  subscription: Subscription;
  treeViewList:LazyTreeNode [] = [];
  treeViewNode: TreeNode[] = [];
  nodeClicked: LazyTreeNode = new LazyTreeNode();
  dragScopeTreeView = 'column-area';
  scrollHeight = 300;
  readonly treeNodeHeight: number = 27;

  searchText: string = "";
  treeViewNode_bk: TreeNode[] = [];


  @ViewChild('op', { static: false }) filter: any;
  constructor(private router: Router) { }

  ngOnInit(): void {

    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this._displayFilter = null;
      this.filter.hide();
    });
    this.tempDataFilters = [];
    this.dataFilters = [];
    this.selectedData = null;
    this.isSearch = false;
    let data: any[] = [];
    this.itemSelectedDefault = [];
    if(this._tempDataFilters) {
      let index: number = this._tempDataFilters.findIndex((s: any) => s.name === this.defaultValue);
      if (index != -1) {
        data.push(this._tempDataFilters[index]);
        this.itemSelectedDefault = data;
      }
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    if (this.scrollHiden) {
      this._displayFilter = null;
      this.filter.hide();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes["objectData"]) {
      let objectData = changes["objectData"].currentValue;
      this.treeViewNode = objectData.treeViewNode;
      this.treeViewList = objectData.treeViewList;
      this.treeViewNode_bk = cloneDeep(objectData.treeViewNode);
    }
    if (this.isFilter) {
      this.borderStyle = { 'border-top': 'solid 1px #969696' };
    }
    if (!this.isSearch && this.tempDataFilters?.length === 0) {
      this.tempDataFilters = this._tempDataFilters;
    };
    if (this._displayFilter === undefined || this._displayFilter === null || !this._displayFilter.type) {
      this.dataFilters = this._tempDataFilters;
      if (!this.isSearch) {
        this.tempDataFilters = this._tempDataFilters;
      }
    } else {
      this.dataFilters = this.tempDataFilters;
      this.filter.toggle(this._displayFilter);
    
    };
    if (this._selectedData == null) {
      this._selectedData = this.itemSelectedDefault;
    }
    this.selectedData = this._selectedData;
  }

  ngOnDestroy() {
    this.subject.unsubscribe();
    if(this.subscription){
      this.subscription.unsubscribe();
    }
    
  }

  // display dialog filter
  displayPopup() {
    this.subject.next;
  }

  // Covert half width to full width
  toFullWidth(str: string): string {
    if (str == undefined) return '';
    return StringUtils.toFullWidthJP(toFullwidthKana(str));
  }

  // event search data
  onSearchData(event: any) {
    let value: any = event.currentTarget?.value;
    if (value?.indexOf(' ') === 0 && value?.trim()?.length === 0) return;
    value = value?.trim();
    this.searchText = value;
    if (value) {
      this.treeViewNode = this.loadNodes(this.treeViewNode_bk);
    } else {
      this.treeViewNode = cloneDeep(this.treeViewNode_bk);
    }
    this.treeViewList = this.updateLazyTree(this.treeViewNode);
    if(this.treeViewList) this.treeViewList.map(item => {
      if (item.level == 0) item.isLastChild = false
      return item;
    })
  }

  // Hide popup when click outside popup.
  clickOutside() {
    const element = (event as MouseEvent).target as HTMLElement;

    if (!this.filter.container.contains(element)
      && this.filter?.target !== element
      && !this.filter?.target?.contains(element)
      && !this.filter.selfClick) {
      this.filter.hide();
    }
  }

  // Remove event after overlay gets hidden.
  removeClickEvent() {
    this._onHide.emit(true);
    this.isSearch = false;
  }

  handleItemClick(node: LazyTreeNode){
    this.nodeClicked = node;
    if (node?.node?.nodes?.length != 0) {
      this.treeViewList = this.updateLazyTree(this.treeViewNode);
    } else {
      this.filter.hide();
    }
  }

  onClickLeafNode(node:LazyTreeNode) {
    this.leafNodeClick.emit(node);
    this.filter.hide();
  }

  updateLazyTree(nodes?: TreeNode[]) {
    if(nodes && nodes.length) {
      this.lazyComposedData = [];
      this.serializeNodes(nodes!, 0, undefined, true);
      return [...this.lazyComposedData];
    }
    else return [];
  }

  serializeNodes(nodes: TreeNode[], level: number, parent?: LazyTreeNode, showIcon?: boolean) {
    if (nodes && nodes.length) {
        for (const [index,node] of nodes.entries()) {
            const rowNode = new LazyTreeNode();
            rowNode.parent = parent;
            rowNode.id = node.id;
            rowNode.level = level;
            rowNode.isFolder = (level == 0);
            rowNode.text = node.label ?? '';
            rowNode.tooltip = node.tooltip?.toString() || '';
            rowNode.expandable = Boolean(node.nodes && nodes.length);
            rowNode.node = node;
            rowNode.draggable = node.draggable;
            rowNode.style = node.style;
            rowNode.icon = node.icon;
            if (!rowNode.parent) {
              rowNode.isFirstNode = true;
            }

            if (!node.nodes || node.nodes.length === 0) {
              rowNode.isLastNode = true;
              rowNode.showIcon = showIcon
            }
            rowNode.isLastChild  = (index == (nodes.length -1));
            rowNode.isParentLastChild = level <= 1 ? [] : ([...(parent?.isParentLastChild??[]), (parent?.isLastChild ?? true)]);
            this.lazyComposedData.push(rowNode);

            if (node.nodes && node.expanded) {
              rowNode.expanded = node.expanded;
              this.serializeNodes(node.nodes, level + 1, rowNode, showIcon);
            }
        }
    }
  }

  loadNodes(treeNode: TreeNode[]) {
    let items = cloneDeep(treeNode)
    if (items) {
      items = items.filter(node => {
        node.nodes = this.loadNodes(node.nodes || []);
        node.isShowExpandIcon = node.nodes && node.nodes.length > 0;
        node.expanded = false;
        return this.keepNode(node)
      })
    }
    return items;
  }

  keepNode(node: TreeNode): boolean {
    if (!this.searchText) {
      return true; // Giữ lại tất cả nếu không có searchText
    }

  // Kiểm tra xem node có chứa searchText không
  const nodeContainsSearchText = node.label?.toLowerCase().includes(this.searchText.toLowerCase());
  
   // Nếu có ít nhất một nút con chứa searchText hoặc node chứa searchText, giữ lại node
    const hasMatchingChildren =  node.nodes?.length && node.nodes.some(childNode => this.keepNode(childNode));

    if (nodeContainsSearchText || hasMatchingChildren) {
      return true;
    }
  
    // Nếu node không có children, giữ lại node nếu node chứa searchText
    return false;
  }

}
