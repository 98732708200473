import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalTemplate } from '../../../../app/models/common-model';
import { BUTTON, COMMON_TEXT, DIALOG_CONFIRM_SAVE_DATA } from '../../../../app/const/text-common';
import { ButtonType } from '../../../../app/enum/common-enum';


@Component({
  selector: 'pivot-dialog-confirm-save-data',
  templateUrl: './dialog-confirm-save-data.component.html',
  styleUrls: ['./dialog-confirm-save-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogConfirmSaveDataComponent implements OnInit {
  @Input() data: any = null;
  @Output() onSubmitData: any = new EventEmitter();
  modalTemplate: ModalTemplate = new ModalTemplate();
  buttonType = ButtonType;
  BUTTON = BUTTON;
  DIALOG_CONFIRM = DIALOG_CONFIRM_SAVE_DATA;
  COMMON_TEXT = COMMON_TEXT;
  isSaveData: boolean = false;
  step: number = 1;

  constructor() { }

  ngOnInit(): void {
    this.modalTemplate.header = COMMON_TEXT.CONFIRM;
  }

  closeFormModal() {
    this.onSubmitData.emit(null);
  }

  changeOption(type: number) {
    this.isSaveData = type ==1 ? false: true;
  }

  cancel() {
    this.onSubmitData.emit(null);
  }

  save() {
    this.onSubmitData.emit(this.isSaveData);
  }

  next() {
      this.step++;
  }

  back() {
    this.step--;
  }
}
