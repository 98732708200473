<pivot-modal-template class="ds-modal form-modal" [modalTemplate]="modalTemplate"
    (closeModalHandler)="cancel()">
    <div class="db-modal-body" modal-body>
        <div class="searchbar-container">
            <pivot-search-bar [searchParams]="searchParams" (onFilterData)="onFilterOutData($event)"></pivot-search-bar>
        </div>
        <div class="db-tree-row">
            <div class="db-tree-container">
                <pivot-tree-view [params]="dashboardTreeData2Level" (nodeMouseClick)="handleNodeClick($event)">
                </pivot-tree-view>
                <div *ngIf="showNoDataText()" class="tree-view-placeholder">{{COMMON_TEXT.NO_DATA}}</div>
            </div>
        </div>
    </div>
    <div class="db-modal-bottom" modal-bottom>
        <div class="right-pannel">
            <pivot-text-button (btnClick)="cancel()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.CANCEL">
            </pivot-text-button>
            <pivot-text-button (btnClick)="save()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.SELECT">
            </pivot-text-button>
        </div>
    </div>
</pivot-modal-template>