import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseData } from '../../../app/models/api-model';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';
import { CodeMstModel } from '../../models/response/corpMst.ro';



@Injectable({ providedIn: 'root' })
export class MstCommonService extends ApiService {
  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async getMstFilter(bodyRequest: any) {
    let apiUrl = API_APP.MSTCOMMON.CONTROLLER + API_APP.MSTCOMMON.MSTFILTER;
    let resData = await this.post(apiUrl, [], bodyRequest);
    if(resData.statuscode === 200 && resData.data) {
      let result = resData.data.map((item: any)=> {
        let group: any = {}
        group.value = item.groupcd,
        group.label = item.content,
        item.periods = item.periods.map((p: any)=> {
          return {value: `${p.groupcd}-${p.itemcd}`, label: p.content, startdate: p.startdate, enddate: p.enddate, startdateseikuy: p.startdateseikuy, enddateseikuy: p.enddateseikuy, content: p.content, contentseikuy: this.getLableSeikeuy(p.groupcd, p.content)}
        })
        group.items = item.periods;
        return group
      })
      return result;
    }
    return [];
  }
  getLableSeikeuy(groupcd: string, content: string): string {
    switch(groupcd){
      case "004":// 年 
      case "005":// 年度 
      case "006":// 四半月 
        return content + " (請求月)";
      default:
        return content;
    }
  }
  getMstByType(typecd: string): Promise<ResponseData<any>> {
    const parameter = [
      typecd
    ];
    const apiUrl = API_APP.MSTCOMMON.CONTROLLER + API_APP.MSTCOMMON.BYTYPE;
    return this.get(apiUrl, parameter);
  }

  /**
   * @returns List data of CODEMST table
   */
  async getCodeMst(): Promise<CodeMstModel[]> {
    let result: CodeMstModel[] = []
    const apiUrl = API_APP.SYNCDATAORALE.CONTROLLER + API_APP.SYNCDATAORALE.GET_CODEMST;
    const resData = await this.get(apiUrl, undefined, undefined);
    if(resData.statuscode === 200 && resData?.data?.result && resData?.data?.result.length > 0) {
      resData.data.result.forEach((element: any) => {
        let item = new CodeMstModel();
        item.id = element.id;
        item.classcd = element.classcd;
        item.itemcd = element.itemcd;
        item.itemnm = element.itemnm;
        item.itemnmr = element.itemnmr;
        item.sortno = element.sortno;
        result.push(item);
      });
    }
    return result;
  }
}
