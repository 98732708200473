
  <div class="title-element">
    <div class="title-kbn-tag">
      <pivot-search-bar class="search-field" [validateInput]="true" [placeHolderFilter] = "COMMON_TEXT.SEARCH" (onFilterData) = "onChangeKBNTag($event)" [searchParams]="searchConfigKbnTag">
      </pivot-search-bar>
    </div>
    <div class="title-tag">
      <pivot-search-bar class="search-field" [validateInput]="true" [placeHolderFilter] = "COMMON_TEXT.SEARCH"   (onFilterData) ="onChangeTTLTag($event)"[searchParams]="searchConfigTTLTag" >
      </pivot-search-bar>
    </div>
   <div class="title-remove">
    <div class="title_removeitem">
      <i class="pi pi-times" (click)="onClickBtnDelete()"></i>
    </div>
    </div>
  </div>

