import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';



@Injectable({ providedIn: 'root' })
export class StepFunctionService extends ApiService {
    constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
        super(http, router, errorHandle);
      }

    checkStatus(arn : string) {
        let apiUrl = API_APP.STEPFUNCTION.CONTROLLER + API_APP.STEPFUNCTION.CHECK_STATUS + "/" + arn;
        return  this.get(apiUrl);
    }

  
}
