import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import { ErrorHandleService } from '../error-handle.service';



@Injectable({ providedIn: 'root' })
export class TemplateViewLogService extends ApiService {
  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async insertOrUpdate(recentWidgetCds: string) {
    let apiUrl = API_APP.TEMPLATE_VIEW_LOG.CONTROLLER + API_APP.TEMPLATE_VIEW_LOG.INSERT_OR_UPDATE;
    await this.post(apiUrl, undefined, {
      recentWidgetCds
    });
  }
  async getByUser() {
    let apiUrl = API_APP.TEMPLATE_VIEW_LOG.CONTROLLER + API_APP.TEMPLATE_VIEW_LOG.GET_BY_USER;
    let resData = await this.get(apiUrl);
    return resData;
  }
}
