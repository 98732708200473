import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, orderBy } from 'lodash';
import { OFFICE } from 'src/app/const/table-col-define';
import { BUTTON, COMMON_TEXT } from 'src/app/const/text-common';
import { ButtonType, DataType, SearchType } from 'src/app/enum/common-enum';

import { ModalTemplate, SearchParams } from 'src/app/models/common-model';
import { Office } from 'src/app/models/response/office.ro';
import { TableData } from 'src/app/models/table-model';
import { OfficeAPIService } from 'src/app/services/modules/office.service';


@Component({
  selector: 'pivot-office-modal',
  templateUrl: './office-modal.component.html',
  styleUrls: ['./office-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OfficeModalComponent implements OnInit {

  @Output() closeModalHandler = new EventEmitter();
  @Output() selectedOfficeHandler = new EventEmitter();
  

  @Output() onChangeFolderHandler = new EventEmitter<any>();   
  @Input() allOffices: Array<Office> = []; 
  @Input() selectedOffices: Office[] | null = null; 
  
  modalTemplate: ModalTemplate = new ModalTemplate();

  loadingTable: boolean = true;
  selectedOffice:any;
  COMMON_TEXT = COMMON_TEXT;
  OFFICE = OFFICE;
  BUTTON = BUTTON;
  buttonType = ButtonType;

  listOffices: Array<Office> = [];
  filterOffice: Array<Office> = [];

  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH,
    cssStyle: { width: '100%', height: '40px' },
    readonly: false,
    disabled: false
  }
  tableData: TableData = {
    config: {
      id: 'folder-service',
      caption: '',
      emptyMessage: COMMON_TEXT.NO_DATA,
      showIndex: true,
      showCheckbox: false,
      hoverShowCheckbox: false,
      showPagination: false,
      isResponsive: false,
      tableStyle: {},
      isSelectedRow: true
    },
    header: [
      {
        field: 'officeCd',
        title: OFFICE.OFFICECD,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '20%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: false
      },
      {
        field: 'officeNm',
        title: OFFICE.OFFICENM,
        sortable: false,
        filterable: false,
        attribute: {
          header: { width: '80%' },
          row: {}
        },
        dataType: DataType.TEXT,
        visible: true
      },
    ],
    body: []
  };
  constructor( private officeAPI: OfficeAPIService) { }

  async ngOnInit() {
    this.modalTemplate.style = { 'width': '500px', 'height': '650px' };
    this.loadingTable = true;
    this.filterOffice = orderBy(this.allOffices, ["officeCd"])
    this.tableData.body = this.filterOffice;
    this.tableData = cloneDeep(this.tableData);
    this.loadingTable = false;

    if(this.selectedOffices && this.selectedOffices.length)
    {
      this.selectedOffice = this.allOffices.find(f => this.selectedOffices?.map(x=> x.officeCd).includes(f.officeCd));
    }
  }
  
  ngOnChanges(){
 
  }
  closeFormModal(){
    this.closeModalHandler.emit();
  }
  onFilterOutData(officeName: any) { 
   
  }
  clickRowTable(data: any){
      this.selectedOffice = this.allOffices.find(f => f.officeCd === data.officeCd) || data;
  }

  cancel(){
    this.closeModalHandler.emit();
  }
  save(){
    if(!this.selectedOffice) return;

    this.selectedOfficeHandler.emit(this.selectedOffice);
    this.closeModalHandler.emit();
  }

}
