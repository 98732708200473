import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ProcessLoadingService } from '../../../app/services/loading.service';
import { COMMON_TEXT, DATA_UPDATE, BUTTON } from '../../../app/const/text-common';
import Utils from '../../../app/util/utils';
import { ButtonType, DeviceType, GraphType, InputType, SyncStatusWidgetResult, WidgetSelectDataType } from '../../../app/enum/common-enum';
import { DetectDeviceService, IDeviceType } from '../../../app/services/detect-device.service';
import * as gridster from 'angular-gridster2';
import { WidgetService } from '../../../app/services/modules/widget.service';
import { Router } from '@angular/router';
import { LocalStorageHelper } from '../../../app/_helper/local-storage.helper';
import { ROUTE_PATH } from '../../../app/const/route-path';
import { cloneDeep, groupBy, orderBy } from 'lodash';
import { FilterConditionRequest, WidgetResultRequest } from '../../../app/models/request/widget.dto';
import { WidgetResultService } from '../../../app/services/modules/widget-result.service';
import { DSCUSTOM, DSTARGET, FooterName, InvisibleColumn, SummaryColumnName, TVPParams } from '../../../app/const/const';
import { isOldTemplate, isPubishWidget } from '../../../app/_helper/helper';
import { DashboardService } from '../../../app/services/modules/dashboard.service';
import { TreeNode, TreeViewInputParams } from '../../../app/models/common-model';
import { SearchLogService } from 'src/app/services/modules/search-log.service';
import { TemplateViewLogService } from 'src/app/services/modules/template-view-log.service';

@Component({
  selector: 'pivot-list-widget-template',
  templateUrl: './list-widget-template.component.html',
  styleUrls: ['./list-widget-template.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListWidgetTemplateComponent implements OnInit, AfterViewInit {

  COMMON_TEXT = COMMON_TEXT;
  DATA_UPDATE = DATA_UPDATE;
  BUTTON = BUTTON;
  isDarkMode: boolean = true;
  widgets: any[] = [];
  syncStatusWidget = SyncStatusWidgetResult ;
  options!: gridster.GridsterConfig;
  DeviceType = DeviceType;
  deviceType!: string;
  allWidgets: any[] = [];
  currentWidgets: any[] = [];
  searchText: string = '';
  periods: any[] = [];
  widgetHistorys: any[] = [];
  filterCondition: FilterConditionRequest = new FilterConditionRequest();
  // viewchild
  @ViewChild('op', { static: false }) listOp: any;
  @ViewChild('grister', { read: ElementRef, static: false }) grister: ElementRef|undefined;
  // Pagination by scroll
  readonly pageSize: number = 10; // The number of rows to be displayed per page (1000 rows per page).
  readonly rowHeight: number = 295; // The height of each row in pixels (30px per row).
  readonly buffer: number = 20;  // The number of additional rows loaded to create a smooth scrolling experience, used to pre-load data during scrolling (100 extra rows).
  currentPage: number = 1; // The index of the page currently being viewed by the user (starting from page 1).
  previousPage: number = 1; // The index of the page that the user viewed before the current page (used to track the previously viewed page).
  items: any[] = [];
  inputDesParams: any = {
    type: InputType.text,
    validate: false,
    borderFill: true,
    maxLength: 200,
    disabled: false
  };
  keyLocalStorage: string = 'widgetHistory';
  GraphType: any = GraphType;
  searchList: any[] = [];
  frequentlyUseds: string[] = [];
  dashboardNews: any[] = [];
  dashboards: any[] = [];
  buttonType = ButtonType;
  dashboardTree: TreeViewInputParams = {
    ...cloneDeep(TVPParams),
    checkbox: false,
    allowParentSelect: true,
    selectable: true,
    hoverable: true
  };

  showSkeletonFrequentlyUseds = true;
  showSkeletonDashboardNews = true;
  showSkeletonSearchList = true;
  showSkeletonWidget = true;

  isDisplayDashboardDlg: boolean = false;
  dashboardTreeData: any[] = [];

  constructor(private loadingService: ProcessLoadingService,
              private detectDeviceService: DetectDeviceService,
              private widgetService: WidgetService,
			  private routerNavigate: Router,
			  private widgetResultService: WidgetResultService,
			  private dashboardService: DashboardService,
			  private searchLogService: SearchLogService,
			  private templateViewLogService: TemplateViewLogService,
			  private cdr: ChangeDetectorRef,
  ) {
    this.detectDeviceService.currentDevice.subscribe((device: IDeviceType) => {
			if (device) this.deviceType = device.type;
		});
    const bodyElement = document.getElementsByTagName("body")[0];
    this.isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
	if (this.isDarkMode) {
		this.dashboardTree.expandIcon = '../../../assets/icons/folder-white.svg';
		this.dashboardTree.collapseIcon = '../../../assets/icons/folder-opened-white.svg';
	  } else {
		this.dashboardTree.expandIcon = '../../../assets/icons/folder.svg';
		this.dashboardTree.collapseIcon = '../../../assets/icons/folder-opened.svg';
	  }
   }

	async ngOnInit() {
		this.loadingService.isLoading.emit(true);

		this.getFrequentlySearches();

		this.setGristerOptions()
		if(this.currentWidgets?.length ==0) {
			await this.widgetService.getListWidget().then(res => {
				let allData = res.data || [];
				allData = this.mapWidgetIsNew(allData);
				// sort widget theo trạng thái isNew
				allData = cloneDeep(this.sortData(allData));
				this.allWidgets = allData;
				this.currentWidgets = allData;
			})
		}
		else this.allWidgets = cloneDeep(this.currentWidgets)
		this.templateViewLogGetByUser();
		await this.getListDashboardNew();
		this.searchList = this.getArrayHistoryFromLocalStorage('listItags')
		if(this.searchList.length == 0) this.searchList.push('事故');
		await this.onSearchWidgets();
		this.allWidgets = this.allWidgets.filter(s =>s.widgetname)?.map(s => {
			s.isPublic = isPubishWidget(s.publicdate ? s.publicdate.toString() : '');
			s.type = s.chartType;
			s.src = this.getChartIcon(s, false);
			s.name = s.widgetname;
			return s
		})?.filter(s=>s.isPublic);
		this.showSkeletonWidget = false;
		this.loadingService.isLoading.emit(false);
	}

	getFrequentlySearches() {
		this.searchLogService.getFrequentlySearches()
							.then(res => { 
								this.frequentlyUseds = res;
								this.showSkeletonFrequentlyUseds = false;
							});

	}

	// Phương thức sắp xếp lại mảng theo (update || insdate) giảm dần
	sortData(data: any[] = []) {
		if(data?.length == 0) return [];
		return [...data]?.sort((a, b) => {
			const dateA = new Date(a.upddate ? a.upddate : a.insdate);
			const dateB = new Date(b.upddate ? b.upddate : b.insdate);
			return dateB?.getTime() - dateA?.getTime();
		});
	}

	mapWidgetIsNew(widgets: any[] = []) {
		return widgets.map(widget => {
			widget.isNew = (!widget.publicdate) ? false : (isOldTemplate(widget.upddate ? widget.upddate : widget.insdate) ? false : true );
			return widget;
		})
	}

	async onSelectedOption(widget: any) {
		this.searchList = [];
		if(widget?.value?.name) {
			this.searchText = widget.value.name;
		}
		this.listOp.hide();
		await this.onSearchWidgets();
		this.allWidgets = cloneDeep(this.currentWidgets);
	}

	scrollToTop() {
		this.currentPage = 1;
		this.previousPage = 1;
		if (this.grister) {
		  let elementScroll = this.grister?.nativeElement;
		  if (elementScroll) {
			elementScroll.scrollTop = 0;
		  }
		}
	}	

	fetchViewportData() {
		// Extract the relevant slice of bodyRender for the current page
		let slicedRows;
		if (this.currentPage == 1) {
		  slicedRows = cloneDeep(this.widgets.slice(0, this.pageSize + this.buffer));
		} else {
		  let offsetRows = (this.currentPage - 1) * this.pageSize - this.buffer;
		  slicedRows = cloneDeep(this.widgets.slice(offsetRows, this.currentPage * this.pageSize));
		}
		return slicedRows;
	}

	handleScrollGrister() {
		const scrollTop = this.grister?.nativeElement.scrollTop;
		const firstVisibleRowIndex = Math.floor(scrollTop / this.rowHeight);
		const rowsInView = Math.ceil(this.grister?.nativeElement.offsetHeight / this.rowHeight);
		const lastVisibleRowIndex = firstVisibleRowIndex + rowsInView;
		this.currentPage = Math.ceil(lastVisibleRowIndex / this.pageSize);
		let allPage = Math.ceil(this.widgets.length / this.pageSize);
		if (this.previousPage != this.currentPage) {
		  if (this.currentPage <= allPage) {
			this.items = this.fetchViewportData();
			this.previousPage = this.currentPage;
		  } else {
			this.currentPage = this.previousPage;
		  }
		}
		this.cdr.detectChanges();
	}

	onInputSearch(searchValue: any) {
		this.allWidgets = cloneDeep(this.currentWidgets);
		this.searchText = searchValue?.target?.value || '';
		if(this.searchText != '') {	
			// tìm kiếm theo widget name
			this.allWidgets = this.allWidgets.filter(s=>s.name?.toString()?.toLowerCase()?.trim()?.includes(this.searchText?.toString()?.toLowerCase()?.trim()) 
					// tìm kiếm theo widget description
					|| s.widgetdesc?.toString()?.toLowerCase()?.trim()?.includes(this.searchText?.toString()?.toLowerCase()?.trim())
					// search theo Itag của datasource nếu column name include col thì sẽ search theo display name vì không lưu itag về
					|| (s.columnname?.toString()?.toLowerCase()?.trim().includes('col') && s.itag?.toString()?.toLowerCase()?.trim()?.includes(this.searchText?.toString()?.toLowerCase()?.trim()))
					// search theo title kbn name
					|| s.ttlkbnnm?.toString()?.toLowerCase()?.trim()?.includes(this.searchText?.toString()?.toLowerCase()?.trim())
					// search theo title name
					|| s.ttlnm?.toString()?.toLowerCase()?.trim()?.includes(this.searchText?.toString()?.toLowerCase()?.trim()));
		}
		else 
		{
			this.allWidgets = cloneDeep(this.currentWidgets);
		}
	}

	async ngAfterViewInit() {
		this.grister?.nativeElement?.addEventListener('scroll', this.handleScrollGrister.bind(this));
		await this.getListDashboardFolder();
	}

	clearSearchData() {
		this.searchList = []
		this.searchText = '';
	}

	async searchByWidgetCd(widgetCd: string) {
		if(widgetCd) {
			this.showSkeletonSearchList = false;
			await this.widgetService.getListWidgetBySearchText([widgetCd]).then(res => {
				if(res.statuscode == 200)  {
					this.widgets = this.mapWidgetIsNew(res.data || []);
					this.widgets = this.sortData(this.widgets);
				}
			})
			await this.mapWidgetInfor();
		}
	}

	async getWidgetData(widgetcd: string) {
		this.clearSearchData();
		this.loadingService.isLoading.emit(true);
		if(widgetcd) {
		   await this.searchByWidgetCd(widgetcd)
		}
		this.loadingService.isLoading.emit(false);
	}

	async onChangeTagItem(item: any) {
		this.clearSearchData();
		this.searchList.push(item);	
		await this.onSearchWidgets(false, true);
		this.allWidgets = cloneDeep(this.currentWidgets);
	}

	async onChangeDashboardItem(dashboardcd: any) {
		if(!dashboardcd) return;
		this.loadingService.isLoading.emit(true);
		let dasboardSetting: any[] = this.dashboards.filter(db => db.dashboardcd == dashboardcd) || []; 
		let widgetCds = dasboardSetting?.map((item: any) => item.widgetcd) || [];
		this.clearSearchData();
		this.showSkeletonSearchList = false;
		await this.widgetService.getListWidgetBySearchText(widgetCds).then(res => {
			if(res.statuscode == 200)   {
				this.widgets = this.mapWidgetIsNew(res.data || []);
				this.widgets = this.sortData(this.widgets);
			}
		})
		await this.mapWidgetInfor();
		this.allWidgets = cloneDeep(this.currentWidgets);
		this.items = cloneDeep(this.widgets);
		this.loadingService.isLoading.emit(false);
	}

	widgetResize(event: any) {
		event?.itemComponent.height 
		let position = JSON.stringify({ "x": event?.itemComponent?.$item?.x, "y": event?.itemComponent?.$item?.y, 
				"rows": event?.itemComponent?.$item?.rows, "cols": event?.itemComponent?.$item?.cols, "width": event?.item?.width, "height": event?.item?.height })
		if (this.widgets?.length > 0) {
			this.widgets?.map((w: any) => {
				if (w.widgetcd == event.item?.widgetcd) {
					w.widgetPosition = position;
					w.type = event?.item?.type;
					w.width = (event?.itemComponent?.width > 20) ? event?.itemComponent?.width - 20 : 500;
					w.height = (event?.itemComponent?.height > 80) ? event?.itemComponent?.height  - 80: 500;
					w.rows = event?.itemComponent?.$item?.rows;
					w.cols = event?.itemComponent?.$item?.cols;
					w.x = event?.itemComponent?.$item?.x;
					w.y = event?.itemComponent?.$item?.y;
				}
			});
		}
	}

	setGristerOptions() {
		this.options = {
			compactType: gridster.CompactType.CompactUpAndLeft,
			gridType: gridster.GridType.ScrollVertical,
			defaultItemRows: 11,
			defaultItemCols: 14,
			displayGrid: gridster.DisplayGrid.None,
			disableWindowResize: false,
			enableEmptyCellClick: false,
			enableEmptyCellContextMenu: false,
			enableEmptyCellDrop: true,
			enableEmptyCellDrag: false,
			enableOccupiedCellDrop: true,
			emptyCellDragMaxCols: 50,
			emptyCellDragMaxRows: 50,
			margin: 10,
			minCols: 15,
			minRows: 12,
			maxRows: 1000,
			maxCols: 40,
			minItemRows: 9,
			minItemCols: 12,
			maxItemRows: 50,
			draggable: {
				enabled: false,
				ignoreContent: false,
				dragHandleClass: 'gridster-item-content',
			},
			resizable: {
				enabled: false,
			},
			pushItems: true,
			mobileBreakpoint: 640
		};

		if (this.deviceType === DeviceType.MOBILE || this.deviceType === DeviceType.TABLET) {
			this.options.mobileBreakpoint = 9999;
			this.options.keepFixedHeightInMobile = true;
			this.options.fixedRowHeight = 56;
			this.options.outerMarginBottom = 90;
		}
	}

	async openWidgetDetail(widget: any) {

		let existWidget = this.widgetHistorys?.find(s => s.widgetcd == widget.widgetcd);
		if(!existWidget) {
			this.widgetHistorys.unshift(widget);
			await this.insertOrUpdateTemplateViewLog();
		}
		this.widgetService.setWidgetDetail(widget);
		this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.CREATE_WIDGET_TEMPLATE)]);
	}

	clickInputSearch(event: any) {
		this.listOp.toggle(event)
	}

	async getListDashboardNew() {
		await this.dashboardService.getListDashboardTemplateNew().then(res => {
			if(res.statuscode == 200) {
				this.dashboards = res.data || [];
				let alldashboard: any[] = res.data || [];
				let mapDashboard: any[] = [];
				if(alldashboard.length > 0) {
					alldashboard.forEach(db => {
						let findIndex = mapDashboard.findIndex(s => s.dashboardcd == db.dashboardcd);
						if(findIndex == -1 && db.upddate) mapDashboard.push({ dashboardcd: db.dashboardcd, upddate: db.upddate, dashboardname: db.dashboardname });
					})
				}
				mapDashboard = orderBy(mapDashboard, item => item.upddate, 'desc');
				mapDashboard = mapDashboard?.map(db => {
					db.isNew = (db.upddate? isOldTemplate(db.upddate) : isOldTemplate(db.insdate)) ? false : true;
					return db;
				});
				// lấy mặc định 4 dashboard
				this.dashboardNews = mapDashboard?.splice(0, 4) || [];
			}
			this.showSkeletonDashboardNews = false;
		})
	}

	async getWidgetResultData() {
		this.filterCondition.isFullData = false;
		let widgetCds = this.widgets.map( x => x.widgetcd) as string[];
		let requestBody = {
			widgetCds: widgetCds,
			selectType: WidgetSelectDataType.SPECIALCASE,
			isTemplate: true,
			filterCondition: this.filterCondition
		} as WidgetResultRequest

		let widgetResultData:  any[] = [];
		await this.widgetResultService.getByWidgetCds(requestBody)
		.then(res => {
			if (res.statuscode == 200) {
				widgetResultData = res.data || [];
			}
		});
		return widgetResultData;
	}

	async mapWidgetInfor() {
		let widgetResultData = await this.getWidgetResultData();
		//map widget
		this.widgets = this.widgets?.map((widget: any) => {
			let widgetResult = widgetResultData.filter((x:any) => x.widgetcd == widget.widgetcd).pop();
			const itemFind = this.widgets?.find(w => w.widgetcd === widget.widgetcd);
			widget.sortcoltype = itemFind?.sortcoltype;
			widget.type = itemFind?.charttype;
			widget.syncStatus = itemFind.syncStatus;
			widget.name = itemFind?.widgetname;
			widget.widgetId = itemFind?.id || "";
			widget.id = itemFind?.id || ""
			widget.widgetId = widget.id;
			widget.widgetcd = widget.widgetcd;
			widget.widgetName = widget.widgetname;
			widget.datasourceCd = widget.widgetdetails?.find((s: any)=>s.datasourcecd != DSTARGET 
				&& s.datasourcecd != DSCUSTOM && s.datasourcecd != FooterName &&  s.datasourcecd != SummaryColumnName )?.datasourcecd;
			widget.syncStatus = 1;
			widget.widgetConfig = widget.widgetconfigs;
			widget.isLoadWidgetResultComplete = true;
			widget.widgetResult = widgetResult?.result;
			return widget;
		})
		this.widgets = this.widgets.filter(s => s.widgetResult);
		this.widgets = this.sortData(this.widgets);
		//this.widgetService.setListWidgets(this.widgets);
	}

	async onKeyupInputData(event: any) {
		if(!this.searchText && !this.searchList.length) return;
		if(event.code == 'Enter') {
			this.listOp.hide();
			await this.onSearchWidgets()
			this.allWidgets = cloneDeep(this.currentWidgets);
		}
	}

	// tìm kiếm danh sách widget
	async findListWidgetBySearchText() {
		await this.onSearchWidgets()
	}

	async onSearchWidgets(isHistory: boolean = false, isFrequentlyUsed: boolean = false) {
		if(!this.searchText && this.searchList.length == 0) return;

		let searchArr: any[] = [];
		let dataFilter: any[] = [];
		if(!isHistory) {
			let findItem = this.searchList.find(text => text == this.searchText);
			if(!findItem && this.searchText != '' && !isFrequentlyUsed) this.searchList.push(this.searchText);
		}
		else this.searchList = [];
		
		if(this.searchList.length == 0 ) searchArr = cloneDeep(this.currentWidgets).map(w=>w.widgetcd);
		else {
			this.searchList?.forEach(searchItem => {
				// tìm kiếm theo widget name
				let widgetFilters = cloneDeep(this.currentWidgets).filter(s=>s.widgetname?.toString()?.toLowerCase()?.trim()?.includes(searchItem?.toString()?.toLowerCase()?.trim()) 
				// tìm kiếm theo widget description
				|| s.widgetdesc?.toString()?.toLowerCase()?.trim()?.includes(searchItem?.toString()?.toLowerCase()?.trim())
				// search theo Itag của datasource nếu column name include col thì sẽ search theo display name vì không lưu itag về
				|| (s.columnname?.toString()?.toLowerCase()?.trim().includes('col') && s.itag?.toString()?.toLowerCase()?.trim()?.includes(searchItem?.toString()?.toLowerCase()?.trim()))
				// search theo title kbn name
				|| s.ttlkbnnm?.toString()?.toLowerCase()?.trim()?.includes(searchItem?.toString()?.toLowerCase()?.trim())
				// search theo title name
				|| s.ttlnm?.toString()?.toLowerCase()?.trim()?.includes(searchItem?.toString()?.toLowerCase()?.trim()));
				dataFilter.push(widgetFilters?.map(s=>s.widgetcd));
			});
			if(dataFilter?.length > 0) {
				// filter các phần tử của mảng con đầu tiên và kiểm tra xem chúng có tồn tại trong tất cả các mảng con không
				searchArr = dataFilter[0]?.filter((item: any) => {
					return dataFilter?.every(subArray => subArray?.includes(item));
				}) || [];
			}
		}
		this.loadingService.isLoading.emit(true);

		this.saveArrayHistoryToLocalStorage('listItags', this.searchList);
		if(this.searchText)
			this.insertSearchLog(false);

		this.searchText = '';

		await this.widgetService.getListWidgetBySearchText(searchArr).then(res => {
			if(res.statuscode == 200)  {
				this.widgets = this.mapWidgetIsNew(res.data || []); 
				this.widgets = this.sortData(this.widgets);
			}
		})
		await this.mapWidgetInfor();
		this.widgets = cloneDeep(this.widgets)
		this.widgets = this.sortData(this.widgets);
		this.setGristerOptions();
		this.loadingService.isLoading.emit(false);
	}

	insertSearchLog(inuse: boolean) {
		// const processedSearchList = (this.searchList || [])
		// .map(item => item ? item.replace(/#/g, "") : "")
		// .filter(item => item !== "");
		const processedSearchList = [this.searchText];

		if(processedSearchList.length) {
			 this.searchLogService.insert(processedSearchList, inuse);
		}
	}

	async removeSearchItem(item: any) {
		if(this.searchList.length > 0) {
			this.searchList = this.searchList.filter(s => s!= item);
			if(this.searchText == item) this.searchText = '';
			if(this.searchList?.length > 0) await this.onSearchWidgets();	
		}

		this.showSkeletonSearchList = false;
	}

	getWidgetTitle(widgetCd: string = '') {
		let title = '';
		let findItem: any = this.widgets?.find((w: any) => w.widgetcd === widgetCd);
		if (!Utils.isNullOrEmpty(findItem)) {
			title = findItem.widgetName;
		}
		return title;
	}

	getWidgetDescription(widgetCd: string = '') {
			let findItem: any = this.widgets.find((w: any) => w.widgetCd === widgetCd);
			return findItem && findItem.description ? findItem.description : "";
	}

	getChartIcon(widget: any, isHistory: boolean = false) {
		let chartType = widget?.charttype;
		switch(chartType){
			case GraphType.NUMBER_CHART:
				return  'number-chart-icon';
			case GraphType.LINE_CHART:
				return 'line-chart-icon';
			case GraphType.TABLE_CHART:
				return 'table-chart-icon';
			case GraphType.DOUGHNUT_CHART:
				return 'doughnut-chart-icon';
			case GraphType.LINE_BAR_COMBINE_CHART:
				return 'combo-chart-icon';
			case GraphType.PIE_CHART:
				return 'pie-chart-icon';
			case GraphType.BAR_CHART_VERTICAL:
				return 'bar-chart-vertical-icon'
			case GraphType.BAR_CHART_HORIZONTAL: 
				return 'bar-chart-horizontal-icon'
			case GraphType.STACKED_BAR:
				return 'stacked-bar-chart-icon'
		}
		return 'table-chart-icon';
	}

	// save array widget history to localStorage
	saveArrayHistoryToLocalStorage(key: string, array: any[]) {
		localStorage.setItem(key, JSON.stringify(array));
	}

	// get array widget history from localStorage
	getArrayHistoryFromLocalStorage(key: string): any[] {
		const arrayString = localStorage.getItem(key);
		return arrayString ? JSON.parse(arrayString) : [];
	}

	// show dashboard select dialog
	onShowDialogDashboardSelect() {
		this.isDisplayDashboardDlg = true;
	}

	async onSubmitDashboardSelect(dashboardCd: any) {
		this.isDisplayDashboardDlg = false;
		await this.onChangeDashboardItem(dashboardCd);
	}

	loadFolderTree(selectedDS: string[] = []) {
		const hierarchyCD: bycd[] = [
		  { cd: 'foldercd', labelcd: 'foldername', showIcon: true, },
		  { cd: 'dashboardcd', labelcd: 'dashboardname', },
		];
		let nodes = this.arrayToTreeNode(this.dashboardTreeData, hierarchyCD, selectedDS)
		if(nodes) nodes = orderBy(nodes, ["label"]);
		nodes = nodes?.filter(s => s.data && (s.data.filter(dt => dt.dashboardcd)?.length > 0));
		this.dashboardTree.nodes = nodes?.sort((a:any, b: any) => {
			const labelA = a.label.toLowerCase();
			const labelB = b.label.toLowerCase();
			return labelA.localeCompare(labelB);
		  })
		this.dashboardTree = { ...this.dashboardTree }
	}

	async getListDashboardFolder() {
		await this.dashboardService.getListDashboardTemplate(false).then(res => {
		  if(res.statuscode == 200){
			this.dashboardTreeData = res.data;
		  }
		});
		this.loadFolderTree()
	}

	arrayToTreeNode = (data: any[], groupCD: bycd[], selectedCD: string[] = [], parentNode?: TreeNode): TreeNode[] => {
		let nodes: TreeNode[] = []
		let groupX: bycd | undefined = groupCD.shift()

		if (groupX) {
		  const { cd, labelcd }: bycd = groupX
		  const x = groupBy(data, cd)
		  const isLastNode = !groupCD.length

		  for (const key in x) {
			const selected = selectedCD.includes(key)
			let y: TreeNode = {
			  id: key,
			  label: x[key][0][labelcd as string],

			  isShowIcon: groupX.showIcon || false,
			  isShowCheckBox: groupX.checkbox || false,
			  data: x[key],
			  isLastNode: isLastNode,
			  draggable: isLastNode,
			  singleSelected: selected,
			  hidden: isLastNode ? (InvisibleColumn[x[key][0].columnname] || false) : false,
			}

			y.nodes = this.arrayToTreeNode(x[key], [...groupCD], selectedCD, y)

			selected && parentNode && (parentNode.expanded = true)

			nodes.push(y);
		  }
		  return nodes
		}

		return []
	  }

	//#region template view log

	async insertOrUpdateTemplateViewLog() {
		let widgetCds = this.widgetHistorys?.slice(0,6)?.map(item => item.widgetcd).join(",");
		if (widgetCds) {
			await this.templateViewLogService.insertOrUpdate(widgetCds);
		}
	}

	 templateViewLogGetByUser() {
		this.templateViewLogService.getByUser().then(res => { 
			if (res && res.statuscode == 200) {
				let widgetCds: string[] = (res.data?.recentwidgetcds ?? "").split(",");
				this.widgetHistorys = this.allWidgets.filter(widget => widgetCds.includes(widget.widgetcd))
				.sort((a, b) => widgetCds.indexOf(a.widgetcd) - widgetCds.indexOf(b.widgetcd));
			} else {
				this.widgetHistorys = [];
			}
		});
	
	}

	//#endregion template view log

}
type bycd = { cd: string, labelcd: string, showIcon?: boolean, checkbox?: boolean }