import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DateFormat, ScreenMode } from '../../../app/enum/common-enum';
import { ResponseData } from '../../../app/models/api-model';
import { Dashboard, DashboardList, DashboardModel, DashboardSetting } from '../../../app/models/response/dashboard.ro';
import { PivotTableConfig, Widget } from '../../../app/models/response/widget.ro';
import { API_APP, PIVOT_CLIENT_API_URL } from '../../config/app.config';
import { ApiService } from '../api.service';
import { AuthenticationService } from '../../services/authentication.service';
import { LocalStorageKey } from './../../_helper/local-storage.helper';
import {ErrorHandleService} from '../error-handle.service';
import { SaucerLogService, SAUCER_LOG_ACTION } from '../saucer-logs/saucer-log.service';
import { ROUTE_PATH } from 'src/app/const/route-path';
import { ACTION_LOG } from 'src/app/config/saucer-log.config';

@Injectable({ providedIn: 'root' })
export class DashboardService extends ApiService {

  private dashboardItem?: DashboardList;
  private dashboardMode: ScreenMode;
  private duplicateItem: any = null;
  private publicData: any[] = [];
  private itemsRemove: any[] = [];
  private currentData: any = null;
  private currentTemplateData: any = null;
  private sessionDashboardData: any = null;
  private sessionDashboardDetail: any = [];
  private listNewItem: any[] = [];
  private featureChange: any = {
    listInsert: [],
    listRemove: [],
    dashboardcd: ''
  };
  private actionLog: any;

  constructor(
    http: HttpClient,
    router: Router, 
    errorHandle: ErrorHandleService,   
    private authenticationService: AuthenticationService,
    private saucerLogService: SaucerLogService
  ) {
    super(http, router, errorHandle);
  }

  setListNewItem(items: any[]) {
    this.listNewItem = items;
  }

  setFeaturePublicChange(featureChange: any) {
    this.featureChange = featureChange;
  }

  getFeaturePublicChange() {
    return this.featureChange;
  }

  getListNewItem() {
    return this.listNewItem;
  }

  setCurrentData(data: any) {
    this.currentData = data;
  }

  getCurrentData() {
    return this.currentData;
  }

  setCurrentTemplateData(data: any) {
    this.currentTemplateData = data;
  }

  getCurrentTemplateData() {
    return this.currentTemplateData;
  }

  setDashboardItem(mode: ScreenMode, value?: DashboardList) {
    this.dashboardItem = value;
    this.dashboardMode = mode;
  }

  setListPublicData(datas: any[]) {
    this.publicData = datas;
  }

  setListDataRemove(items: any[]) {
    this.itemsRemove = items;
  }

  getListDataRemove() {
    return this.itemsRemove;
  }

  getListPublicData() {
    return this.publicData;
  }

  getDashboardItem() {
    let item = {
      dashboard: this.dashboardItem || null,
      mode: this.dashboardMode
    };
    return item;
  }

  setDuplicateItem(item: any) {
    this.duplicateItem = item;
  }

  getDuplicateItem() {
    return this.duplicateItem;
  }

  async getAll() {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.ALL;
    let resData = await this.get(apiUrl);
    if(resData.statuscode === 200) {
      return resData.data;
    }
    return [];
  }

  async getByFolder(code: string) {
    let parameter = [
      code
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.FOLDER;
    let resData = await this.get(apiUrl, parameter);
    this.sessionDashboardData = resData?.data || [];
    return await this.mapModel(resData);
  }

  async mapModel(data: any) {
    const staffs = this.authenticationService.getData(LocalStorageKey.ALL_STAFF) || [];
    let dataArr = [];
      for await (const element of data.data) {
        let dash = new DashboardList();
        dash.id = element.id;
        dash.name = element.dashboardname;
        dash.dashboardCd = element.dashboardcd;
        dash.description = element.dashboarddesc;
        dash.folderCd = element.foldercd;
        dash.folderName = element.foldername;
        dash.insdate = element.insdate;
        dash.insstfcd = element.insstfcd;
        dash.insstfnm = staffs?.find((stf: any) => stf.staffCd == element.insstfcd)?.fullName || element.insstfnm;
        dash.upddate = element.upddate;
        dash.updstfnm = staffs?.find((stf: any) => stf.staffCd == element.updstfcd)?.fullName || element.updstfnm;
        dash.publicCd = element.publiccd;
        dash.publictype = element.publictype;
        if (element.updstfcd) {
          dash.displayNm = dash.updstfnm;
          dash.displayDate = (moment(element.upddate)).format(DateFormat.FULL_SHORT_DATE);
        } else {
          dash.displayNm = dash.insstfnm;
          dash.displayDate = (moment(element.insdate)).format(DateFormat.FULL_SHORT_DATE)
        }
        dataArr.push(dash);
      }
    return dataArr;
  }

  mapDashboardSetting(data: any): DashboardSetting {
    const detail = new DashboardSetting();
    detail.widgetCd = data.widgetcd;
    detail.datasourceCd = data.datasourcecd;
    detail.datasourceName = data.dsname;
    detail.period =  data.period;
    detail.widgetName = data.widgetname;
    detail.widgetConfig = data.widgetconfig;
    detail.widgetdetails = data.widgetdetails;
    detail.syncStatus = data.syncstatus;
    detail.charttype = data.charttype;
    detail.detailid = data.detailid;
    if (data.widgetposition) {
      detail.widgetPosition = data.widgetposition;
      try {
        const parsedPosition = JSON.parse(data.widgetposition);
        detail.x = parsedPosition.x
        detail.y = parsedPosition.y;
        detail.rows = parsedPosition.rows;
        detail.cols = parsedPosition.cols;
        detail.width = parsedPosition.width;
        detail.height = parsedPosition.height;
      } catch (e) {
      }
    }

    return detail;
  }

  async insertOrUpdate(bodyData: any, isUpdate: boolean) {
    let parameter = [
      isUpdate
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.INSERTORUPDATE;
    let resData = await this.post(apiUrl, parameter, bodyData);
    let dashboardcd = resData?.data || '';
    let isTemplate = location.pathname.includes('dashboard-template') ? true : false;
    let isDashboardList = (location.pathname.includes(ROUTE_PATH.DASHBOARD_LIST) ||  location.pathname.includes(ROUTE_PATH.DATASOURCE_TEMPLATE)) ? true : false;
    let screenList = SAUCER_LOG_ACTION.DASHBOARD_LIST;
    let screenDetail = SAUCER_LOG_ACTION.DASHBOARD_CREATION.SAVE;
    if(isTemplate) {
      screenList = SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_LIST;
      screenDetail = !isUpdate ? SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_CREATION.SAVE : SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_EDITING.SAVE_DASHBOARD;
    }
    else {
      screenList = SAUCER_LOG_ACTION.DASHBOARD_LIST;
      screenDetail = !isUpdate ? SAUCER_LOG_ACTION.DASHBOARD_CREATION.SAVE : SAUCER_LOG_ACTION.DASHBOARD_EDITING.SAVE_DASHBOARD;
    }
    this.actionLog = isDashboardList ? !isUpdate ? screenList.CREATE_DASHBOARD : screenList.UPDATE_DASHBOARD : screenDetail;
    const oldUpdateDatas = this.sessionDashboardData?.find((db: any) => db.dashboardcd == dashboardcd);
    const newData = {
      foldercd: bodyData[0]?.foldercd,
      dashboardcd,
      dashboardname: bodyData[0]?.dashboardname,
      dashboarddesc: (bodyData[0]?.dashboarddesc || ''),
      publiccd: bodyData[0].publiccd
    }
    const logCreate = {
      old: null,
      new: newData
    };
    const logUpdate = {
      old: {
        foldercd: oldUpdateDatas?.foldercd,
        dashboardcd: oldUpdateDatas?.dashboardcd || "",
        dashboardname: oldUpdateDatas?.dashboardname || "",
        dashboarddesc: oldUpdateDatas?.dashboarddesc || "",
        publiccd: oldUpdateDatas?.publiccd || ""
      },
      new: newData
    };
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    let content = (!isUpdate ? JSON.stringify(logCreate) : JSON.stringify(logUpdate))
    if(resData.statuscode == 200) {
      this.saucerLogService.system(
        {
          apiPath,
          body: resData,
          statusCode: resData.statuscode,
          content: content
        }, 
        { 
          action: this.actionLog
        }
      );
    }
    else {
      this.saucerLogService.error(
        {
          apiPath,
          body: resData,
          statusCode: resData.statuscode,
          content: content
        }, 
        { 
          action: this.actionLog
        }
      );
    }
    return resData;
  }

  async delete(ids: any) {
    let isTemplate = window.location.pathname.includes(ROUTE_PATH.DASHBOARD_TEMPLATE) ? true : false;
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.DELETE;
    let action = isTemplate ? SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_LIST.DELETE_DASHBOARD : SAUCER_LOG_ACTION.DASHBOARD_LIST.DELETE_DASHBOARD
    let resData = await this.post(apiUrl, [], ids);
    let itemDelete: any[] = [];
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    if(this.sessionDashboardData?.length > 0) {
      itemDelete = this.sessionDashboardData.filter((db: any) => 
        ids.some((dbCd: any) => dbCd === db.dashboardcd)
      )?.map((s: any) => ({
          dashboardCd:  s.dashboardcd,
          dashboardName: s.dashboardname
        }));
    }
    if (resData?.statuscode === 200) {
      this.saucerLogService.system({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content: JSON.stringify({
          old: itemDelete,
          new: null
        })
      }, { action: action });
    } else {
      this.saucerLogService.error({
        apiPath,
        body: resData,
        statusCode: resData.statuscode,
        content: JSON.stringify({
          old: itemDelete,
          new: null
        })
      }, { action: action });
    }
    return resData;
  }

  async getListDashboardSetting(dashboardCd: string = '') {
    let parameter = [
      dashboardCd
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.SETTING;
    let resData = await this.get(apiUrl, parameter);
    return resData;
  }

  async getPublic(isAdminOrSupporter?: boolean) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.PUBLIC;
    if(isAdminOrSupporter)
      apiUrl += "/true";
    else
      apiUrl += "/false";
    const resData = await this.get(apiUrl);
    if(resData.statuscode === 200) {
      return this.mapModelDetail(resData.data);
    }
    return [];
  }

  async getDetail(dashboardCd: string) {
    let parameter = [
      dashboardCd
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.DETAIL;
    let resData = await this.get(apiUrl, parameter);
    if(resData.statuscode === 200) {
      return this.mapModelDetail(resData.data);
    }
    return [];
  }

  async getSettingDetail(dashboardCd: string) {
    let parameter = [
      dashboardCd
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.SETTINGDETAIL;
    let resData = await this.get(apiUrl, parameter);
    if(resData.statuscode === 200) {
      this.sessionDashboardData = resData?.data || [];
      return this.mapModelDetail(resData.data);
    }
    return [];
  }

  async getSettingWidgetDetail(dashboardCd: string, isTemplate:boolean = false) {
    let parameter = [
      dashboardCd
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.SETTINGWIDGETDETAIL;
    if(isTemplate) apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.SETTINGWIDGETDETAILTEMPLATE;

    let resData = await this.get(apiUrl, parameter);
    if (resData.statuscode === 200) {
      let dashboard = this.mapModelDetail(resData.data);
      // save session for saucer log
      if (dashboard[0]?.setting.length !== this.sessionDashboardDetail?.setting?.length) {
        this.sessionDashboardDetail = dashboard[0];
      }
      return dashboard
    }
    return {};
  }
  mapModelWidgetDetail(data: any) {
    const res: ResponseData<Widget[]> = new ResponseData<Widget[]>();
    const dataArr = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for (const element of data.data || []) {
        const widget = new Widget();
        widget.id = element.widgetid;
        widget.widgetCd = element.widgetcd;
        widget.name = element.widgetname;
        widget.chartType = element.charttype;
        widget.description = element.widgetdesc;
        widget.folderCd = element.foldercd;
        widget.delflg = element.delflg;
        widget.dataTableConfig = [];
        widget.pivotTableConfig = new PivotTableConfig();
        widget.insdate = element.insdate || new Date(element.insdate);
        widget.upddate = element.insdate || new Date(element.upddate);
        widget.insstfcd = element.insstfcd;
        widget.updstfcd = element.updstfcd;
        widget.insstfnm = element.insstfnm;
        widget.updstfnm = element.updstfnm;
        widget.sortcoltype = element.sortcoltype;
        dataArr.push(widget);
      }
    }
    res.data = dataArr;
    return res;
  }
  mapModelDetail(data: any) {
    if(!data) return [];
    const dataArr: Dashboard[] = [];
      for (const element of data) {
        const db = dataArr.find(d => d.id === element.id);
        if (db && element.widgetposition) {
          const detail = this.mapDashboardSetting(element);
          db.setting.push(detail);
          continue;
        }

        const dash = new Dashboard();
        dash.id = element.id;
        dash.name = element.dashboardname;
        dash.dashboardCd = element.dashboardcd;
        dash.description = element.dashboarddesc;
        dash.folderCd = element.foldercd;
        dash.folderName = element.foldername;
        dash.insdate = element.insdate;
        dash.insstfcd = element.insstfcd;
        dash.insstfnm = element.insstfnm;
        dash.upddate = element.upddate;
        dash.updstfnm = element.updstfnm;
        dash.publicCd = element.publiccd;
        dash.publicDate = element.publicdate;
        dash.publictype = element.publictype;
        dash.sortcoltype = element.sortcoltype;
        dash.setting = [];
        if (element.widgetposition) {
          const detail = this.mapDashboardSetting(element);

          dash.setting.push(detail);
        }

        if (element.updstfcd) {
          dash.displayNm = element.updstfnm;
          dash.displayDate = (moment(element.upddate)).format(DateFormat.FULL_SHORT_DATE);
        } else {
          dash.displayNm = element.insstfnm;
          dash.displayDate = (moment(element.insdate)).format(DateFormat.FULL_SHORT_DATE)
        }
        dataArr.push(dash);
      }

    return dataArr;
  }


  mapWidgetModel(data: any) {
    if(!data) return [];
    let dataArr = [];
      for  (const element of data) {
        let widget = new Widget();
        widget.id = element.id;
        widget.widgetCd = element.widgetcd;
        widget.name = element.widgetname;
        widget.description = element.widgetdesc;
        widget.chartType = element.charttype;
        widget.publicDate = element.publicdate;
        dataArr.push(widget);
      
      }
    return dataArr;
  }

  async getWidgetList(dashboardCd: string) {
    let parameter = [
      dashboardCd
    ];
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.GETWIDGETLIST;
    let resData = await this.get(apiUrl, parameter);
    if(resData.statuscode === 200) {
      return this.mapWidgetModel(resData.data);
    }
    else return [];
  }

  async insertOrUpdateDbDetail(bodyData: any) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.INSERTORUPDATEDETAIL;
    const oldDashboardDetail = this.sessionDashboardDetail?.setting?.map((x:any) => ({
      widgetcd: x.widgetCd,
      widgetname: x.widgetName || x.name
    }));
    
    const newDashboardDetail = bodyData?.dashboard?.setting?.map((x:any) => ({
      widgetcd: x.widgetCd,
      widgetname: x.widgetName || x.name
    }));

    const saveAsDashboardDetail = bodyData?.listDataChange?.map((x:any) => ({
      widgetcd: x.widgetcd
    }));
    
    let resData = await this.post(apiUrl, [], bodyData);

    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    const oldLogContent = {
      foldercd: this.sessionDashboardDetail?.folderCd,
      dashboardcd: this.sessionDashboardDetail?.dashboardCd,
      dashboardname: this.sessionDashboardDetail?.name,
      widgetlst: oldDashboardDetail
    };
    const isEdit = this.actionLog?.action == ACTION_LOG.UPDATE;
    const contentLog = {
      old: isEdit ? oldLogContent : null,
      new: {
        foldercd: bodyData.dashboard?.folderCd || bodyData.dashboard?.foldercd,
        dashboardcd: bodyData.dashboard?.dashboardCd,
        dashboardname: bodyData.dashboard?.name || bodyData.dashboard?.dashboardname,
        widgetlst: newDashboardDetail || saveAsDashboardDetail
      }
    }

    if(resData.statuscode == 200) {
      this.saucerLogService.system(
        {
          apiPath,
          body: resData,
          statusCode: resData.statuscode,
          content: JSON.stringify(contentLog)
        }, 
        { 
          action: this.actionLog
        }
      );
    }
    else {
      this.saucerLogService.error(
        {
          apiPath,
          body: resData,
          statusCode: resData.statuscode,
          content: JSON.stringify(contentLog)
        }, 
        { 
          action: this.actionLog
        }
      );
    }
    this.sessionDashboardDetail = [];
    return resData;
  }

  async deleteDashboardAndDetail(bodyData: any) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.DELETEDASHBOARDANDDETAIL;
    const apiPath = apiUrl.replace(PIVOT_CLIENT_API_URL, "");
    let resData = await this.post(apiUrl, [], bodyData);

    let isTemplate = window.location.pathname.includes(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL) ? true : false;
    let actionLog = isTemplate? SAUCER_LOG_ACTION.DASHBOARD_TEMPLATE_EDITING.DELETE_DASHBOARD : SAUCER_LOG_ACTION.DASHBOARD_EDITING.DELETE_DASHBOARD;
    const logDelete = {
      old: {
        dashboardCd: bodyData?.dashboard?.dashboardCd,
        dashboardName: (bodyData?.dashboard?.dashboardName || '')
      },
      new: null
    };
    
    let content = JSON.stringify(logDelete);
    if(resData.statuscode == 200) {
      this.saucerLogService.system(
        {
          apiPath,
          body: resData,
          statusCode: resData.statuscode,
          content: content
        }, 
        { 
          action: actionLog
        }
      );
    }
    else {
      this.saucerLogService.error(
        {
          apiPath,
          body: resData,
          statusCode: resData.statuscode,
          content: content
        }, 
        { 
          action: actionLog
        }
      );
    }
    return resData;
  }

  mapDashboardModel(data: any) {
    let dash = new DashboardModel();
    dash.upddate = data?.upddate;
    dash.updstfcd = data?.updstfcd;
    dash.insdate = data?.insdate;
    dash.insstfcd = data?.insstfcd;
    dash.publiccd = data?.publicCd;
    dash.id = data?.id;
    dash.dashboardname = data?.name;
    dash.dashboarddesc = data?.description;
    dash.dashboardcd = data?.dashboardCd;
    dash.delflg = data?.delflg;
    dash.foldercd = data?.folderCd;
    dash.publictype = data?.publictype;
    return dash;
  }


  async getAllDashboardTemplate(isCheckingPublic: boolean) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.TEMPLATE;
    let resData = await this.get(apiUrl, [isCheckingPublic]);
    return resData;
  }

  async getListDashboardTemplate(isCheckingPublic: boolean) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.LIST_DASHBOARD_TEMPLATE;
    let resData = await this.get(apiUrl, [isCheckingPublic]);
    return resData;
  }

  async getWidgetTemplatesByDashboard(dashboardCd: string) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + "/" + dashboardCd + API_APP.DASHBOARD.GETWIDGETLIST + API_APP.DASHBOARD.TEMPLATE;
    let resData = await this.get(apiUrl);
    if(resData.statuscode === 200) {
      return this.mapWidgetModel(resData.data);
    }
    return [];
  }

  async getSettingDetailTemplate(dashboardCd: string) {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER+ "/" + dashboardCd  + API_APP.DASHBOARD.SETTINGDETAIL + API_APP.DASHBOARD.TEMPLATE;
    let resData = await this.get(apiUrl);
    if (resData.statuscode === 200) {
      this.sessionDashboardData = resData?.data || [];
      return this.mapModelDetail(resData.data);
    }
    return []; 
  }

  async getListDashboardTemplateNew() {
    let apiUrl = API_APP.DASHBOARD.CONTROLLER + API_APP.DASHBOARD.GETLISTDASHBOARDTEMPLATENEW;
    let resData = await this.get(apiUrl, []);
    if (resData.statuscode === 200) {
        return resData;
    }
    return []; 
  }
}
