import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import { AccessOptions, SideMenu, SystemSettingPage, TEAM_EVERYONE } from '../../../app/const/const';
import { MESSAGE_TEXT } from '../../../app/const/message';
import { BUTTON, COMMON_TEXT, FOLDER_DIALOG } from '../../../app/const/text-common';
import { AssignFolderType, ButtonType, InputType, SearchType, UserAction } from '../../../app/enum/common-enum';
import { ModalTemplate, SearchParams } from '../../../app/models/common-model';
import { AssignDTO } from '../../../app/models/request/assign.dto';
import { FolderAddDTO, FolderUpdateDTO } from '../../../app/models/request/folder.dto';
import { Folder, FOLDER_TYPE } from '../../../app/models/response/folder.ro';
import { Staff } from '../../../app/models/response/staff.ro';
import { Team } from '../../../app/models/response/team.ro';
import { AuthenticationService } from '../../../app/services/authentication.service';
import { ProcessLoadingService } from '../../../app/services/loading.service';
import { AssignService } from '../../../app/services/modules/assign.service';
import { FolderService } from '../../../app/services/modules/folder.service';
import { TeamService } from '../../../app/services/modules/team.service';
import Utils from '../../../app/util/utils';
import { LocalStorageHelper, LocalStorageKey } from '../../_helper/local-storage.helper';
import { ROUTE_PATH } from 'src/app/const/route-path';
import { Router } from '@angular/router';

@Component({
  selector: 'pivot-dialog-folder-editor',
  templateUrl: './dialog-folder-editor.component.html',
  styleUrls: ['./dialog-folder-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogFolderEditorComponent implements OnInit, OnChanges {
  // @ViewChild('#helpPanel',{static:true}) helpPanel: OverlayPanel;
  @ViewChild('targethelpPanel') targethelpPanel!: ElementRef;
  
  @Input() isShowAssign: boolean = true;
  @Input() dataAccessListOption: any[];
  @Input() isTemplate: boolean = false;
  // display modal variables
  @Input() isDisplayModal: boolean = false;
  @Input() folderType: FOLDER_TYPE = FOLDER_TYPE.Datasource;
  // edit folder data
  @Input() data: Folder;
  @Input() assignPermission: boolean = true;
  // modal params
  @Input() modalData: ModalTemplate = new ModalTemplate();
  @Input() folderActionLog : {screenName: string, action: string, body: string, content: string} | null = null;
  // event submit data
  @Output() onSubmitData: any = new EventEmitter<any>();
  // new folder variables
  newFolder: Folder = new Folder();
  // disable folder assign
  disableAssignPermission: boolean = true;
  disableAccessPermission: boolean = true;
  disableInputName: boolean = true;
  // list assign change data
  listAssignChange: AssignDTO[] = [];
  // list Assign remove
  lstAssignDelete: AssignDTO[] = [];
  // assign act
  act: number = 1;
  // button type variables
  buttonType = ButtonType;
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  FOLDER_DIALOG = FOLDER_DIALOG;
  shared: any = null;
  name: string = '';
  // list staff remove
  staffRemove: any[] = [];
  assignType: AssignFolderType = AssignFolderType.ONLY_ME;

  ONLY_ME: AssignFolderType = AssignFolderType.ONLY_ME
  // assign type params
  assignTypeParams: SearchParams = {
    type: SearchType.combo,
    placeholder: '',
    isFilter: false,
    defaultValue: COMMON_TEXT.ONLY_ME,
    cssStyle: { height: '40px', width: '100%', 'font-size': '14px' },
    readonly: false,
    disabled: this.disableAssignPermission,
    dataSource: [],
    comboDisplayText: 'name'
  };
  // list staff
  staffs: Staff[] = [];
  // field name staff
  fieldNameStaff: string = 'fullName';
  // field name team
  fieldNameTeam: string = 'teamName';
  // list assign selected
  assignSelecteds: AssignDTO[] = [];
  // list team
  teams: Team[] = [];
  // assign model
  assignModel: any[] = [];
  // staffcd
  staffcd: string = '';
  // list assigns
  assigns: AssignDTO[] = [];
  // check Staff
  checkStaff: boolean = false;
  // 
  actionParams: any = {
    type: SearchType.combo,
    placeholder: '',
    isFilter: false,
    defaultValue: AccessOptions[0].name,
    cssStyle: { height: '40px', width: '100%', 'font-size': '14px' },
    readonly: false,
    disabled: false,
    dataSource: AccessOptions,
    comboDisplayText: 'name'
  }
  

  actionParamsDisable: any = {
    type: SearchType.combo,
    placeholder: '',
    isFilter: false,
    defaultValue: '',
    cssStyle: { height: '40px', width: '100%', 'font-size': '14px' },
    readonly: false,
    disabled: false,
    dataSource: [],
    comboDisplayText: 'name'
  }
  // input textbox folder params
  inputFolderNameParams: any = {
    type: InputType.text,
    validate: false,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT,
    borderFill: true,
    maxLength: 100
  };

  autoCompleteParams: any = {
    validate: false,
    isError: false,
    validateError: MESSAGE_TEXT.REQUIRE_FIELD,
    borderFill: true,
  };

  // input textbox description params
  inputDesParams: any = {
    type: InputType.text,
    validate: false,
    borderFill: true,
    maxLength: 200
  };

  listActionName: any[] = [];
  listAccessFieldDisplay: string;
  excludeSearchData: any[] = [];
  loggedInUser: any;
  isDisplaySystemSetting = false;
  // saucer Log
  oldAssignSaucerLog: any = { assignList: [], accessData: null };
  newAssignSaucerLog: any = { assignList: [], accessData: null };
  constructor(private teamService: TeamService,
    private authenticationService: AuthenticationService,
    private loadingService: ProcessLoadingService,
    private folderService: FolderService,
    private router: Router,
    private assignService: AssignService) {

  }

  async ngOnChanges() {
    this.staffcd = this.authenticationService.currentUserValue.staffcode;
    this.disableAssignPermission = false;
    this.autoCompleteParams.isError = false;
    // check show modal status
    if (this.isDisplayModal) {
      this.setAssignTypeParams(COMMON_TEXT.ONLY_ME);
      this.loadingService.isLoading.emit(true);
      this.clearValidate();
      if (!Utils.isNullOrEmpty(this.data)) {
        this.modalData.header = FOLDER_DIALOG.FOLDER_DLG_EDIT;
        this.newFolder = this.data;
        this.disableAssignPermission = !this.assignPermission;
        this.disableAccessPermission = true;
        this.disableInputName = true;

        await this.getListAssign();
        // set act 
        if (this.assigns?.length > 0) {
          this.act = this.assigns[0]?.act;
          this.actionParams.defaultValue = this.act == UserAction.VIEW ? AccessOptions[0].name : this.act == UserAction.EDIT ? AccessOptions[1].name : AccessOptions[2].name;
          this.actionParams = cloneDeep(this.actionParams);
        }
        else if(this.assignType == AssignFolderType.ONLY_ME){
          this.clearActionParam();
        }
      }
      else {
        this.act = 1;
        this.assignType = AssignFolderType.ONLY_ME; 
        this.modalData.header = FOLDER_DIALOG.FOLDER_DLG_CREATE;
        this.newFolder = new Folder();
        this.assignModel = [];
      }
      this.loadingService.isLoading.emit(false);
    }
    if (this.assignType == AssignFolderType.STAFF) {
      this.assignTypeParams.defaultValue = COMMON_TEXT.STAFF;
    }
    else if (this.assignType == AssignFolderType.GROUP) {
      this.assignTypeParams.defaultValue = COMMON_TEXT.GROUP;
    }
    else if (this.assignType == AssignFolderType.EVERYONE) {
      this.assignTypeParams.defaultValue = COMMON_TEXT.EVERYONE;
    }
    else if (this.assignType == AssignFolderType.ONLY_ME) {
      this.assignTypeParams.defaultValue = COMMON_TEXT.ONLY_ME;
    }
    else {
      this.assignTypeParams.defaultValue = COMMON_TEXT.ONLY_ME;
    }
    this.assignTypeParams = cloneDeep(this.assignTypeParams);
    this.setAccessListOption(this.staffs, this.teams);
    this.assignModel = cloneDeep(this.assignModel);
    this.oldAssignSaucerLog.accessData = this.act;
  }

  async ngOnInit() {
    this.setAssignTypeParams(COMMON_TEXT.ONLY_ME);
    this.loggedInUser = this.authenticationService.getData(LocalStorageKey.CURRENT_USER);
    await this.getListTeam();
    this.staffs = this.authenticationService.getData(LocalStorageKey.ALL_STAFF) || [];
    // filter unique staffs
    this.staffs = this.staffs.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.staffCd === value.staffCd
      ))
    );
    //filter supporter and admin
    this.staffs = this.staffs.filter( e => !((e.isSupport && e.roleCd == "RL00000099") || e.roleCd == "RL00000001"));
    this.setAccessListOption(this.staffs, this.teams);
    this.assignModel = [];
    this.listAssignChange = [];
    this.lstAssignDelete = [];
    this.authenticationService.currentUser.subscribe((user: any) => {
      if (!user || !user.token) {
        return;
      }
      const roles = this.authenticationService.getData(LocalStorageKey.CURRENT_USER_ROLE);
      if (roles && roles.length > 0 && roles[0].permissions) {
        const item = roles[0].permissions.find((s: any) => s == SideMenu.SYSTEM_SETTING);
        if (item) this.isDisplaySystemSetting = true;
      } else {

      }
    });
  }

  // close 
  onClose() {
    this.isDisplayModal = false;
    this.lstAssignDelete = [];
    this.listAssignChange = [];
    this.assignModel = [];
    this.clearActionParam();
    this.onSubmitData.emit(null);
  }

  clearActionParam() {
    this.act = 1;
    this.actionParams.defaultValue = AccessOptions[0].name;
    this.actionParams = cloneDeep(this.actionParams);
    this.assignTypeParams.defaultValue = COMMON_TEXT.ONLY_ME;
    this.assignTypeParams = cloneDeep(this.assignTypeParams);
    this.assignType = AssignFolderType.ONLY_ME;
    this.assigns = [];
    this.setAccessListOption(this.staffs, this.teams);
  }

  setAccessListOption(staffData: any[], teamData: any[]) {
    this.dataAccessListOption = [
      {
        name: COMMON_TEXT.ONLY_ME,
        value: AssignFolderType.ONLY_ME,
        data: staffData,
        field: this.fieldNameStaff,
      },
      {
        name: COMMON_TEXT.STAFF,
        value: AssignFolderType.STAFF,
        data: staffData,
        field: this.fieldNameStaff,
      },
      {
        name: COMMON_TEXT.GROUP,
        value: AssignFolderType.GROUP,
        data: teamData,
        field: this.fieldNameTeam,
      },
      {
        name: COMMON_TEXT.EVERYONE,
        value: AssignFolderType.EVERYONE,
        data: teamData,
        field: this.fieldNameTeam,
      },
    ];
    this.assignTypeParams.dataSource = this.dataAccessListOption;
    let index = 0;
    let itemDefault = {}
    itemDefault = this.assignTypeParams.dataSource.find((x:any)=>{
      return (x.name == this.assignTypeParams.defaultValue)
    })
    index = this.assignTypeParams.dataSource.indexOf(itemDefault);
    this.listActionName = this.dataAccessListOption[index]?.data;
    this.listAccessFieldDisplay = this.dataAccessListOption[index]?.field;

    if (this.assignTypeParams.defaultValue == COMMON_TEXT.STAFF) {
      this.excludeSearchData = [{ [this.listAccessFieldDisplay]: this.loggedInUser?.fullname }];
    } else {
      this.excludeSearchData = [];
    }
    this.assignTypeParams = cloneDeep(this.assignTypeParams);
  }

  onAssignListItemDelete() {
    if (this.assignModel.length > 0) {
      this.assignModel?.forEach((asm: any) => {
        this.lstAssignDelete.push(asm);
      });
    }
  }

  // 
  onFilterAssignType(data: any) {
    this.onAssignListItemDelete();
    this.autoCompleteParams.isError = false;
    this.assignModel = [];
    if (data[0]?.name == COMMON_TEXT.GROUP) {
      this.assignType = AssignFolderType.GROUP;
      this.checkStaff = false;
      this.excludeSearchData = [];
      this.disableAccessPermission = false;
      this.disableInputName = false;
    }
    else if (data[0]?.name == COMMON_TEXT.ONLY_ME) {
      this.assignType = AssignFolderType.ONLY_ME;
      this.checkStaff = true;
      this.excludeSearchData = [{ [data[0]?.field]: this.loggedInUser?.fullname }];
      this.disableAccessPermission = true;
      this.disableInputName = true;
    }
    else if (data[0]?.name == COMMON_TEXT.EVERYONE) {
      this.assignType = AssignFolderType.EVERYONE;
      this.checkStaff = true;
      this.excludeSearchData = [{ [data[0]?.field]: this.loggedInUser?.fullname }];
      this.disableAccessPermission = false;
      this.disableInputName = true;
    }
    else if (data[0]?.name == COMMON_TEXT.STAFF) {
      this.assignType = AssignFolderType.STAFF;
      this.checkStaff = true;
      this.excludeSearchData = [{ [data[0]?.field]: this.loggedInUser?.fullname }];
      this.disableAccessPermission = false;
      this.disableInputName = false;
    }
    else {
      this.assignType = AssignFolderType.STAFF;
      this.checkStaff = true;
      this.excludeSearchData = [{ [data[0]?.field]: this.loggedInUser?.fullname }];
      this.disableAccessPermission = false;
      this.disableInputName = false;
    }
    this.listActionName = data[0]?.data;
    this.listAccessFieldDisplay = data[0]?.field;
    this.assignSelecteds = [];
    this.setlistAssignChange();
  }

  async onSubmitFolder() {
    if(!this.validateData()) return;
    this.isDisplayModal = false;
    this.loadingService.isLoading.emit(true);
    this.setItemForListRemove();
    let listStaffCd = this.staffs.map(x=>x.staffCd);
    let assigns = this.assigns.filter(x=>x.staffcd  && x.staffcd !=''  && x.staffcd && !listStaffCd.includes(x.staffcd));

    let isUpdateOrSaveSuccess: any = true;

    let dataParam: any = {
      isUpdate: false,
      foldes: [],
      listAssignsAdmin: assigns,
      listDataChange: this.listAssignChange,
      listDataDelete: this.lstAssignDelete,
    }
   
    this.newAssignSaucerLog.assignList = this.assignSelecteds;
    this.newAssignSaucerLog.accessData = this.newAssignSaucerLog.accessData || this.act;
    let assignSaucerLog = {
      oldAssign: this.oldAssignSaucerLog,
      newAssign: this.newAssignSaucerLog
    }

    if (Utils.isNullOrEmpty(this.data)) {
      let insertFolder: FolderAddDTO[] = [
        {
          id: v4().toString(),
          foldercd: '',
          assigncd: '',
          foldername: this.newFolder.name ? this.newFolder.name.trim() : '',
          folderdesc: this.newFolder.description ? this.newFolder.description.trim() : '',
          foldertype: this.folderType,
          assigntype: this.assignType,
          insstfcd: this.staffcd,
          insdate: new Date(),
          delflg: false,
          istemplate: this.isTemplate,
        }
      ];
      let dataChangeParam = cloneDeep(dataParam);
      dataChangeParam.foldes = insertFolder;
      dataChangeParam.isUpdate = false;
      isUpdateOrSaveSuccess = await this.folderService.insertOrUpdate(dataChangeParam, this.folderActionLog, assignSaucerLog);
    }
    else {
      let folderUpdate: FolderUpdateDTO[] = [
        {
          id: this.data.id?.toString() || '',
          foldercd: this.data?.folderCd || '',
          assigncd: this.data.assign?.assignCd || '',
          foldername: this.newFolder.name ? this.newFolder.name.trim() : '',
          folderdesc: this.newFolder.description ? this.newFolder.description.trim() : '',
          foldertype: this.folderType,
          assigntype: this.assignType,
          updstfcd: this.staffcd,
          insstfcd: this.data.insstfcd,
          insdate: this.data.insdate,
          upddate: new Date(),
          delflg: false,
          istemplate: this.isTemplate,
        }
      ];
      let paramsUpdate: any = cloneDeep(dataParam);
      paramsUpdate.foldes = folderUpdate;
      paramsUpdate.isUpdate = true;
      isUpdateOrSaveSuccess = await this.folderService.insertOrUpdate(paramsUpdate, this.folderActionLog, assignSaucerLog);
    }
    this.clearActionParam();
    if(isUpdateOrSaveSuccess == true || (isUpdateOrSaveSuccess.statuscode && isUpdateOrSaveSuccess.statuscode == 200)) {
      this.onSubmitData.emit(this.newFolder);
    }
    else {
      this.onSubmitData.emit(false);
    }
    this.loadingService.isLoading.emit(false);
  }

  onAccessFilterData(act: any) {
    this.act = act[0]?.value;
    this.newAssignSaucerLog.accessData = this.act;
    this.assignSelecteds = cloneDeep(this.assignModel);
    this.setlistAssignChange();
  }

  // input folder name
  onInputFolderName(data: any) {
    this.newFolder.name = data.currentTarget?.value;
  }

  // input folder name focusout
  inputFolderFocusOut() {
    this.inputFolderNameParams.validate = false;
  }

  // input Explanation
  onInputDescription(data: any) {
    this.newFolder.description = data.currentTarget?.value;
  }

  // input dashboard name
  onInputDashboardName(data: any) {
    this.name = data.currentTarget?.value;
  }

  onChangeAssignList(data: any) {
    this.assignModel = data;
    this.assignSelecteds = data;
    this.setlistAssignChange();
    this.autoCompleteParams.isError = false;
  }

  // get list team from service
  async getListTeam() {
    let teamLst = await this.teamService.getAll();
    if (teamLst.statuscode == 200) {
      this.teams = teamLst.data || [];
    }
  }

  // set assign type params
  setAssignTypeParams(defaultValue: string) {
    this.assignTypeParams.defaultValue = defaultValue;
    this.assignTypeParams = cloneDeep(this.assignTypeParams);
    if (this.assigns?.length == 0) {
      this.setAccessListOption(this.staffs, this.teams);
    }
  }

  getPublicInfor(feaItem: any) {
    return {
      staffcd: feaItem?.staffcd == undefined ? feaItem?.staffCd == undefined ? '' : feaItem?.staffCd : feaItem?.staffcd,
      teamcd: feaItem.teamcd == undefined ? feaItem.teamCd == undefined ? '' : feaItem.teamCd : feaItem.teamcd
    }
  }
  // get list assign
  async getListAssign() {
    this.assigns = [];
    this.assignModel = [];
    let assignCD = this.data?.assign?.assignCd || '';
    if (!Utils.isNullOrEmpty(assignCD)) {
      await this.assignService.getByAssignCD(assignCD).then((data: any) => {
        if (data.statuscode == 200) {
          this.assigns = data.data || [];
          this.assigns.forEach((ass: any) => {
            let publicInfor = this.getPublicInfor(ass);
            if (!Utils.isNullOrEmpty(publicInfor.staffcd)) {
              this.checkStaff = true;
              this.listAccessFieldDisplay = this.fieldNameStaff;
              this.excludeSearchData = [{ [this.listAccessFieldDisplay]: this.loggedInUser?.fullname }];
              this.listActionName = this.dataAccessListOption[0]?.data;
              let findStaff = this.staffs.find((s: any) => s.staffCd === publicInfor.staffcd);
              if (!Utils.isNullOrEmpty(findStaff)) {
                this.assignModel.push(findStaff);
              }

            }
            else if (!Utils.isNullOrEmpty(publicInfor.teamcd)) {
              this.checkStaff = false;
              let findTeam = this.teams.find((s: any) => s.teamCd === publicInfor.teamcd);
              if (!Utils.isNullOrEmpty(findTeam)) {
                this.listAccessFieldDisplay = this.fieldNameTeam;
                this.excludeSearchData = [];

                this.listActionName = this.dataAccessListOption[0]?.data;
                this.assignModel.push(findTeam);
              }
            }
          });
        }
      });
    }
    this.assignSelecteds = this.assignModel;
    this.oldAssignSaucerLog.assignList = this.assignModel;
    this.assignType = this.data.assigntype ? this.data.assigntype : AssignFolderType.ONLY_ME;
    if (this.assignType == AssignFolderType.STAFF) {
      this.setAssignTypeParams(COMMON_TEXT.STAFF);
      this.disableAccessPermission = false;
      this.disableInputName = false;
    } else if (this.assignType == AssignFolderType.GROUP) {
      this.setAssignTypeParams(COMMON_TEXT.GROUP);
      this.disableAccessPermission = false;
      this.disableInputName = false;
    } else if (this.assignType == AssignFolderType.EVERYONE) {
      this.checkStaff = false;
      this.setAssignTypeParams(COMMON_TEXT.EVERYONE);
      this.disableAccessPermission = false;
      this.disableInputName = true;
    }
  }

  // on remove list item assign
  onRemoveAssignData(event: any) {
    this.lstAssignDelete.push(event);
    this.autoCompleteParams.isError = false;
  }

  // set list assign insert
  setlistAssignChange() {
    this.listAssignChange = [];
    if (this.assignType == AssignFolderType.EVERYONE) {
      let everyonTeam: any = {
        teamCd: TEAM_EVERYONE,
        teamName: 'EVERYONE'
      }
      this.assignSelecteds = [];
      this.assignSelecteds.push(everyonTeam);
    }

    this.assignSelecteds?.forEach((assign: any) => {
      let publicInfor = this.getPublicInfor(assign);
      let assignObj: AssignDTO = {
        id: v4(),
        assigncd: this.data != undefined ? this.data.assign?.assignCd || '' : '',
        act: this.act,
        teamcd: this.assignType == AssignFolderType.GROUP ? publicInfor.teamcd : (this.assignType == AssignFolderType.EVERYONE? TEAM_EVERYONE : ''),
        staffcd: this.assignType == AssignFolderType.STAFF ? publicInfor.staffcd : '',
        delflg: false,
        insstfcd: this.staffcd,
        insdate: new Date(),
        updstfcd: ''
      };
      let assignData = this.assignType == AssignFolderType.STAFF ? this.assigns.find(a => a.assigncd == assignObj.assigncd && a.staffcd == publicInfor.staffcd) : this.assigns.find(a => a.assigncd == assignObj.assigncd && a.teamcd == publicInfor.teamcd);
      if ((assignData != undefined && assignData?.act != this.act) || assignData == undefined) {
        if ((assignData != undefined && assignData?.act != this.act)) {
          assignObj.isUpdate = true;
          assignObj.id = assignData?.id || v4();
        }
        else {
          assignObj.isUpdate = false;
        }
        if (assignObj.staffcd != '' || assignObj.teamcd != '') {
          this.listAssignChange.push(
            assignObj
          );
        }
      }
    });
  }

  setItemForListRemove() {
    let listDelete: any[] = cloneDeep(this.lstAssignDelete);
    this.lstAssignDelete = [];
    // add item for list assign delete
    if (listDelete?.length > 0) {
      listDelete?.forEach((d: any) => {
        let publicInfor = this.getPublicInfor(d);
        let index: number = -1;
        if (!Utils.isNullOrEmpty(publicInfor.staffcd)) {
          index = this.assigns?.findIndex((s: any) => s.staffcd == publicInfor.staffcd);
        }
        else if (!Utils.isNullOrEmpty(publicInfor.teamcd)) {
          index = this.assigns?.findIndex((s: any) => s.teamcd == publicInfor.teamcd);
        }
        // push delete data
        if (index != -1) {
          this.lstAssignDelete.push(this.assigns[index]);
        }
      });
    }
    let assignEveryOne = this.assigns.find(a => a.teamcd == TEAM_EVERYONE);
    if(assignEveryOne && this.assignType != AssignFolderType.EVERYONE){
      this.lstAssignDelete.push(assignEveryOne);
    }
  }
  validateData():boolean{
    if (Utils.isNullOrEmpty(this.newFolder?.name)) {
      this.inputFolderNameParams.validate = true;
      this.inputFolderNameParams.isValidate = true;
      return false;
    }
    if(this.assignType == AssignFolderType.STAFF || this.assignType == AssignFolderType.GROUP){
      if((!this.assignSelecteds) || (this.assignSelecteds.length == 0)) {
        this.autoCompleteParams.isError = true;
        return false;
      }
    }
    return true;
  }
  clearValidate(){
    this.autoCompleteParams.isError = false;
    this.inputFolderNameParams.isValidate = false;
  }

  isPanelEnter = false;
  showPanel  = false;
  onTargetMouseover() {
    this.showPanel = true;
  }
  onTargetMouseout() {
    setTimeout(() => {
      if (!this.isPanelEnter) {
        this.showPanel = false;
        this.isPanelEnter = false;
      }
    }, 100);
  }

  onPanleMouseout() {
    if (this.isPanelEnter) {
      this.showPanel = false;
      this.isPanelEnter = false;
    }
  }
  onPanleMousenter(){
    this.isPanelEnter = true;
  }
  gotoStaffInfo(){
    this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.SYSTEM_SETTING) + '/' + SystemSettingPage.STAFF_INFO]);
  }
}
