import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { COMMON_TEXT } from '../../../../app/const/text-common';

@Component({
  selector: 'pivot-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  COMMON_TEXT = COMMON_TEXT;
  _itemsPerPage: number = 20;
  _currentPage: number = 1;
  totalPage: number = 0;
  toogleDropdown: boolean = false;
  @Output() paginateData = new EventEmitter<any>();
  get currentPage() {
    return this._currentPage;
  }

  @Input() set currentPage(value: number) {
    this._currentPage = value;
    this.paginateData.emit({ "currentPage": value, "pageSize": this.itemsPerPage });
  }

  get itemsPerPage() {
    return this._itemsPerPage;
  }

  @Input() set itemsPerPage(value: number) {
    this._itemsPerPage = value;
    this.paginateData.emit({ "currentPage": this.currentPage, "pageSize": value });
  }

  _totalRecord: number = 0;
  get totalRecord(): number {
    return this._totalRecord;
  }
  @Input() set totalRecord(value: number) {
    this._totalRecord = value;
    this.totalPage = this._totalRecord <= this.itemsPerPage ? 1 : Math.ceil(this._totalRecord / this.itemsPerPage);
    this.currentPage = 1;
  }

  ngOnInit(): void {
    this.totalPage = this.totalRecord <= this.itemsPerPage ? 1 : Math.ceil(this.totalRecord / this.itemsPerPage);
    this.currentPage = 1;
    window.addEventListener('click', (event: any) => {
      let modal = document.getElementById('pagination-modal');
      if (!modal?.contains(event.target)) {
        let pagination = document.getElementById('pagination');
        if (!pagination?.contains(event.target)) {
          this.toogleDropdown = false;
        }
      }
    });
  }

  changePageSize(item: number): void {
    this.itemsPerPage = item;
    this.currentPage = 1;
    this.totalPage = Math.ceil(this.totalRecord / this.itemsPerPage) === 0 ? 1 : Math.ceil(this.totalRecord / this.itemsPerPage);
    this.toogleDropdown = false;
  }
}
