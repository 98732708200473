import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { cloneDeep, findIndex } from 'lodash';
import { toFullwidthKana } from 'japanese-string-utils';
import StringUtils from '../../../../../app/util/stringUtils';
import { COMMON_TEXT } from '../../../../../app/const/text-common';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';


@Component({
  selector: 'pivot-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {

  @Input() filterLog: {screenName: string, action: string, body: string, content: string} | null = null;
  @Output() _onFilterOutData = new EventEmitter<any>();
  @Output() _onSearchData = new EventEmitter<any>();
  @Output() _onHide = new EventEmitter<any>();
  @Input() _displayFilter: any;
  @Input() _targetFilter: any;
  @Input() _tempDataFilters: any = [];
  @Input() displayText: string = '';
  @Input() _selectedData: any = null;
  @Input() isGroup: boolean = false;
  @Input() defaultValue: string = '';
  @Input() isFilter: boolean = true;
  @Input() isMutiple: boolean = true;
  @Input() appendToPosition: string = 'body';
  @Input() class: string = 'z-10000'; 
  @Input() scrollHiden = false;
  @Input() cssStyle: any = {};
  @Input() placeHolderFilter: string = '';
  @Input() maxLength: number = 100;
  borderStyle: any = {};
  checkSelected: boolean = false;
  itemSelectedDefault: any = null;
  timeoutSearchId: any = null;


  subject: Subject<any> = new Subject();
  tempDataFilters: any = [];
  dataFilters = [];
  selectedData: any = null;
  isSearch = false;
  protected clickEventListener: any;
  COMMON_TEXT = COMMON_TEXT;
  subscription: Subscription;

  @ViewChild('op', { static: false }) filter: any;
  constructor(private router: Router, private saucerLogService: SaucerLogService) { }

  ngOnInit(): void {
    this.subject
      // Execute after popup rendered
      .pipe(debounceTime(0))
      .subscribe(() => {
        const selectedItem = document.getElementsByClassName("p-listbox-item p-highlight")[0];
        // Scroll to selected item
        if (selectedItem) {
          selectedItem.scrollIntoView({
            behavior: "auto",
            block: "nearest",
            inline: "center"
          });
        }

        // Filter dialog component on header
        if (this.appendToPosition === "header") {
          this.clickEventListener = () => this.clickOutside();
          document.addEventListener('click', this.clickEventListener);

          const that = this;
          window.onresize = function () {
            that.filter.hide();
          }
        }
      });
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this._displayFilter = null;
      this.filter.hide();
    });
    this.tempDataFilters = [];
    this.dataFilters = [];
    this.selectedData = null;
    this.isSearch = false;
    let data: any[] = [];
    this.itemSelectedDefault = [];
    if(this._tempDataFilters) {
      let index: number = this._tempDataFilters.findIndex((s: any) => s.name === this.defaultValue);
      if (index != -1) {
        data.push(this._tempDataFilters[index]);
        this.itemSelectedDefault = data;
      }
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    if (this.scrollHiden) {
      this._displayFilter = null;
      this.filter.hide();
    }
  }

  ngOnChanges(): void {
    if (this.isFilter) {
      this.borderStyle = { 'border-top': 'solid 1px #969696' };
    }
    if (!this.isSearch && this.tempDataFilters?.length === 0) {
      this.tempDataFilters = this._tempDataFilters;
    };
    if (this._displayFilter === undefined || this._displayFilter === null || !this._displayFilter.type) {
      this.dataFilters = this._tempDataFilters;
      if (!this.isSearch) {
        this.tempDataFilters = this._tempDataFilters;
      }
    } else {
      this.dataFilters = this.tempDataFilters;
      if(this._targetFilter){
        this.filter.toggle(this._displayFilter, this._targetFilter);
      }else{
        this.filter.toggle(this._displayFilter);
      }
    
    };
    if (this._selectedData == null) {
      this._selectedData = this.itemSelectedDefault;
    }
    this.selectedData = this._selectedData;
  }

  ngOnDestroy() {
    this.subject.unsubscribe();
    if(this.subscription){
      this.subscription.unsubscribe();
    }
    
  }

  // display dialog filter
  displayPopup() {
    this.subject.next;
  }

  // on selected data for list-box
  onSelectedData(event: any) {
    this.checkSelected = true;
    if (this.isMutiple) {
      let tempDataFilters: any[] = [];
      if (event.value?.length > 0) {
        tempDataFilters = event.value;
      }
      if (this.isSearch) {
        if (tempDataFilters?.length === 0) {
          tempDataFilters = cloneDeep(this._selectedData);
          this.dataFilters.forEach((data: any) => {
            if (this.isGroup) {
              data.items.forEach((el: any) => {
                let index = findIndex(tempDataFilters, (item) => {
                  return item.name == el.name;
                }, 0);
                if (index != -1) {
                  tempDataFilters.splice(index, 1);
                }
              });
            } else {
              let index = findIndex(tempDataFilters, (item) => {
                return item.name == data.name;
              }, 0);
              if (index != -1) {
                tempDataFilters.splice(index, 1);
              }
            }
          });
        } else {
          let countItem = 0;
          if (this.isGroup) {
            this.dataFilters.forEach((item: any) => {
              countItem += item.items?.length;
            });
          } else {
            countItem = this.dataFilters?.length;
          }

          let temp: any = [];
          this._selectedData?.forEach((item: any) => {
            if (!tempDataFilters?.some(el => el.name === item.name)) {
              temp.push(item);
            };
          });

          if (tempDataFilters?.length > 1 && tempDataFilters?.length == countItem && (temp?.length >= tempDataFilters?.length || tempDataFilters?.length >= this._selectedData?.length)) {
            tempDataFilters = temp?.concat(tempDataFilters);
          } else if (tempDataFilters?.length === 1 && !this._selectedData?.some((item: any) => item.name === tempDataFilters[0]?.name)) {
            tempDataFilters = temp.concat(tempDataFilters);
          }
        }
        this._onSearchData.emit(this.dataFilters);
      } else {
        this._onSearchData.emit(this.tempDataFilters);
      }
      this._onFilterOutData.emit(tempDataFilters);
    } else {
      // Hide popup after selected item with single mode
      this.filter.hide();
      this._onFilterOutData.emit(event.value);
    }
    if (this.selectedData?.length > 0) {
      this.filter.hide();
    }
  }

  // Covert half width to full width
  toFullWidth(str: string): string {
    if (str == undefined) return '';
    return StringUtils.toFullWidthJP(toFullwidthKana(str));
  }

  // event search data
  onSearchData(event: any) {
    this._selectedData = null;
    let value: any = event.currentTarget?.value;
    if (value?.indexOf(' ') === 0 && value?.trim()?.length === 0) return;
    value = value?.trim();
    if (value !== '') {
      this.isSearch = true;
      let tempDataFilters: any = cloneDeep(this.tempDataFilters);
      if (this.isGroup) {
        let tmpGroup: any[] = [];
        tempDataFilters.filter((item: any) => {
          if (item.items?.length > 0 && item.items.some((el: any) => this.toFullWidth(el.name?.toUpperCase()).includes(this.toFullWidth(value?.toUpperCase())))) {
            item.items = item.items.filter((el: any) => this.toFullWidth(el.name?.toUpperCase()).includes(this.toFullWidth(value?.toUpperCase())));
            tmpGroup.push(item);
          }
        });
        tempDataFilters = cloneDeep(tmpGroup);
      } else {
        tempDataFilters = tempDataFilters.filter((item: any) =>
          this.toFullWidth(item.name?.toUpperCase()).includes(this.toFullWidth(value?.toUpperCase()))
        );
      }
      this.dataFilters = cloneDeep(tempDataFilters);
    } else {
      this.isSearch = false;
      this.dataFilters = cloneDeep(this.tempDataFilters);
      this.tempDataFilters = cloneDeep(this.tempDataFilters);
    }

    if(this.filterLog) {
      // clear time out search
      if (this.timeoutSearchId) clearTimeout(this.timeoutSearchId);

      this.timeoutSearchId = setTimeout(() => {
        this.saucerLogService.action({
          content: this.filterLog?.screenName + " ＞ " + this.filterLog?.content + value
        }, { 
          action: this.filterLog
        });
      }, 3000);
    }
  }

  // Hide popup when click outside popup.
  clickOutside() {
    const element = (event as MouseEvent).target as HTMLElement;

    if (!this.filter.container.contains(element)
      && this.filter?.target !== element
      && !this.filter?.target?.contains(element)
      && !this.filter.selfClick) {
      this.filter.hide();
    }
  }

  // Remove event after overlay gets hidden.
  removeClickEvent() {
    this._onHide.emit(true);
    this.isSearch = false;
    if (this.appendToPosition === "header") {
      document.removeEventListener("click", this.clickEventListener);
    }
  }
}
