import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DateFormat } from 'src/app/enum/common-enum';
import { ZaitakuDetailDTO } from 'src/app/models/request/zaitaku-detail.dto';
import { ZaitakuUserInfoDTO, ZaitakuDTO } from 'src/app/models/request/zaitaku.dto';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import {ErrorHandleService} from '../error-handle.service';
import { ZaitakuSettingDTO } from 'src/app/models/request/zaitaku-setting.dto';
import { zaitakuSettingModel } from 'src/app/models/zaitaku-model';
import { groupBy, map, mapValues } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { MAXIMUM_MONTH } from 'src/app/const/const';

@Injectable({ providedIn: 'root' })
export class ZaitakuService extends ApiService {
  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async getAll(isFromZaitakuScreen: boolean = false): Promise<any> {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.ALL;
    if(isFromZaitakuScreen === true) {
      apiUrl += "/true";
    } 
    else
      apiUrl += "/false";
    return await this.get(apiUrl);
  }

  async getDetailInfoByCondition(jigno: string, siteino: string) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.GET_DETAIL_INFO_BY_CONDITION;
    apiUrl = apiUrl.replace('{jigno}', jigno).replace('{siteino}', siteino);
    return await this.get(apiUrl);
  }

  async insert(bodyData: ZaitakuDTO) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.INSERT;
    let resData = await this.post(apiUrl, [], bodyData);
    return resData;
  }

  async insertDataTableUserInfo(bodyData: ZaitakuUserInfoDTO) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.INSERT_DETAIL_INFO;
    let resData = await this.post(apiUrl, [], bodyData);
    return resData;
  }

  async syncZaitaku(jigNo: string, siteiNo: string): Promise<any> {
    let bodyRequest = {
      jigNo,
      siteiNo
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.SYNC_DATA;
    return await this.post(apiUrl, [], bodyRequest);
  }
  
  async syncZaitakuBySetting(bodyData: any): Promise<any> {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.SYNC_DATA_BY_SETTING;
    return await this.post(apiUrl, [], bodyData);
  }
  

  async getZaitakuDetail(jigNo: string, siteiNo: string, sDate: string, eDate: string): Promise<any> {
    let bodyRequest = {
      jigNo,
      siteiNo,
      sDate,
      eDate
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.DETAIL;
    let resData = await this.post(apiUrl, [], bodyRequest);
    return this.mapZaitakuDetail(resData);
  }

  async getZaitakuDetailOffset(jigNo: string, siteiNo: string, sDate: string, offset: number): Promise<any> {
    let bodyRequest = {
      jigNo,
      siteiNo,
      sDate,
      offset
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.DETAIL_OFFSET;
    let resData = await this.post(apiUrl, [], bodyRequest);
    return this.mapZaitakuDetail(resData);
  }

  mapZaitakuDetail(data: any) {
    if (data.statuscode === 200 && data.data) {
      let listData: any[];
      listData = data.data;
      const newListData = listData.map(obj => {
        const { month, ...rest } = obj;
        return { month: moment(month).format(DateFormat.YEAR_MONTH), monthRaw: new Date(month) , ...rest };
      });
      return newListData;
    }
    return [];
  }

  async insertDetail(bodyData: ZaitakuDetailDTO[]) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.INSERT_DETAIL;
    let resData = await this.post(apiUrl, [], bodyData);
    return resData;
  }

  //#region setting
  async insertOrUpdateSetting(bodyData: ZaitakuSettingDTO) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.INSERT_OR_UPDATE_SETTING;
    let resData = await this.post(apiUrl, [], bodyData);
    return resData;
  }
  

  async getSettingByOffice(jigNo: string, siteiNo: string): Promise<zaitakuSettingModel[]> {
    let bodyRequest = {
      jigNo,
      siteiNo
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.GET_SETTING_BY_OFFICE;
    let resData = await this.post(apiUrl, [], bodyRequest);
    return this.mapZaitakuSettingByOffice(resData);
  }

  async getSettingByCondition(jigNo: string, siteiNo: string, sDate: string, eDate: string) {
    let bodyRequest = {
      jigNo,
      siteiNo,
      sDate,
      eDate
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.GET_SETTING_BY_CONDITION;
    let resData = await this.post(apiUrl, [], bodyRequest);
    return resData;
  }


  mapZaitakuSettingByOffice(data: any) {
    if (data.statuscode === 200 && data.data) {
      let listData: zaitakuSettingModel[];

      // Group data by multiple properties
      const groupedItems = groupBy(data.data, item => `${item.jigno}_${item.siteino}`);


      // Transform the grouped items array
      listData = map(groupedItems, (value, key) => (
        {
          id: uuidv4(),
          jigno: value[0].jigno,
          siteino: value[0].siteino,
          officecd: value[0].officecd,
          configs: value.map(item => {
            return { ...item, 
                      edate: item.edate != MAXIMUM_MONTH ? item.edate : "", 
                      conditions: JSON.parse(item.conditions) 
                    }
          })
        } as zaitakuSettingModel
      ));
      return listData;
    }
    return [];
  }

  async deleteSetting(id: string) {
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.DELETE_SETTING;
    apiUrl = apiUrl.replace('{id}', id);
    let resData =  await this.httpdelete(apiUrl);
    return resData;
  }

  async deletePendingInvitation(id: string) {
    let apiUrl = API_APP.NOTIFY.CONTROLLER + API_APP.NOTIFY.DELETE_PENDING_INVITATION;
    apiUrl = apiUrl.replace('{id}', id);
    let resData =  await this.httpdelete(apiUrl);
    return resData;
  }

  async getItemValuesByCondition(officecd: string, ttlkbn: string, ttlcd: string, itemid: string): Promise<any[]> {
    let bodyRequest = {
      officecd,
      ttlkbn,
      ttlcd,
      itemid
    };
    let apiUrl = API_APP.ZAITAKU.CONTROLLER + API_APP.ZAITAKU.GET_ITEMVALUES_BY_CONDITION;
    let resData = await this.post(apiUrl, [], bodyRequest);
    if (resData.statuscode === 200 && resData.data) { 
      return resData.data
    } 
    return [];
  }



  //#endregion



}
