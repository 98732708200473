<div class="widget-template">
    <div class="panel-left">
        <div class="group-items">
            <div class="title-text ">{{COMMON_TEXT.WIDGET_TEMPLATE_TITILE}}</div>
            <div class="frequently-used">
                <label class="lbl-frequently">{{COMMON_TEXT.FREQUENTLYUSED}}</label>
                <div class="group-btn-used mt-10">
                    <div class="d-flex gap-10" *ngIf="showSkeletonFrequentlyUseds && !frequentlyUseds.length">
                        <p-skeleton width="50px" height="28px"></p-skeleton>
                        <p-skeleton width="80px" height="28px"></p-skeleton>
                        <p-skeleton width="50px" height="28px"></p-skeleton>
                        <p-skeleton width="80px" height="28px"></p-skeleton>
                        <p-skeleton width="50px" height="28px"></p-skeleton>
                    </div>
                    <div class="frequently-item" *ngFor="let item of frequentlyUseds;">
                        <button class="btn-used" [pTooltip]="item"  tooltipPosition = "top" (click)="onChangeTagItem(item)">{{item}}</button>
                    </div>
                </div>  
            </div>
            <div class="input-group mt-10">
                <div class="p-inputgroup input-search" (click)="clickInputSearch($event)">
                    <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                    <pivot-textbox class="input-text" [inputParams]="inputDesParams" 
                        (onInput)="onInputSearch($event)" 
                        (onKeyUp)="onKeyupInputData($event)"
                        [inputValue]="searchText"></pivot-textbox>
                </div>
                <p-overlayPanel #op [styleClass]="'custom-overlay'">
                    <ng-template pTemplate>
                        <p-listbox [options]="allWidgets" optionLabel="name"
                            lazy="true"
                            [emptyMessage]="COMMON_TEXT.NO_DATA"
                            (onChange)="onSelectedOption($event)">
                            <ng-template let-option pTemplate="item">
                                <div class="listbox-item-img" [ngClass]="option.src"></div>
                                <div class="item-name">{{option.name}}</div>
                            </ng-template>
                        </p-listbox>
                    </ng-template>
                </p-overlayPanel>
                <button class="btn-search" (click)="findListWidgetBySearchText()">{{BUTTON.SEARCH_WIDGET}}</button>
            </div>    
            <div [ngClass]="searchList.length == 0 ?'itag-error' : ''">
                <div *ngIf="!searchList.length && showSkeletonSearchList" class="mt-15 d-flex gap-10">
                    <p-skeleton width="50px" height="31px"></p-skeleton>
                </div>
                <div class="btn-write">
                    <div class="button-tag" *ngFor="let item of searchList;">
                        <div class="button-tag-lbl"  [pTooltip]="item"  tooltipPosition = "top" (click)="removeSearchItem(item)">{{item}}</div>
                    </div>
                </div>
            </div>  

            <div *ngIf="showSkeletonDashboardNews" class="mt-25">
                <p-skeleton width="56px" height="20px"></p-skeleton>
                <div class="mt-20">
                    <div class="mt-15 d-flex gap-10">
                        <div class="w-50">
                            <p-skeleton height="28px"></p-skeleton>
                        </div>
                        <div class="w-50">
                            <p-skeleton height="28px"></p-skeleton>
                        </div>
                    </div>
                    <div class="mt-15 d-flex gap-10">
                        <div class="w-50">
                            <p-skeleton height="28px"></p-skeleton>
                        </div>
                        <div class="w-50">
                            <p-skeleton height="28px"></p-skeleton>
                        </div>
                    </div>
                </div>
            </div>
            <div class="frequently-used lastest-grp" *ngIf="!showSkeletonDashboardNews && dashboardNews.length == 4">
                <div class="title">
                    <label class="lbl-title">{{COMMON_TEXT.LASTESTRELEASE}}</label>
                    <label class="lbl-title lbl-small" *ngIf="dashboards.length > 4" (click)="onShowDialogDashboardSelect()">{{COMMON_TEXT.SHOW_MORE}}</label>
                </div>
                <div class="frequently-dashboard">
                    <div class="dashboard-new-grp" *ngFor="let item of dashboardNews;">
                        <label class="dashboard-new" [ngStyle]="{'border' : !item.isNew ? 'none' : ''}">{{item.isNew ? 'New' : ''}}</label>
                        <button class="btn-dashboard" [pTooltip]="item.dashboardname"   
                            tooltipPosition = "top"
                            (click)="onChangeDashboardItem(item.dashboardcd)">{{item.dashboardname}}
                    </button>
                    </div>
                </div>
            </div>

            <div *ngIf="!widgetHistorys" class="mt-25">
                <p-skeleton width="56px" height="20px"></p-skeleton>
                <div class="mt-20 d-flex justify-content-center gap-10">
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px"></p-skeleton>
                    </div>
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px"></p-skeleton>
                    </div>
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px"></p-skeleton>
                    </div>
                </div>
                <div class="mt-15 d-flex justify-content-center gap-10">
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px"></p-skeleton>
                    </div>
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px"></p-skeleton>
                    </div>
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px"></p-skeleton>
                    </div>
                </div>
            </div>
            <div class="group-history-chart" *ngIf="widgetHistorys && widgetHistorys.length > 0">
                <label class="label-search">{{COMMON_TEXT.RECENT_SEARCHES}}</label>
                <div class="group-chart-items">
                    <div class="chart-items" *ngFor="let widget of widgetHistorys?.slice(0,6);">
                        <div class="chart-item" (click)="getWidgetData(widget.widgetcd)">
                            <div class="icon-setting">
                                <div class="history-icon">
                                    <div class="chart-icon" [ngClass]="getChartIcon(widget, true)"></div>
                                </div>
                            </div>
                            <p class="chart-label" [pTooltip]="widget.name"  tooltipPosition = "top">{{widget.name}}</p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </div>
    <div class="panel-right">
        <div class="header">
            <div class="tile-text ">{{COMMON_TEXT.WIDGET_TEMPLATE_SEARCH}}</div>
            <!-- <pivot-text-button  class="btn-setting" [buttonType]="buttonType.PRIMARY" [buttonText]="'SAVE'">
            </pivot-text-button> -->
        </div>
        <div class="gridster-contains">

			<gridster [options]="options" #grister>
                <div *ngIf="showSkeletonWidget">
                    <div class="mt-10 d-flex gap-10" >
                        <div class="w-50">
                            <p-skeleton height="365px"></p-skeleton>
                        </div>
                        <div class="w-50">
                            <p-skeleton height="365px"></p-skeleton>
                        </div>
                    </div>
                    <div class="mt-10 d-flex gap-10" >
                        <div class="w-50">
                            <p-skeleton height="365px"></p-skeleton>
                        </div>
                        <div class="w-50">
                            <p-skeleton height="365px"></p-skeleton>
                        </div>
                    </div>
                </div>
				<ng-container *ngIf="!showSkeletonWidget">
					<gridster-item [ngClass]="widgets.length == 1? 'default-width' : ''" [item]="widget" *ngFor="let widget of widgets" (itemResize)="widgetResize($event)">
						<div class="gridster-item-body">
							<div class="gridster-item-error" *ngIf="widget.syncStatus && widget.syncStatus == syncStatusWidget.ERROR">
								<div class="error-message">
									{{DATA_UPDATE.ERROR_UPDATE_DATA_MESSAGE}}
								</div>
							</div>
							<div class="gridster-item-error" *ngIf="widget.syncStatus && widget.syncStatus == syncStatusWidget.NO_DATA">
								<div class="no-data-message">
									{{DATA_UPDATE.NO_DATA_MESSAGE}}
								</div>
							</div>
							<div class="gridster-item-content" [ngClass]="{'error':widget.syncStatus && widget.syncStatus ==  syncStatusWidget.ERROR }"[id]="widget.widgetcd">
								<div class="gridster-item-content--header" [id]="widget.datasourceCd">
                                    <div class="widget-content-label">
                                        <div style="margin: 10px 15px;" *ngIf="!widget.widgetName">
                                            <p-skeleton  width="120px" height="26px" borderRadius="20px"></p-skeleton>
                                        </div>
                                        <label class="widget-title" *ngIf="widget.widgetName">{{ widget.widgetName }}</label>
                                        <label *ngIf="widget.isNew" class="widget-title widget-new">New</label>
                                    </div>     
                                    <div class="widget-icon">
                                        <div class="arrow-img" (click)="openWidgetDetail(widget)"></div>
                                    </div>    
								</div>
								<ng-container>
									<pivot-chart
										[widgetId]="widget.widgetcd || ''" 
										[widgetdetails]="widget.widgetdetails"
										[sortParams]="widget.sortArr || []" 
										[widgetResult]="widget.widgetResult"  
										[isLoadWidgetResultComplete]="widget.isLoadWidgetResultComplete"
										[type]="widget.type" 
										[width]="widget.width" 
										[height]="widget.height" 
										[officeList]="[]" 
										[widgetConfig]="widget.widgetConfig"	
										[isHome]="true"
									>
									</pivot-chart>
								</ng-container>
								<div class="gridster-item-content--description" [ngClass]="{ 'mobile':  deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET}" *ngIf="widget.isHaveSize === false">
									<label class="lbl-date">【{{widget.startdate}}～{{widget.enddate}}】</label>
									<label class="lbl-description" [pTooltip]="getWidgetDescription(widget.widgetcd)">{{ getWidgetDescription(widget.widgetcd) }}</label>
								</div>
							</div>
						</div>
					</gridster-item>
				</ng-container>
			</gridster>
        </div>
    </div>
</div>
<pivot-dialog-select-dashboard *ngIf="isDisplayDashboardDlg" [dashboardTreeData]="dashboardTree" (onSubmitData)="onSubmitDashboardSelect($event)"></pivot-dialog-select-dashboard>