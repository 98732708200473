

<div class="ztk">
    <div class="ztk__left">
        <div class="ztk__left__office">
            <label>{{COMMON_TEXT.OFFICE}}</label>
            <pivot-search-bar [searchParams]="paramsForOffices" [placeHolderFilter]="CK_REPORT.TITLE2" (onFilterData)="onSelectOffice($event)"></pivot-search-bar>
        </div>
        <div class="ztk__left__info">
            <div class="ztk__left__info__buttons">
                <pivot-text-button (btnClick)="onAddConfig()" [buttonType]="buttonType.SECONDARY"
                    [buttonText]="CK_REPORT.ADD_CONDITION">
                </pivot-text-button>
                <pivot-text-button (btnClick)="onDeleteConfig()" [buttonType]="buttonType.SECONDARY" [disabled]="isAddConfig"
                    [buttonText]="CK_REPORT.DELETE_CONDITION">
                </pivot-text-button>
            </div>
            <div class="ztk__left__info__configs">
                <div class="ztk__left__info__configs__search">
                    <pivot-search-bar class="search-field" [searchParams]="paramsForConfigs" (onFilterData)="onSearchConfig($event)">
                    </pivot-search-bar>
                </div>
                <div class="ztk__left__info__configs__list">
                    <p-listbox optionLabel="name" [options]="filteredConfigs" [(ngModel)]="currentConfig" (onClick)="onClickConfig($event)"
                        emptyMessage="{{COMMON_TEXT.NO_DATA}}" id="listboxCols" [styleClass]="'cols1-listbox'"
                        [listStyle]="{'max-height':'calc(100vh - 305px)'}" [listStyleClass]="'cols1-style'">
                        <ng-template #itemOfListBox id="item-of-listbox" let-config pTemplate="item">
                            <div class="cols1-item">
                                <span class="cols1-item--name"> {{config.conditionname}}</span>
                            </div>
                        </ng-template>
                    </p-listbox>
                </div>
            </div>
        </div>
    </div>
    <div class="ztk__right">
        <div class="ztk__right__selectcols">
            <div class="ztk__right__selectcols__condition">
                <label>{{CK_REPORT.CONDITION_NAME}} <span class="ztk-input-required">*</span></label>
                <pivot-textbox [inputParams]="paramsForConditionName" (onFocusOut)="onInputConditionName($event)" (inputValueChange)="onChangeConditionName($event)" [inputValue]="currentConfig.conditionname || ''"></pivot-textbox>
            </div>
            <div class="ztk__right__selectcols__cols">
                <label>{{CK_REPORT.COL_LIST}} <span class="ztk-input-required">*</span></label>
                <pivot-search-bar [searchParams]="paramsForCols" [placeHolderFilter]="CK_REPORT.TITLE2" (onFilterData)="onChooseCol($event)"></pivot-search-bar>
            </div>
        </div>
        <div class="ztk__right__error" *ngIf="!validFormData.name || !validFormData.column">
            <span *ngIf="!validFormData.name" class="valid-date">{{errorMessages.name}}</span>
            <span *ngIf="!validFormData.column"  class="valid-date err-col-right">{{errorMessages.column}}</span>
        </div>
        <div class="ztk__right__option">
            <div class="ztk__right__option__datetime">
                <label>{{CK_REPORT.VALIDITY_PERIOD}} <span class="ztk-input-required">*</span></label>
                <div class="ztk__right__option__datetime__period">
                    <pivot-calendar [disabled]="false" class="input-date-dlg" [ngClass]="{ 'valid-date': !validFormData.date }"
                        [dateFormat]="dateFormat" [view]="'month'" [(value)]="this.currentConfig.sdate" (valueChange)="onChangeDate($event, 1)"
                        [canClear]="false">
                    </pivot-calendar>
                    <span class="tilde">{{CK_REPORT.TO}}</span>
                    <pivot-calendar [disabled]="false" class="input-date-dlg" [ngClass]="{ 'valid-date': !validFormData.date }"
                        [dateFormat]="dateFormat" [view]="'month'" [(value)]="this.currentConfig.edate"
                        (valueChange)="onChangeDate($event, 2)" [canClear]="false">
                    </pivot-calendar>
                </div>
            </div>
            <div class="ztk__right__option__fetchtype">
                <label>{{CK_REPORT.COUNTING_METHOD}}</label>
                <div class="ztk__right__option__fetchtype__checkbox">
                    <div class="ztk__right__option__fetchtype__checkbox--cb1">
                        <pivot-radio-button [isChecked]="currentConfig.countingtype == 1" (onClick)="onCheckedOptionChange(1)"></pivot-radio-button>
                        <label>{{CK_REPORT.NUMBER_OF_TIMES}}</label>
                    </div>
                    <div class="ztk__right__option__fetchtype__checkbox--cb2">
                        <pivot-radio-button [isChecked]="currentConfig.countingtype == 2" (onClick)="onCheckedOptionChange(2)"></pivot-radio-button>
                        <label>{{CK_REPORT.NUMBER_OF_DAYS}}</label>
                    </div>
                    <div class="ztk__right__option__fetchtype__checkbox--cb3">
                        <pivot-radio-button [isChecked]="currentConfig.countingtype == 3"  (onClick)="onCheckedOptionChange(3)"></pivot-radio-button>
                        <label>{{CK_REPORT.NUMBER_OF_DAYS_OF_USE}}</label>
                    </div>
                    <div class="ztk__right__option__fetchtype__checkbox--cb4">
                        <pivot-radio-button [isChecked]="currentConfig.countingtype == 4"  (onClick)="onCheckedOptionChange(4)"></pivot-radio-button>
                        <label>{{CK_REPORT.NUMBER_OF_USERS}}</label>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="ztk__right__error" *ngIf="!validFormData.date">
            <span class="valid-date">{{errorMessages.date}}</span>
        </div>
        <div class="ztk__right__conditionlist">
            <div class="ztk__right__conditionlist__header">
                <div class="ztk__right__conditionlist__header__left">
                    <label>{{CK_REPORT.RECORDED_ITEMS}} <span class="ztk-input-required">*</span></label>
                </div>
                <div class="ztk__right__conditionlist__header__center">
                    <label>{{CK_REPORT.CONDITION}}</label>
                </div>
                <div class="ztk__right__conditionlist__header__right">
                    <label>{{CK_REPORT.VALUE}}</label>
                </div>
            </div>
            <div class="ztk__right__conditionlist__body">
                <div class="" *ngFor="let item of currentConfig.conditions; let i = index">
                    <pivot-condition-item 
                        [treeViewList]="treeViewList" 
                        [treeViewNode]="treeViewNode"
                        [conditionItem]="item"
                        [notValidIds]="notValidIds"
                        [officecd]="selectedOffice?.officecd ?? ''"
                        (removeItem)="onRemoveConditionItem($event)"
                        (eventUpdateData)="onUpdateDataCondition($event, i)"
                    >
                    </pivot-condition-item>
                </div>
                <div class="ztk__right__error" *ngIf="!validFormData.title">
                    <span class="valid-date">{{errorMessages.title}}</span>
                </div>
                <div class="ztk__right__conditionlist__body__add">
                    <pivot-text-button class="ztk-add-btn" [iconType]="iconType.PLUS" (btnClick)="onAddConditionItem()" [buttonType]="buttonType.SECONDARY" [buttonText]="BUTTON.ADD"></pivot-text-button>
                </div>
            </div>
        </div>

        <div class="ztk__right__buttons">
            <pivot-text-button (btnClick)="onClose()" [buttonType]="buttonType.SECONDARY"
                [buttonText]="BUTTON.CANCEL">
            </pivot-text-button>
            <pivot-text-button (btnClick)="onSave(true)" [buttonType]="buttonType.SECONDARY"
                [buttonText]="BUTTON.SAVE_AND_CLOSE">
            </pivot-text-button>
            <pivot-text-button (btnClick)="onSave(false)" [buttonType]="buttonType.SECONDARY"
            [buttonText]="BUTTON.SAVE">
        </pivot-text-button>
        </div>
    </div>
</div>

 <pivot-confirm-del-selected-dialog [inputData]="delTxt" [isDisplayModal]="isDisplayModalDelete" [isCustomDialogForValidZaitaku]="isCustomDialogForValidZaitaku"
    [modalData]="modalDelete" [duplicateItems]="duplicateArray" (onSubmitData)="onSubmitConfirmDialog($event)">
</pivot-confirm-del-selected-dialog>

