<div class="ztk-treenode-item" [ngStyle]="searchParams.cssStyle">
    <div class="pivot-input-search" *ngIf="searchParams.type === 1"
        [ngClass]="{'no-border': comboInputFocus, 'required': _required}">
        <input class="pivot-input" type="text" 
            placeholder="{{searchParams.placeholder}}" 
            id={{inputSearchId}}
            [disabled]="_disable" readonly 
            [ngModel]="searchValue" 
            (click)="onClickDropdown($event)" 
            [maxlength]="searchParams.maxLength || null"
            (focus)="handleComboInputFocus($event)" 
            (focusout)="handleComboInputFocusOut($event)"
            [ngClass]="{'border-red-err': notValidIds.includes(searchParams.objectData.selectedNode.id)}"
             />
        <div class="pivot-icon dropdown-icon" [ngClass]="dspSearchPanel? 'icon-rotate': ''" (click)="onClickDropdown($event)"></div>
    </div>
    <div class="treenode-titles">
        <pivot-zaitaku-treenode-titles 
            [_displayFilter]="_displayFilter" 
            (_onHide)="onHidePanelSearch()" 
            [_tempDataFilters]="searchParams.dataSource" 
            [isFilter]="isFilter"
            [defaultValue]="searchValue" 
            [displayText]="listFilterDspText" 
            [cssStyle]="popupStyle"
            [_selectedData]="selectedData"
            [placeHolderFilter]="placeHolderFilter" 
            [objectData]="searchParams.objectData" 
            (leafNodeClick)="onClickLeafNode($event)"
            >
        </pivot-zaitaku-treenode-titles>
    </div>
</div>