import { Location } from '@angular/common';
import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import * as gridster from 'angular-gridster2';
import * as FileSaver from 'file-saver';
import * as htmlToImage from 'html-to-image';
import { cloneDeep, uniqBy } from 'lodash';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { DataSourceService } from '../../../../app/services/modules/data-source.service';
import { ButtonType, DataSourceType, DateFormat, DeviceType, DialogType, GraphType, InputType, SaveType, ScreenMode, SyncStatusWidgetResult, UserAction, WidgetSelectDataType } from '../../../..//app/enum/common-enum';
import { Dashboard, DashboardDetailModel, DashboardSetting } from '../../../..//app/models/response/dashboard.ro';
import { FOLDER_TYPE } from '../../../..//app/models/response/folder.ro';
import { DashboardService } from '../../../..//app/services/modules/dashboard.service';
import { ConfirmDialogComponent } from '../../../../app/component/common/confirm-dialog/confirm-dialog.component';
import { ROUTE_PATH } from '../../../../app/const/route-path';
import { InputParams, ModalTemplate } from '../../../../app/models/common-model';
import { Widget } from '../../../../app/models/response/widget.ro';
import { DetectDeviceService } from '../../../../app/services/detect-device.service';
import { ProcessLoadingService } from '../../../../app/services/loading.service';
import { FeatureService } from '../../../../app/services/modules/feature.service';
import { FolderService } from '../../../../app/services/modules/folder.service';
import { WidgetService } from '../../../../app/services/modules/widget.service';
import { SidebarExpandHandleService } from '../../../../app/services/sidebar-expand-handle.service';
import Utils from '../../../../app/util/utils';
import { LocalStorageHelper } from '../../../../app/_helper/local-storage.helper';
import { BUTTON, COMMON_TEXT, DASHBOARD_SETTING, WIDGET_SETTING } from '../../../../app/const/text-common';
import { MESSAGE_TEXT } from '../../../../app/const/message';
import { firstValueFrom, mergeMap, Observable, Subscription } from 'rxjs';
import { ConfirmUnsavedDataDialogComponent } from '../../../../app/component/common/confirm-unsaved-data-dialog/confirm-unsaved-data-dialog.component';
import {AuthenticationService} from 'src/app/services/authentication.service';
import { distinctBy } from 'src/app/_helper/helper';
import {DashboardHelper} from 'src/app/_helper/dashboard-helper'
import { DASHBOARD_CD_NEW } from 'src/app/const/const';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
import { WidgetResultService } from 'src/app/services/modules/widget-result.service';
import { FilterConditionRequest, WidgetResultRequest } from 'src/app/models/request/widget.dto';
import { SAUCER_LOG_ACTION, SaucerLogService } from '../../../../app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG, SCREEN_NAME } from '../../../../app/config/saucer-log.config';

@Component({
  selector: 'pivot-dashboard-setting',
  templateUrl: './dashboard-setting.component.html',
  styleUrls: ['./dashboard-setting.component.scss']
})
export class DashboardSettingComponent implements OnInit, OnDestroy, AfterContentInit {
  ScreenMode = ScreenMode;
  ButtonType = ButtonType;
  DeviceType = DeviceType;
  GraphType = GraphType;
  DialogType = DialogType;
  BUTTON = BUTTON;
  DASHBOARD_SETTING = DASHBOARD_SETTING;
  COMMON_TEXT = COMMON_TEXT;
  deviceType!: string;
  heightDashboard: string | undefined;
  dashboardCd: string = '';
  // dashboard selected
  dashboardSelected: any = null;
  // new dashboard
  newDashboard: any = null;
  // dashboard name
  dashboardName: string = '';
  // check dashboard permission
  checkPermission: boolean = false;
  gridster!: gridster.GridsterComponent;
  dashboard: gridster.GridsterItem[] = [];
  options!: gridster.GridsterConfig;
  mode: ScreenMode = ScreenMode.PREVIEW;
  titlePage: string = "";
  isDisplayConfirmDeleteModal: boolean = false;
  modalData: ModalTemplate = new ModalTemplate();
  modalAddDashboard: ModalTemplate = new ModalTemplate();
  // list feature public duplicate
  featureDuplicates: any[] = [];
  // is create dashboard
  isCreate: boolean = false;
  // list dashboard setting
  dashboardSettings: DashboardSetting[] = [];
  // current name
  currentName: string = '';
  // list dashboard
  dashboads: any[] = [];
  // list widget
  widgets: Widget[] = [];
  // list widget Remove
  widgetRemoves: DashboardDetailModel[] = [];
  // list Widget change
  widgetChanges: DashboardDetailModel[] = [];
  // enable dialog add dashboard
  isDisplayDashboardModal: boolean = false;
  // folder selected 
  folderSelected: any = null;
  // list folder
  folders: any[] = [];
  // isDuplicate status
  isDuplicate: boolean = false;
  // duplicate Data
  duplicateData: any = null;
  // new dashboardcd
  newDashboardCd: string = '';
  // list folder of user
  folderOwners: any[] = [];
  folderCreate: any = null;
  // loading chart status
  isLoading: boolean = true;
  // all widget
  allWidgets: any[] = [];
  // list dashboard detail
  listDetail: any[] = [];
  isSave: boolean = false;
  // list period
  periods: any[] = [];
  // list feature public current
  featureCurrents: any[] = [];
  saveType = SaveType;
  widgetPick: any = null;
  // is public dashboard
  // list current dashboard detail
  dashboardDetailCurrent: any[] = [];
  nameDashboarDelete: string[] = [];
  useCanDeactivate: boolean = true;
  isDisplayToolTip: boolean = false;
  isEditingWidget: boolean = false;
  isStackedChartSetting: boolean 
  delTxt : string;

  nameParams: InputParams = {
    inputStyle: { 'width': '300px' },
    placeholder: COMMON_TEXT.TEXT_INPUT,
    type: InputType.text,
    pencil: true,
    validate: false,
    disabled: false,
    readonly: false,
    maxLength: 100,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT_TEXT,
    borderFill: false,
  };

  sortData: any = null;
  isSupporterAdmin: boolean = false;
  
  listDataSourceCd:any[] = [];
  logAction: any;
  isByPassConfirmDialog: boolean = false;

  apiServiceSubscriber: Subscription | undefined;
  syncStatusWidget = SyncStatusWidgetResult ;
  routerSub: Subscription;
  backActionFromBrowser: boolean = false;
  dashboardSettingActionLog = this.mode == ScreenMode.ADD ? SAUCER_LOG_ACTION.DASHBOARD_CREATION : SAUCER_LOG_ACTION.DASHBOARD_EDITING;

  constructor(
    private router: ActivatedRoute,
    private loadingService: ProcessLoadingService,
    private routerNavigate: Router,
    private location: Location,
    private detectDeviceService: DetectDeviceService,
    private sidebarExpandHandleService: SidebarExpandHandleService,
    private dashboardService: DashboardService,
    private modalService: DialogService,
    private widgetService: WidgetService,
    private folderService: FolderService,
    private featureService: FeatureService,
    private authenticationService: AuthenticationService,
    private errorHandleService: ErrorHandleService,
    private widgetResultService: WidgetResultService,
    private saucerLogService: SaucerLogService
  ) {
    this.deviceType = this.detectDeviceService.getDeviceType();

    this.routerSub = this.routerNavigate.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
        this.backActionFromBrowser = true;
      }
    });
  }

  ngAfterContentInit(): void {
    this.setOptions(this.mode);
  }

  ngOnDestroy(): void {
    this.apiServiceSubscriber?.unsubscribe();
    this.routerSub.unsubscribe();
    let pathname = window.location.pathname;
    this.dashboads = [];
    if( this.dashboardSelected){
      this.dashboardSelected?.setting.map((x:any)=>{
        x.dataSource = [];
      })
      this.dashboardSelected.setting = [];
      this.dashboardSelected= new Dashboard();
    }
    if (!pathname?.includes(ROUTE_PATH.DASHBOARD_CREATE) &&
      !pathname?.includes(ROUTE_PATH.DASHBOARD_DETAIL) &&
      !pathname?.includes(ROUTE_PATH.WIDGET_LIST) &&
      !pathname?.includes(ROUTE_PATH.WIDGET_CREATE) &&
      !pathname?.includes(ROUTE_PATH.WIDGET_DETAIL) &&
      !pathname?.includes(ROUTE_PATH.DASHBOARD_WIDGET_TEMPLATE)) {
      this.dashboardService.setDuplicateItem(null);
      if (!pathname?.includes(ROUTE_PATH.DASHBOARD_LIST)) {
        this.dashboardService.setDashboardItem(0, undefined);
      }
      this.dashboardService.setListDataRemove([]);
      this.dashboardService.setCurrentData(null);
      this.dashboardService.setListPublicData([]);
    }
  }

  async ngOnInit() {
    this.apiServiceSubscriber = this.errorHandleService.isByPassConfirmDialog.subscribe((isSkipAllConfirmDialog: boolean) => {
      if(this.isByPassConfirmDialog != isSkipAllConfirmDialog) this.isByPassConfirmDialog = isSkipAllConfirmDialog;
    });
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    this.hiddenDashboardName(this.mode);
    this.dashboardDetailCurrent = [];
    this.loadingService.isLoading.emit(true);
    this.errorHandleService.backURLSub.next(ROUTE_PATH.DASHBOARD_LIST);
    this.sidebarExpandHandleService.events$.forEach(item => {
      this.setOptions(this.mode);
    });
    this.router.paramMap.subscribe(paramMap => {
      this.dashboardCd = paramMap.get('dashboardId') as string;
      let mode = paramMap.get('type') as unknown as ScreenMode;
      this.mode = Number(mode);
    });
    this.logAction = this.mode == ScreenMode.ADD ? SAUCER_LOG_ACTION.DASHBOARD_CREATION : SAUCER_LOG_ACTION.DASHBOARD_EDITING
    this.setModeInputName();
    await this.initData();
    this.loadWidgetContent();
    this.setOptions(this.mode);
    let action = this.mode == ScreenMode.ADD? SAUCER_LOG_ACTION.DASHBOARD_CREATION.VIEW : SAUCER_LOG_ACTION.DASHBOARD_EDITING.VIEW
    this.saucerLogService.action(
      {
        content: action.screenName + ' ＞ ' + action.content + ' (dashboardCd: ' + this.dashboardCd + ', ' + 'dashboardName: ' + this.dashboardName + ')'
      }, 
      {
        action: this.logAction.VIEW
      }
    );
    this.setTitlePage(this.mode);
    this.loadingService.isLoading.emit(false);
  }

  getListWidgetStr() {
    let widgetStrs: any[] = [];
    this.dashboardSelected?.setting?.forEach((widget: any) => {
      widgetStrs.push(widget.widgetCd);
    });
    return widgetStrs;
  }

  hiddenDashboardName(mode: any) {
    if (this.mode === ScreenMode.PREVIEW) {
      this.nameParams.hidden = true;
      this.nameParams = cloneDeep(this.nameParams);
    }
    else {
      this.nameParams.hidden = false;
      this.nameParams = cloneDeep(this.nameParams);
    }
  }

  async mapPeriodForWidget() {
    await this.widgetService.getPeriodInfor(this.getListWidgetStr(),false).then(request => {
      if (request.statuscode == 200) {
        this.periods = request.data || [];
      }
    });

    this.dashboardSelected?.setting?.map((w: any) => {
      let findPeriod = this.periods?.find(s => s.widgetcd == w.widgetCd);
      if (!Utils.isNullOrEmpty(findPeriod)) {
        w.startdate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.startdateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE): (moment(findPeriod.startdate)).format(DateFormat.JP_FULL_SHORT_DATE);
        w.enddate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.enddateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE) : (moment(findPeriod.enddate)).format(DateFormat.JP_FULL_SHORT_DATE);
      }
    });
  }

  setModeInputName() {
    if (this.mode == ScreenMode.PREVIEW) {
      this.nameParams.pencil = false;
      this.nameParams.readonly = true;
      this.nameParams = cloneDeep(this.nameParams);
    }
    else {
      this.nameParams.pencil = true;
      this.nameParams.readonly = false;
      this.nameParams = cloneDeep(this.nameParams);
    }
  }

  // load widget content
  async loadWidgetContent() {
    if (this.dashboardSelected) {
      let widgetResultData:  any[] = [];
      let widgetCds = this.dashboardSelected?.setting.map( (x: any) => x.widgetCd) as string[];

      let filterCondition = new FilterConditionRequest();
      filterCondition.isFullData = true;
      
      let requestBody = {
        widgetCds: widgetCds,
        selectType: WidgetSelectDataType.SPECIALCASE,
        isTemplate: false,
        filterCondition: filterCondition
    } as WidgetResultRequest
    if(widgetCds.length == 0) return;
      let widgetResultRes = await this.widgetResultService.getByWidgetCds(requestBody);
      if(widgetResultRes.statuscode === 200) {
        widgetResultData = widgetResultRes.data || [];
      }
      
      this.dashboardSelected?.setting?.forEach((widget: any) => {
        let sortCol = !Utils.isNullOrEmpty(widget?.sortcoltype)? JSON.parse(widget.sortcoltype) : [];
        widget.sortArr = sortCol;
        let findPeriod = this.periods?.find(s => s.widgetcd == widget.widgetCd);
        if (!Utils.isNullOrEmpty(findPeriod)) {
          widget.startdate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.startdateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE): (moment(findPeriod.startdate)).format(DateFormat.JP_FULL_SHORT_DATE);
          widget.enddate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.enddateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE) : (moment(findPeriod.enddate)).format(DateFormat.JP_FULL_SHORT_DATE);
        }
        widget.isHaveSize = false;
        let widgetResult = widgetResultData.filter((x:any) => x.widgetcd == widget.widgetCd).pop();
        if(widgetResult && widgetResult.result) {
          widget.widgetResult = widgetResult.result;
          if (widget.type == GraphType.STACKED_BAR){
            var graphConfigsString = JSON.stringify(widgetResult.result.graphConfigs); 
            widget.widgetConfig = graphConfigsString;
          }
        }
        else widget.syncStatus = this.syncStatusWidget.ERROR;
        widget.isLoadWidgetResultComplete = true;
      });
      this.dashboardSelected.setting = cloneDeep(this.dashboardSelected?.setting);
      this.isLoading = false;
    }
  }
  
  clearWidgetSetting() {
    this.widgetService.pass({ widgetCd: undefined, folderCd: '', mode: ScreenMode.ADD });
    this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
  }

  async initData() {
    await this.getDashboardDetail();
    this.dashboads = [];
    if( this.dashboardSelected){
      if(this.dashboardSelected?.setting){
        this.dashboardSelected?.setting.map((x:any)=>{
          x.dataSource = [];
        })
      }
    }
    this.newDashboard = null;
    this.widgetPick = null;
    this.widgetPick = this.widgetService.pick();
    await this.getDashboardInfo();
    // get Dashboard item
    this.newDashboard =  this.dashboardService.getDashboardItem()?.dashboard || null;
    // add dashboard for list dashboard
    if (this.newDashboard) {
      this.dashboardName = this.newDashboard.name;
      this.dashboardCd = this.newDashboard.dashboardCd;
      let findDashboard = this.dashboads?.find(d => d.dashboardCd === this.dashboardCd);
      if (findDashboard) {
        if (Utils.isNullOrEmpty(this.newDashboard?.setting)) {
          this.newDashboard.setting = findDashboard?.setting;
        }else{
          this.newDashboard?.setting.map((x: any) => {
            let widget = findDashboard?.setting?.find((d: any) => d.widgetCd === x.widgetCd);
            if(widget){
              x.startdate = widget.startdate;
              x.enddate = widget.enddate;
              x.datasourceCd = widget.datasourceCd;
              x.period = widget.period;
              x.datasourceName = widget.datasourceName;
              x.publictype = widget.publictype;
              x.sortArr = widget.sortArr;
              x.dataSource = widget.dataSource;
              x.widgetConfig = widget.widgetConfig;
              x.widgetDashboardetails = widget.widgetDashboardetails;
              x.widgetdetails = widget.widgetdetails;
            }
          })
        }
      }
      this.dashboads = [];
      this.dashboads.push(this.newDashboard);
    }
    this.dashboardSelected = cloneDeep(this.dashboads)?.find(d => d.dashboardCd === this.dashboardCd) as Dashboard;
    this.removeWidgetNotExist();
    if (!Utils.isNullOrEmpty(this.dashboardSelected)) {
      if (this.dashboardSelected?.dashboardCd) {
        if( this.dashboardSelected){
          if(this.dashboardSelected?.setting){
            this.dashboardSelected?.setting.map((x:any)=>{
              x.dataSource = [];
            })
          }
        }
        if(this.dashboardSelected.dashboadCd != DASHBOARD_CD_NEW && this.dashboardSelected.setting && this.dashboardCd  != DASHBOARD_CD_NEW){
          let dataWidget = await DashboardHelper.getWidget(this.widgets, this.widgetService, this.dashboardSelected.dashboardCd, this.dashboardSelected,false);
          this.widgets = cloneDeep(dataWidget.widgets);
          this.periods = cloneDeep(dataWidget.periods);
        }
      }
      if (!Utils.isNullOrEmpty(this.dashboardSelected?.publicCd)) {
        this.featureCurrents = await this.getListPublicData(this.dashboardSelected?.publicCd);
      }
      this.currentName = this.dashboardSelected?.name;
      if (Utils.isNullOrEmpty(this.dashboardSelected?.setting)) {
        this.dashboardSelected.setting = [];
      }
      this.allWidgets = cloneDeep(this.dashboardSelected.setting || []);
      if (this.widgetPick){
        let widgetConfigRes = await this.widgetService.getWidgetConfig(this.widgetPick.widgetCd)
        if(widgetConfigRes.statuscode === 200 && widgetConfigRes.data) {
          this.widgetPick = {
            ...this.widgetPick, 
            widgetConfig: { graphconfig: widgetConfigRes.data?.graphconfig ?? "" }}
        }
        // nếu widget vừa được chọn đã có trong allwidgets thì loại trừ nó ra
        let widgetExist = this.allWidgets?.find(w => w.widgetCd == this.widgetPick?.widgetCd);
        if(widgetExist) this.allWidgets = this.allWidgets.filter(w => w.widgetCd != widgetExist.widgetCd);
        //push widget đó ở trạng thái mới nhất vô lại allwidgets trừ những widget đã bị delete bên màn hình widget-setting
        if(this.widgetPick.widgetCd) this.allWidgets.push(this.widgetPick);
      }
      // get duplicate dashboard data
      let dulidateData = this.dashboardService.getDuplicateItem();
      if (!Utils.isNullOrEmpty(dulidateData)) {
        // duplicate list faeture public
        this.featureDuplicates = dulidateData?.featureDuplicates;
        this.dashboardName = dulidateData?.dashboard?.dashboardname;
        this.duplicateData = cloneDeep(dulidateData);
        if (this.duplicateData?.listDataChange?.length > 0) {
          this.duplicateData?.listDataChange.forEach((data: any) => {
            let findWd = this.allWidgets.find(s => s.widgetCd == data.widgetcd);
            if (findWd !== undefined) {
              this.widgets.push(findWd);
            }
          });
        }
        this.isDuplicate = true;
        // set duplicate widget
        if (this.duplicateData?.listDataChange?.length > 0) {
          this.duplicateData.listDataChange.forEach((dlcW: any) => {
            let findWdg = this.allWidgets.find(s => s.widgetCd == dlcW.widgetcd);
            if (findWdg != undefined) {
              this.dashboardSelected.publicCd = this.duplicateData?.dashboard?.publicCd || '';
              this.setDashboardItem(findWdg);
            }
          });
        }
      }
      // get dashboard name
      if (!Utils.isNullOrEmpty(this.dashboardSelected.name)) {
        this.dashboardName = this.dashboardSelected.name || '';
      }
      else {
        this.dashboardName = this.dashboardSelected?.dashboardname || '';
      }
      // get list widget new
      let widgetNews = this.dashboardService.getListNewItem() || [];
      this.dashboardService.setListNewItem([]);
      if (!Utils.isNullOrEmpty(this.widgetPick)) {
        let widgetCd = this.widgetPick?.widgetCd || '';
        this.clearWidgetSetting();
        if (widgetCd) {
          let indexWidgetNew = this.dashboardSelected?.setting?.findIndex((s: any) => s.widgetCd == widgetCd);
          if (indexWidgetNew == -1) {
            widgetNews.push(this.allWidgets.find(s => s.widgetCd == widgetCd));
          }
        }
      }
      widgetNews = uniqBy(widgetNews, 'widgetCd');
      if(this.widgetPick?.itemdelete) widgetNews = widgetNews?.filter(item => item.widgetCd != this.widgetPick.itemdelete);
      // set list widget new
      this.dashboardService.setListNewItem(widgetNews);

      if (widgetNews.length > 0) {
        // map widget new
        widgetNews?.map(widget => {
          if(widget) {
            widget.isHaveSize = false;
            this.setDashboardItem(widget);
          }
        });
        await this.mapPeriodForWidget();
      }

      if (this.dashboardSelected?.setting.length > 0) {
        this.dashboardSelected.setting = uniqBy(this.dashboardSelected.setting, 'widgetCd');
      }
      // remove widget is deleted
      let widgetSetting: any[] = [];
      this.dashboardSelected?.setting?.forEach((w: any) => {
        // tìm trong allwidget nếu tìm ra thì sẽ map lại type cho widget trong setting.
        let findWidget = this.allWidgets.find(wg => wg.widgetCd == w.widgetCd);
        if (findWidget != undefined) {
          w.type = findWidget.type;
          widgetSetting.push(w);
        }
      });
      this.dashboardSelected.setting = widgetSetting;
    }
    this.setCurrentDashboard();

  }

  async getDashboardInfo() {
    if(!this.dashboardCd) this.dashboardCd = '';
    if(this.dashboardCd  != DASHBOARD_CD_NEW){
      let dataWidgetSetting = await DashboardHelper.getSettingWidgetDetail(this.dashboardService,this.dashboardCd,false);
      if(dataWidgetSetting.dashboards) { 
        this.dashboads = dataWidgetSetting.dashboards;
        let widgetRemoves: any[] = this.dashboardService.getListDataRemove() || [];
        if(widgetRemoves.length > 0 && this.dashboads[0]?.setting?.length > 0) {
          // danh sách các widget bị xoá (những widget này đã được lưu xuống table dashboardDetail)
          let widgetOld: any[] = widgetRemoves.filter(s=>s.detailid) || [];
          // danh sách các widget bị xoá khỏi dashboard mà những dashboard này chỉ đang được lưu tạm chưa có lưu xuống dashboard detail
          let widgetNew: any[] = widgetRemoves.filter(s=>!s.detailid) || [];
          this.setListItemForDashboard(widgetOld, widgetNew);
        }
      }
      if(dataWidgetSetting.widgets) { this.widgets = dataWidgetSetting.widgets }
    }
  }

  setListItemForDashboard(widgetOld: any = [], widgetNew: any[] = []) {
    if(widgetOld.length > 0) {
      // xoá những widget cũ trong dashboard-setting
      this.dashboads[0].setting = this.dashboads[0].setting.filter((st: any) => !widgetOld?.some((wg: any) => wg.detailid === st.detailid));
    }
    if(widgetNew.length > 0) {
      // xoá những widget lưu tạm trong dashboard setting
      this.dashboads[0].setting = this.dashboads[0].setting.filter((st: any) => !widgetNew.some(wgn => wgn.widgetCd === st.widgetCd));
    }
  }

  async getFolderData() {
    this.loadingService.isLoading.emit(true);
    await this.getListFolder();
    this.folderSelected = this.folders?.find(s => s.folderCd == this.dashboardSelected.folderCd) || null;
    if (this.folderSelected?.act != 1) {
      this.folderCreate = this.folderSelected;
    }
    if (this.folderSelected?.act === UserAction.VIEW) {
      if (this.mode != 3) {
        this.navigateTo(ROUTE_PATH.DASHBOARD_LIST);
      }
    }
    if (!Utils.isNullOrEmpty(this.folderSelected)) {
      this.checkPermission = this.folderSelected.act == UserAction.VIEW ? true : false;
    }
    this.loadingService.isLoading.emit(false);
  }

  setDashboardItem(widget: any) {
    let findWgIndex = this.dashboardSelected?.setting?.findIndex((s: any) => s.widgetCd === widget.widgetCd);
    if (findWgIndex == -1) {
      this.widgets.push(widget);
      this.dashboardSelected.setting.push(widget);
    }
  }

  handleSortColData(data: any) {
		if(data) this.sortData = data;
  }

  async onGetWidgetSelect(widget: any) {
    if(!Utils.isNullOrEmpty(this.sortData) && widget) {
      this.widgets.forEach((w: any) => {
        if(widget.widgetCd == w.widgetCd) w.sortcoltype = JSON.stringify(this.sortData);
      })
    }
  }

  checkValidateDashboardName() {
    if (Utils.isNullOrEmpty(this.dashboardName)) {
      this.nameParams.validate = true;
    }
    else {
      this.nameParams.validate = false;
    }
    this.nameParams = cloneDeep(this.nameParams);
  }

  // confirm dialog save data
  async onConfirmSaveData(data: any, isNavigate: boolean = true): Promise<boolean> {
    //save data
    if (data == this.saveType.SAVE) {
      this.checkValidateDashboardName();
      if (Utils.isNullOrEmpty(this.dashboardName)) {
        return false;
      }
     
      if(this.mode == ScreenMode.ADD) {
        this.saucerLogService.action(
          {
            content: SCREEN_NAME.UNSAVED_CONFIRM_DIALOG + ' ＞ ' + this.logAction.YES_VIEW.content + ': (' + CONTENT_LOG.DASHBOARD_NAME + ": " + this.dashboardName + ')'
          }, 
          {
            action: this.logAction.YES_VIEW
          }
        );
      }
      await this.handleSaveBtnClick(isNavigate);
      if(this.mode == ScreenMode.ADD) {
        let actionSave = SAUCER_LOG_ACTION.DASHBOARD_CREATION.YES;
        this.saucerLogService.action(
          {
            content: SCREEN_NAME.UNSAVED_CONFIRM_DIALOG + ' ＞ ' + actionSave.content + ' ＞ ' + CONTENT_LOG.DASHBOARD_NAME + ": " + this.dashboardName
          }, 
          {
            action: actionSave
          }
        );
      }
     
      this.dashboardService.setListNewItem([]);
      this.setModeInputName();
    }
    // swidth mode preview
    else if (data == this.saveType.NO) {
      if (this.mode == ScreenMode.ADD) {
        this.logAction = SAUCER_LOG_ACTION.DASHBOARD_CREATION;
        this.saucerLogService.action(
          {
            content: SCREEN_NAME.UNSAVED_CONFIRM_DIALOG + ' ＞ ' + this.logAction.NO.content
          }, 
          {
            action: this.logAction.NO
          }
        );
        if (isNavigate) this.navigateTo(ROUTE_PATH.DASHBOARD_LIST);
      }
      else {
        this.dashboardName = this.currentName;
        let dash = cloneDeep(this.dashboardService.getCurrentData());
        if (!Utils.isNullOrEmpty(dash)) {
          let findDashboard = this.dashboads?.find(d => d.dashboardCd === this.dashboardCd);
          if (findDashboard != undefined) {
            if (Utils.isNullOrEmpty(dash?.setting)) {
              dash.setting = findDashboard?.setting;
            }else{
              if(dash?.setting?.length > 0) {
                let widgetLst: any[] =  [];
                // lấy danh sách widget đã lưu của dashboard
                await this.dashboardService.getWidgetList(this.dashboardCd).then(data => {
                  widgetLst = data || [];
                });
                // loại bỏ những widget chưa lưu
                dash.setting = dash.setting.filter((wd: any) => 
                  widgetLst?.some(w => w.widgetCd === wd.widgetCd)
                );
                 // map lại chart type cho widget trong dashboard setting nếu có update từ phía widget
                dash.setting?.map((item: any) => {
                  // tìm widget trong danh sách widget của dashboard nếu có thì map data
                  let widgetExist = widgetLst?.find(w => w.widgetCd == item.widgetCd);
                  if(widgetExist) {
                    item.chartType = widgetExist.chartType;
                    item.type = widgetExist.chartType;
                  }
                });
              }
            }
          }
          this.dashboardSelected = dash;
        }
        // load wwidget content
        await this.loadWidgetContent();
        this.setOptions(this.mode);
        this.clearAllSetting();

        if (!this.isDuplicate) {
          this.dashboardService.setDuplicateItem(null);
        }
        this.setModeInputName();
        this.dashboardService.setListNewItem([]);
        this.switchMode(ScreenMode.PREVIEW);
      }
      this.checkValidateDashboardName();
    }
    else {
        this.saucerLogService.action(
          {
            content: SCREEN_NAME.UNSAVED_CONFIRM_DIALOG + ' ＞ ' + this.logAction.CLOSE.content
          }, 
          {
            action: this.logAction.CLOSE
          }
        );
      this.switchMode(ScreenMode.EDIT);
    }

    return true;
  }

  clearAllSetting() {
    this.dashboardService.setListDataRemove([]);
    this.dashboardService.setListPublicData([]);
    this.dashboardService.setDuplicateItem([]);
    this.dashboardService.setListNewItem([]);
    this.dashboardService.setFeaturePublicChange(null);
  }

  getHeightDashboard() {
    if (!this.gridster) return;
    const minHeight = 'min-height: calc(100vh - 56px)';
    if (this.gridster.mobile) { return `${minHeight}`; }
    const height = this.gridster.curRowHeight * this.gridster.rows + 40;
    return `height: ${height}px; ${minHeight}; `;
  }

  getWidgetTitle(widgetCd: string = '') {
    let title = '';
    let findItem: any = this.widgets?.find((w: any) => w.widgetCd === widgetCd);
    if (!Utils.isNullOrEmpty(findItem)) {
      if (!Utils.isNullOrEmpty(findItem.name)) {
        title = findItem.name;
      }
      if (!Utils.isNullOrEmpty(findItem.widgetname)) {
        title = findItem.widgetname;
      }
    }
    else {
      let findItem: any = this.dashboardSelected?.setting?.find((w: any) => w.widgetCd === widgetCd);
      if (!Utils.isNullOrEmpty(findItem)) {
        if (!Utils.isNullOrEmpty(findItem.widgetName)) {
          title = findItem.widgetName;
        } else if(!Utils.isNullOrEmpty(findItem.name)){
          title = findItem.name;
        }
      }
    }
    return title;
  }

  getWidgetType(widgetCd: string) {
    let findItem = this.widgets.find(w => w.widgetCd === widgetCd);
    return findItem ? findItem.chartType : 0;
  }

  getWidgetDescription(widgetCd: string = '') {
    let desc = '';
    let findItem = this.widgets?.find((w: any) => w.widgetCd === widgetCd);
    if (!Utils.isNullOrEmpty(findItem)) {
      desc = findItem?.description || '';
    }
    else {
      let findItem = this.dashboardSelected?.setting?.find((w: any) => w.widgetCd === widgetCd);
      if (!Utils.isNullOrEmpty(findItem)) {
        desc = findItem?.description || '';
      }
    }
    return desc;
  }

  getWidgetWidth(widgetCd: string): number {
    let widgetElement = document.getElementById(widgetCd);
    return widgetElement ? widgetElement?.offsetWidth : 300;
  }

  getWidgetHeight(widgetCd: string): number {
    let widgetElement = document.getElementById(widgetCd);
    return widgetElement ? widgetElement?.offsetHeight : 500;
  }

  // get list dashboard detail
  async getDashboardDetail() {
    if(this.dashboardSelected?.setting) this.dashboardSelected.setting = [];
    let dashboardlLst: any = await this.findDashboard();
    this.dashboads = dashboardlLst;
    this.dashboardDetailCurrent = dashboardlLst;
  }

  async findDashboard() {
    let db: any[] = [];
    if(this.dashboardCd != "DB00000_New") {
      await this.dashboardService.getSettingDetail(this.dashboardCd).then((data: any) => {
          db = data || [];
          if(db.length == 0) {
            this.errorHandleService.backURLSub.next(ROUTE_PATH.DASHBOARD_LIST);
          }
          db.map((d: any) => {
            d.isHaveSize = true;
          });
      });
    }
    return db;
  }

  switchMode(mode: ScreenMode) {
    this.mode = mode;
    this.setOptions(mode);
    this.setTitlePage(mode);
    this.setLocationState(mode);
    this.setModeInputName();
  }

  async getListFolder() {
    this.isSupporterAdmin = await this.authenticationService.isAdminOrSupporter();
    await this.folderService.getByType(FOLDER_TYPE.Dashboard.toString(), this.isSupporterAdmin).then(data => {
      if (data.statuscode == 200) {
        this.folders = data.data || [];
        this.folderOwners = this.folders.filter(s => s.act != 1);
      }
    });
  }

  setOptions(mode: ScreenMode) {
    switch (mode) {
      case ScreenMode.PREVIEW:
        this.options = {
          compactType: gridster.CompactType.CompactUpAndLeft,
          gridType: gridster.GridType.ScrollVertical,
          defaultItemRows: 7,
          defaultItemCols: 14,
          displayGrid: gridster.DisplayGrid.None,
          disableWindowResize: false,
          enableEmptyCellClick: false,
          enableEmptyCellContextMenu: false,
          enableEmptyCellDrop: true,
          enableEmptyCellDrag: false,
          enableOccupiedCellDrop: true,
          emptyCellDragMaxCols: 50,
          emptyCellDragMaxRows: 50,
          margin: 10,
          minCols: 28,
          minRows: 10,
          maxRows: 1000,
          maxCols: 28,
          minItemRows: 4,
          minItemCols: 7,
          maxItemRows: 28,
          draggable: {
            enabled: false,
            ignoreContent: false,
            dragHandleClass: 'gridster-item-content',
          },
          resizable: {
            enabled: false,
          },
          pushItems: false,
          mobileBreakpoint: 0
        };

        break;
      case ScreenMode.ADD:
      case ScreenMode.EDIT:
        this.options = {
          compactType: gridster.CompactType.CompactUpAndLeft,
          gridType: gridster.GridType.ScrollVertical,
          defaultItemRows: 7,
          defaultItemCols: 14,
          displayGrid: gridster.DisplayGrid.Always,
          disableWindowResize: false,
          enableEmptyCellClick: false,
          enableEmptyCellContextMenu: false,
          enableEmptyCellDrop: true,
          enableEmptyCellDrag: false,
          enableOccupiedCellDrop: true,
          emptyCellDragMaxCols: 50,
          emptyCellDragMaxRows: 50,
          margin: 10,
          minCols: 28,
          minRows: 10,
          maxRows: 1000,
          maxCols: 28,
          minItemRows: 4,
          minItemCols: 7,
          maxItemRows: 28,
          draggable: {
            enabled: true,
            ignoreContent: true,
            dragHandleClass: 'gridster-item-content',
          },
          resizable: {
            enabled: true,
          },
          pushItems: true,
          mobileBreakpoint: 0
        };
        break;
      default:
        break;
    }
  }

  setTitlePage(mode: ScreenMode) {
    this.isDisplayToolTip = false;
    switch (mode) {
      case ScreenMode.PREVIEW:
        this.titlePage = this.dashboardName;
        this.nameParams.hidden = true;
        this.nameParams = cloneDeep(this.nameParams);
        this.isDisplayToolTip = true;
        break;
      case ScreenMode.EDIT:
        this.titlePage = DASHBOARD_SETTING.TITLE_EDIT;
        this.nameParams.hidden = false;
        this.nameParams = cloneDeep(this.nameParams);
        break;
      case ScreenMode.ADD:
        this.titlePage = DASHBOARD_SETTING.TITLE_CREATE;
        this.nameParams.hidden = false;
        this.nameParams = cloneDeep(this.nameParams);
        break;
      default:
        break;
    }
  }

  saveBase64Png(id: any, filename: string) {
    this.loadingService.isLoading.emit(true);
    var node = document.getElementById(id);
    if (node == null) return;
    var _self = this;
    htmlToImage.toPng(node, { width: node.scrollWidth, height: node.scrollHeight })
      .then(function (dataUrl) {
        var file = _self.dataURLtoFile(dataUrl, filename);
        FileSaver.saveAs(file);
        _self.loadingService.isLoading.emit(false);
      })
      .catch(function (error) {
        _self.loadingService.isLoading.emit(false);
      });
  }

  dataURLtoFile(dataurl: any, filename: string) {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getDashboardName(data: any) {
    this.dashboardName = data.trim();
    this.dashboardSelected.name = data.trim();
  }

  // nếu widget đã bị xoá ở màn hình widget setting thì sẽ xoá nó ra khỏi dashboard-setting
  removeWidgetNotExist() {
    // kiểm tra widget đó đã bị xoá hay không
    if(this.widgetPick?.itemdelete) {
      // xoá ra khỏi setting của dashboard
      if(this.dashboardSelected?.setting?.length > 0) this.dashboardSelected.setting = this.dashboardSelected.setting.filter((st: any) => st.widgetCd != this.widgetPick.itemdelete);
      if(this.allWidgets?.length > 0) this.allWidgets = this.allWidgets.filter(wd => wd.widgetCd != this.widgetPick.itemdelete);
      if(this.widgetChanges?.length > 0) this.widgetChanges = this.widgetChanges.filter((wd: any) => wd.widgetCd != this.widgetPick.itemdelete);
      if(this.newDashboard?.setting?.length > 0) this.newDashboard.setting = this.newDashboard.setting.filter((wd: any) => wd.widgetCd != this.widgetPick.itemdelete);
    }
  }

  setLocationState(mode: ScreenMode) {

    switch (mode) {
      case ScreenMode.PREVIEW: {
        const url = LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_DETAIL) + '/' + this.dashboardCd + '/';
        this.location.replaceState(url + ScreenMode.PREVIEW);
        break;
      }
      case ScreenMode.ADD: {
        const url = LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_CREATE) + '/' + this.dashboardCd + '/';
        this.location.replaceState(url + ScreenMode.ADD);
        break;
      }
      case ScreenMode.EDIT: {
        const url = LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_DETAIL) + '/' + this.dashboardCd + '/';
        this.location.replaceState(url + ScreenMode.EDIT);
        break;
      }
      default:
        break;
    }
  }

  mapPositionForListWidget(setting: any[]) {
    if (setting?.length > 0) {
      this.widgetChanges?.map(w => {
        let findWg = setting.find(s => s.widgetCd == w.widgetcd);
        if (findWg != undefined) {
          let widgetPosition = JSON.stringify({ x: findWg.x, y: findWg.y, rows: findWg.rows, cols: findWg.cols, width: findWg.width, height: findWg.height });
          w.widgetposition = widgetPosition;
        }
      });
    }
  }

  setCurrentDashboard() {
    let dash = this.dashboardService.getCurrentData();
    if (Utils.isNullOrEmpty(dash) || this.isSave) {
      this.dashboardService.setCurrentData(cloneDeep(this.dashboardSelected));
    }
    this.isSave = false;
  }

  backPage() {
    if (this.mode != ScreenMode.PREVIEW) {
      this.saucerLogService.action(
        
        {
          content: SCREEN_NAME.DASHBOARD_EDITING + ' ＞ ' + CONTENT_LOG.DASHBOARD_NAME + ': ' + this.dashboardName
        }, 
        {
          action: this.logAction.BACK_SCREEN
        }
      );
      const refBackPage = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
        header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
        width: '35%'
      });

      refBackPage.onClose.subscribe((x) => {
        this.onConfirmSaveData(x);
      });
    }
    else {
      this.saucerLogService.action(
        {
          content: SCREEN_NAME.DASHBOARD_EDITING + ' ＞ ' + CONTENT_LOG.DASHBOARD_NAME + ': ' + this.dashboardName
        }, 
        {
          action: SAUCER_LOG_ACTION.DASHBOARD_EDITING.BACK_SCREEN
        }
      );
      this.dashboardService.setListNewItem([]);
      if (window.location.pathname.includes(ROUTE_PATH.DASHBOARD_CREATE) ||
        window.location.pathname.includes(ROUTE_PATH.DASHBOARD_DETAIL)) {
        this.clearAllSetting();
        this.navigateTo(ROUTE_PATH.DASHBOARD_LIST);
      }
    }
  }

  getListDataChange() {
    this.setListWidgetChange();
    this.setListWidgetRemove();
    let param = {
      listDataChange: this.widgetChanges,
      listRemove: this.widgetRemoves,
      dashboard: this.dashboardService.mapDashboardModel(this.dashboardSelected)
    }
    return param;
  }

  async handleSaveBtnClick(isNavigate: boolean = false) {
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SAVE_DATA_FAIL);
    let isSaveSuccess: any = false;
    if (Utils.isNullOrEmpty(this.dashboardName)) {
      return;
    }
    if (!this.isDisabledButton()) {
      this.switchMode(ScreenMode.PREVIEW);
      this.loadingService.isLoading.emit(true);
      this.mapPositionForListWidget(this.dashboardSelected?.setting);
      // duplicate dashboard 
      if (this.isDuplicate) {
        if (!Utils.isNullOrEmpty(this.duplicateData)) {
          this.getListDataChange();
          this.dashboardName = this.dashboardSelected?.dashboardname == undefined ? this.dashboardSelected?.name == undefined ? '' : this.dashboardSelected?.name : this.dashboardSelected?.dashboardname;

          if (this.duplicateData?.dashboard?.foldercd) {
            this.dashboardSelected.folderCd = this.duplicateData?.dashboard?.foldercd;
          }

          this.dashboardSelected.description = this.dashboardSelected?.dashboarddesc == undefined ? this.dashboardSelected?.description == undefined ? '' : this.dashboardSelected?.description : this.dashboardSelected?.dashboarddesc;
          isSaveSuccess = await this.insertOrUpdateDashboard(this.dashboardSelected, false);
          this.duplicateData.dashboard.dashboardCd = this.dashboardCd;
          this.duplicateData.dashboard.id = this.dashboardSelected?.id;
          let widgetDuplicate = cloneDeep(this.widgetChanges);
          widgetDuplicate?.map(w => {
            w.widgetnew = true;
            w.isupdate = false;
          })
          this.duplicateData.listDataChange = cloneDeep(widgetDuplicate);
          this.duplicateData.listDataChange?.map((dashboard: any) => {
            dashboard.dashboardcd = this.dashboardCd;
          });
          this.duplicateData.listRemove = [];
          let publicCd = this.dashboardSelected.publicCd ? this.dashboardSelected.publicCd : '';
          this.featureDuplicates = await this.getListPublicData(publicCd);
          this.featureDuplicates?.map(fea => {
            fea.featureType = 1;
          });
          this.duplicateData.listFeaturePulic = this.featureDuplicates;
          this.updateDetails(this.duplicateData).then(d => {
            if (d.statuscode == 200) {
              this.newDashboardCd = d.data || '';
            }
          });
        }
        this.isDuplicate = false;
        this.dashboardService.setDuplicateItem(null);
      }
      // update dashboard
      else {
        // insert new dashboard 
        let dashboardItem = this.dashboardService.getDashboardItem();
        this.setOptions(this.mode);
        let findDashboard = await this.findDashboard();
        if (dashboardItem.dashboard != null && findDashboard.length == 0) {
          isSaveSuccess = await this.insertOrUpdateDashboard(dashboardItem?.dashboard, false);
        }
        else {
          // update dashboard name
          this.dashboardSelected.id = findDashboard[0]?.id;
          this.dashboardSelected.publiccd = findDashboard[0]?.publicCd;
          this.dashboardSelected.insdate = findDashboard[0]?.insdate;
          this.dashboardSelected.insstfcd = findDashboard[0]?.insstfcd;
          isSaveSuccess = await this.insertOrUpdateDashboard(this.dashboardSelected, true);
        }

        this.newDashboardCd = this.dashboardSelected?.dashboardCd || '';
        // public dashboard
        let dashboardPublicData = this.dashboardService.getFeaturePublicChange();
        if (dashboardPublicData?.listInsert?.length > 0 || dashboardPublicData?.listRemove?.length > 0) {
          dashboardPublicData.dashboardcd = this.newDashboardCd;
          await this.featureService.insertOrUpdate(dashboardPublicData);
        }
        // set param update and insert dashboard detail
        let param = this.getListDataChange();
        await this.updateDetails(param)
        this.widgetChanges = [];
        this.widgetRemoves = [];
      }
      // update sort column type
      const widgetsorts = this.widgets?.map(item => {
        return { widgetCd: item.widgetCd, sortcoltype: item.sortcoltype };
      });
      if(widgetsorts.length > 0)
        await this.widgetService.updateSortColType(widgetsorts, true);
      this.isSave = true;
      if(window.location.pathname?.includes(DASHBOARD_CD_NEW)) {
        const url = LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_DETAIL) + '/' + this.dashboardCd + '/';
        this.location.replaceState(url + ScreenMode.PREVIEW);
      }
      this.loadingService.isLoading.emit(false);
      if(isSaveSuccess) this.openDialog(DialogType.save, isNavigate);
      this.clearAllSetting();
      this.setTitlePage(this.mode);
      this.setCurrentDashboard();
    }
  }

  setListWidgetRemove() {
    this.widgetRemoves = [];
    let itemRemoves = this.dashboardService.getListDataRemove() || [];
    itemRemoves?.forEach(item => {
      let findIndex = this.widgetRemoves?.findIndex(s => s.widgetcd == item.widgetCd);
      if (findIndex == -1) {
        this.widgetRemoves.push({
          id: item.detailid,
          dashboardcd: this.dashboardCd,
          widgetcd: item.widgetCd || ''
        });
      }
    })
  }

  handleRemoveWidgetClick($event: MouseEvent | TouchEvent, item: DashboardSetting): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.widgetService.pass({ widgetCd: undefined, folderCd: '', mode: ScreenMode.ADD });
    this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
    // remove widget for list setting
    if (this.dashboardSelected?.setting?.length > 0) {
      this.dashboardSelected?.setting?.splice(this.dashboardSelected?.setting?.indexOf(item), 1);
    }
    // remove widget 
    this.removeItemForWidgetNew(item);
  }

  removeItemForWidgetNew(item: any) {
    // remove widget new
    let listWd = this.dashboardService.getListNewItem() || [];
    let wgIndex = listWd?.findIndex(s => s.widgetCd == item.widgetCd);
    if (wgIndex != -1) {
      listWd?.splice(wgIndex, 1);
    }
    this.dashboardService.setListNewItem(listWd);
    // remove item for list widget
    let indexWg = this.widgets?.findIndex(s => s.widgetCd == item.widgetCd);
    if (indexWg != -1) {
      this.widgets?.splice(indexWg, 1);
    }
    //widgetChanges
    let duplicateIndex = this.duplicateData?.listDataChange?.findIndex((w: any) => w.widgetcd == item.widgetCd);
    if (duplicateIndex != -1) {
      this.duplicateData?.listDataChange?.splice(duplicateIndex, 1);
    }
    item.delflg = 1;
    let itemRemove = this.dashboardService.getListDataRemove() || [];
    itemRemove.push(item);
    this.dashboardService.setListDataRemove(itemRemove);
    this.saucerLogService.action({
      content: JSON.stringify({
        widgetCd: item?.widgetCd,
        widgetName: (item?.widgetName || item?.name)
      })
    }, { 
			action: this.logAction.DELETE_WIDGET
		});
  }

  handleSelectTemplateBtnClick() {
    this.useCanDeactivate = false;
    this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL), this.dashboardSelected.dashboardCd, 4]);
  }

  handleCreateBtnClick() {
    this.saucerLogService.action(
      {
        content: this.logAction.ADD_WIDGET.screenName + ' ＞ ' + CONTENT_LOG.VIEW
      }, 
      {
        action: this.logAction.ADD_WIDGET
      }
    );
    let dashboardItem = this.dashboardService.getDashboardItem();
    if (!Utils.isNullOrEmpty(dashboardItem?.dashboard?.folderCd)) {
      this.dashboardSelected.folderCd = dashboardItem?.dashboard?.folderCd;
    }
    this.dashboardService.setDashboardItem(this.mode, cloneDeep(this.dashboardSelected));
    this.navigateTo(ROUTE_PATH.WIDGET_LIST);
  }

  handleUpdateBtnClick() {
    if (!this.checkPermission) {
      this.switchMode(ScreenMode.EDIT);
      this.saucerLogService.action(
        {
          content: JSON.stringify({
            dashboardCd: this.dashboardCd,
            dashboardName: this.dashboardName
          })
        }, 
        {
          action: this.logAction.EDIT_DASHBOARD
        }
      );
      this.setModeInputName();
    }
  }

  async updateDetails(param: any) {
    if(this.widgetChanges.length == 0 && this.widgetRemoves.length == 0) return;
    return  await this.dashboardService.insertOrUpdateDbDetail(param);
  }

  //insert or update dashboard info
  async insertOrUpdateDashboard(data: any, isUpdate: boolean) {
    let reqData = [];
    let result: boolean = false;
    let dash = this.dashboardService.mapDashboardModel(data);
    dash.dashboardname = this.dashboardName;
    dash.upddate = new Date();
    if (Utils.isNullOrEmpty(dash?.publiccd)) {
      dash.publiccd = data?.publiccd || '';
    }
    reqData.push(dash);
    await this.dashboardService.insertOrUpdate(reqData, isUpdate).then(request => {
      if (request.statuscode == 200) {
        this.dashboardSelected.dashboardCd = request.data || '';
        this.dashboardCd = request.data || '';
        result = true;
      }
    });
    return result;
  }

  async openDialog(dialogType: DialogType, isNavigate: boolean = true) {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    });
    this.dashboardCd = this.newDashboardCd;

    modalRef.onClose.subscribe(data => {
      switch (this.mode) {
        case ScreenMode.ADD:
          if (isNavigate) this.navigateTo(ROUTE_PATH.DASHBOARD_CREATE);
          break;

        case ScreenMode.EDIT:
        case ScreenMode.PREVIEW:
          if (isNavigate) this.navigateTo(ROUTE_PATH.DASHBOARD_DETAIL);
          break;

        default:
          break;
      }
    });
  }

  onDeleteDashboard() {
    this.nameDashboarDelete = [this.dashboardName];
    if (!this.checkPermission) {
      this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
      this.isDisplayConfirmDeleteModal = true;
      this.modalData.header = COMMON_TEXT.CONFIRM_DELETE;
      this.modalData.style = { width: '25%' }
    }
    this.delTxt = COMMON_TEXT.DASHBOARD;
  }

  async onConfirmDeleteDlg(event: any) {
    this.isDisplayConfirmDeleteModal = false;
    if (event === true) {
      this.loadingService.isLoading.emit(true);
      this.setListWidgetChange();
      this.setListWidgetRemove();
      this.widgetRemoves = this.widgetChanges;
      let param = {
        listRemove: this.widgetRemoves,
        dashboard: {
          dashboardCd: this.dashboardCd,
          dashboardName: this.dashboardName
        }
      }
      this.dashboardService.deleteDashboardAndDetail(param).then(d => {
        if (d.statuscode == 200) {
          this.modalService.open(ConfirmDialogComponent, {
            data: {
              dialogType: DialogType.delete,
            }
          });
          this.navigateTo(ROUTE_PATH.DASHBOARD_LIST);
        }
      });
      this.widgetChanges = [];
      this.widgetRemoves = [];     
      this.loadingService.isLoading.emit(false);
    }
  }

  onDownloadImage() {
    this.saucerLogService.action(
      {
        content: this.logAction.DOWNLOAD_IMAGE.screenName + " " + CONTENT_LOG.DASHBOARD_NAME + ': ' + this.dashboardName
      }, 
      {
        action: this.logAction.DOWNLOAD_IMAGE
      }
    );
    this.saveBase64Png('dashboardSettingDownload', 'dashboard-setting' + '_' + moment(Date.now()).format(DateFormat.FULL_LONG_DATE) + '.png');
  }

  async onDuplicateDashboard() {
    this.isDuplicate = true;
    if(this.folders.length == 0) await this.getFolderData();
    if (Utils.isNullOrEmpty(this.dashboardSelected)) return;
    this.newDashboard = this.dashboardSelected;
    if(this.folderOwners.findIndex(s=>s.folderCd == this.dashboardSelected.folderCd) != -1 ) {
      this.newDashboard.folderCd = this.dashboardSelected.folderCd;
    }
    else {
      this.newDashboard.folderCd = '';
      this.newDashboard.folderName = '';
    }
    this.newDashboard.name = '';
    this.newDashboard.name = this.dashboardName + COMMON_TEXT.EXTEND_COPY;
    this.modalAddDashboard.header = DASHBOARD_SETTING.CP_NEW;
    this.modalAddDashboard.style = { 'width': '557px' };
    this.modalAddDashboard.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDisplayDashboardModal = true;
    this.saucerLogService.action(
      {
        content: this.logAction.CREATE_DASHBOARD.screenName + ' ＞ ' + CONTENT_LOG.SAVE_AS + ' ＞ ' + CONTENT_LOG.DASHBOARD_NAME + ': ' + this.dashboardName + COMMON_TEXT.EXTEND_COPY
      }, 
      {
        action: this.logAction.CREATE_DASHBOARD
      }
    );
  }

  async onNewDashboard() {
    this.widgetService.pass({ widgetCd: undefined, folderCd: '', mode: ScreenMode.ADD });
    this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
    this.dashboardService.setDuplicateItem(null);
    this.dashboardService.setListPublicData([]);
    this.isDuplicate = false;
    this.widgets = [];
    this.dashboardSelected.publicCd = '';
    this.newDashboard = null;
    this.dashboardCd = '';
    this.modalAddDashboard.header = DASHBOARD_SETTING.CP_NEW;
    this.modalAddDashboard.style = { 'width': '557px' };
    this.modalAddDashboard.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isCreate = true;
    if(this.folders.length == 0) await this.getFolderData();
    this.isDisplayDashboardModal = true;
    this.saucerLogService.action(
      {
        content: this.logAction.CREATE_DASHBOARD.screenName + ' ＞ ' + CONTENT_LOG.NEW_DASHBOARD 
      }, 
      {
        action: this.logAction.CREATE_DASHBOARD
      }
    );
  }

  async editWidgetSetting(event: any, wdg: any) {
    this.saucerLogService.action({
      content: JSON.stringify({
        widgetCd: wdg?.widgetCd,
        widgetName: wdg?.widgetName || wdg?.name
      })
    }, { 
			action: this.logAction.UPDATE_WIDGET
		});
    if(this.isEditingWidget) return;
    this.isEditingWidget = true;
    await this.widgetService.getDetail(wdg.widgetCd).then(ws => {
      if (ws.statuscode == 200) {
        if (ws.data?.length > 0) {
          let dashboard = cloneDeep(this.dashboardSelected);
          if(!dashboard) dashboard = cloneDeep(this.dashboardService.getCurrentData());
          this.dashboardService.setDashboardItem(this.mode, cloneDeep(this.dashboardSelected));
          var widget = { mode: ScreenMode.EDIT, widgetCd: wdg.widgetCd, folderCd: ws.data[0]?.foldercd as string }
          this.widgetService.pass(widget);
          this.navigateTo(ROUTE_PATH.WIDGET_DETAIL);
        }
      }
    }).finally(() => this.isEditingWidget = false);
  }

  widgetResize(event: any) {
    this.setWidgetPositionAndResize(event);
  }

  setWidgetPositionAndResize(event: any) {
    let position = JSON.stringify({ "x": event?.itemComponent?.$item?.x, "y": event?.itemComponent?.$item?.y, "rows": event?.itemComponent?.$item?.rows, "cols": event?.itemComponent?.$item?.cols, "width": event?.item?.width, "height": event?.item?.height })
    if (this.dashboardSelected?.setting?.length > 0) {
      this.dashboardSelected?.setting?.map((w: any) => {
        if (w.widgetCd == event.item?.widgetCd) {
          w.widgetPosition = position;
          w.type = event?.item?.type;
          w.width = (event?.itemComponent?.width > 20) ? event?.itemComponent?.width - 20 : 500;
          w.height = (event?.itemComponent?.height > 80) ? event?.itemComponent?.height - 80 : 500;
          w.rows = event?.itemComponent?.$item?.rows;
          w.cols = event?.itemComponent?.$item?.cols;
          w.x = event?.itemComponent?.$item?.x;
          w.y = event?.itemComponent?.$item?.y;
        }
      });
    }
    this.dashboardService.setDashboardItem(this.mode, cloneDeep(this.dashboardSelected));
  }

  widgetChange(event: any) {
    this.setWidgetPositionAndResize(event);
  }

  setListWidgetChange() {
    this.widgetChanges = [];
    let settingLst = this.dashboardDetailCurrent[0]?.setting || null;
    // get list new widget
    let widgetNews = this.dashboardService.getListNewItem() || [];
    this.dashboardSelected?.setting?.forEach((item: any) => {
      let widgetNew = widgetNews.find(s => s?.widgetCd == item.widgetCd);
      let widgetPosition = JSON.stringify({ "x": item.x, "y": item.y, "rows": item.rows, "cols": item.cols, "width": item.width, "height": item.height });
      let isUpdateWg = false;
      let findWidgetCurrent = null;
      if (settingLst?.length > 0) {
        findWidgetCurrent = settingLst?.find((wd: any) => wd.widgetCd == item.widgetCd && wd.dashboardCd == item.dashboardCd);
        if (!Utils.isNullOrEmpty(findWidgetCurrent)) {
          isUpdateWg = true;
        }
      }
      // check widget new
      let findIndex = this.widgetChanges.findIndex(w => w.widgetcd == item.widgetCd);
      if (findIndex == -1) {
        this.widgetChanges.push({
          id: item.detailid,
          dashboardcd: this.dashboardCd,
          widgetcd: item.widgetCd || '',
          widgetposition: widgetPosition,
          isupdate: (this.isDuplicate == true || !Utils.isNullOrEmpty(widgetNew) || this.mode == 1) ? false : isUpdateWg,
          widgetnew: (!Utils.isNullOrEmpty(widgetNew) || this.mode == 1) ? true : false
        });
      }
      this.widgetChanges = uniqBy(this.widgetChanges, 'widgetcd');
    });
  }

  async getListPublicData(publicCd: any) {
    let listPublic: any[] = [];
    if (!Utils.isNullOrEmpty(publicCd)) {
      await this.featureService.getByType(publicCd).then(p => {
        if (p.statuscode == 200) {
          listPublic = p.data || [];
        }
      });
    }
    return listPublic;
  }

  async onChangeDashboardData(data: any) {
    this.newDashboardCd = data?.dashboardCd;
    if (data == null) {
      this.dashboardSelected.name = this.currentName;
      this.isDisplayDashboardModal = false;
    }
    else {
      this.dashboardName = data.name || '';
      if (this.isDuplicate) {
        this.loadingService.isLoading.emit(true);
        let publicCd = data.publicCd ? data.publicCd : '';
        this.featureDuplicates = await this.getListPublicData(publicCd);
        this.setListWidgetChange();
        this.setListWidgetRemove();
        this.duplicateData = {
          listDataChange: cloneDeep(this.widgetChanges),
          listRemove: cloneDeep(this.widgetRemoves),
          featureDuplicates: this.featureDuplicates,
          isduplicate: true,
          dashboard: {
            dashboardCd: data.dashboardCd,
            dashboarddesc: data.description,
            dashboardname: data.name,
            foldercd: data.folderCd,
            publicCd: publicCd
          }
        }
        this.dashboardService.setCurrentData(this.duplicateData.dashboard);
        this.dashboardService.setDuplicateItem(this.duplicateData);
        this.dashboardService.setDashboardItem(2, cloneDeep(this.duplicateData?.dashboard));
        this.widgetChanges = [];
        this.loadingService.isLoading.emit(false);
        this.switchMode(ScreenMode.ADD);
      }
      else {
        this.newDashboard = data;
        this.dashboardService.setDashboardItem(ScreenMode.ADD, cloneDeep(data));
        this.dashboardService.setDuplicateItem([]);
        if (this.isCreate) {
          this.dashboardSelected = data;
          if (Utils.isNullOrEmpty(this.dashboardSelected?.setting)) {
            this.dashboardSelected.setting = [];
          }
          this.dashboardService.setDuplicateItem(null);
          this.isCreate = false;
        }
        this.switchMode(this.mode);
      }
      this.isDisplayDashboardModal = false;

    }
  }

  isDisabledButton(): boolean {
    if (Utils.isNullOrEmpty(this.dashboardName) || this.dashboardName === "") return true;
    return false;
  }

  canDeactivate(url: string | undefined): boolean | Observable<boolean> | Promise<boolean> {
    if (this.useCanDeactivate && !this.backActionFromBrowser) {
      if(this.isByPassConfirmDialog) {
        this.errorHandleService.isByPassConfirmDialogSub.next(false);
        return true;
      };
      if (this.mode != ScreenMode.PREVIEW) {
        const ref = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
          header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
          width: '35%'
        });

        return firstValueFrom(ref.onClose.pipe(mergeMap(async x => {
          if (!x || x == SaveType.CANCEL) return false;

          return await this.onConfirmSaveData(x, false)
        })))
      }
      else {
        this.dashboardService.setListNewItem([]);
        if (window.location.pathname.includes(ROUTE_PATH.DASHBOARD_CREATE) ||
          window.location.pathname.includes(ROUTE_PATH.DASHBOARD_DETAIL)) {
          this.clearAllSetting();
          return true;
        } else {
          return false;
        }
      }
    } else {
      this.useCanDeactivate = true;
      this.backActionFromBrowser = false;
      return true;
    }
  }

  navigateTo(screen: string | undefined, commands: any[] = []) {
    this.useCanDeactivate = false;
    switch (screen) {
      case ROUTE_PATH.DASHBOARD_LIST:
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_LIST)]);
        break;
      case ROUTE_PATH.WIDGET_LIST:
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_LIST)]);
        break;
      case ROUTE_PATH.DASHBOARD_CREATE:
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_CREATE) + '/' + this.newDashboardCd + '/' + this.mode]);
        break;
      case ROUTE_PATH.DASHBOARD_DETAIL:
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_DETAIL) + '/' + this.newDashboardCd + '/' + this.mode]);
        break;
      case ROUTE_PATH.WIDGET_DETAIL:
        this.routerNavigate.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.WIDGET_DETAIL)]);
        break;
      case "URL":
        this.routerNavigate.navigate(commands);
        break;
      default:
        break;
    }

  }
}
