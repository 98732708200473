import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseData } from '../../models/api-model';
import { API_APP } from '../../config/app.config';
import { ApiService } from '../api.service';
import { ErrorHandleService } from '../error-handle.service';
import { DefaultValueSetting, DefaultValueSettingDetail, DefaultValueSettingRO } from 'src/app/models/response/default-value-setting.ro';


@Injectable({ providedIn: 'root' })
export class DefaultValueSettingService extends ApiService {

  constructor(http: HttpClient, router: Router, errorHandle: ErrorHandleService) {
    super(http, router, errorHandle);
  }

  async getAll() {
    let apiUrl = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.ALL;
    let resData = await this.get(apiUrl);
    return await this.mapModel(resData);
  }
  getTagByDf(data: any) {
    let apiUrl = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.GET_TAG_BY_DF;
    let resData =  this.post(apiUrl, [], data);
    return resData
  }
  async UpdateName(data: any) {
    let apiUrl = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.UPDATE_NAME;
    let resData = await this.post(apiUrl, [], data);
    return resData;
  }

  getTTLTagData(requestBody: any): Promise<any> {
    let apiUrl = API_APP.TITLE.CONTROLLER + API_APP.TITLE.GETTTLTAGDATA;
    return this.post(apiUrl, [], requestBody);

  }

  countTTLTagByOffices(requestBody: any): Promise<any> {
    let apiUrl = API_APP.TITLE.CONTROLLER + API_APP.TITLE.COUNTTTLTAG;
    return this.post(apiUrl, [], requestBody);

  }

  LIMIT_TITLEMAPPING = 6000;
  async getDataTTLTag(requestBody: any) {
    try {
      let countRepon = await this.countTTLTagByOffices(requestBody);
      if (countRepon.statuscode === 200 && countRepon.data) {
        let loopTotal = Math.ceil(countRepon.data / this.LIMIT_TITLEMAPPING);
        let dataRepon = await Promise.all([...Array(loopTotal).keys()].map(index => {
          let titleMappingRequest = {
            officeIds: requestBody.officeIds,
            offset: index * this.LIMIT_TITLEMAPPING,
            limit: this.LIMIT_TITLEMAPPING,
          }
          return this.getTTLTagData(titleMappingRequest);
        }));
        let data = dataRepon.map((item: any) => item.data).flat();

        let kbnTagGroups = data.reduce((group, ttlTag) => {

          let kbntagGroup = group.find((g:any) => g.kbntag == ttlTag.kbntag);
          if (!kbntagGroup) {
            kbntagGroup = { kbntag: ttlTag.kbntag, name: ttlTag.kbntag, ttltags: [] };
            group.push(kbntagGroup);
          }
          let tag = kbntagGroup.ttltags.find((g:any) => g.ttltag == ttlTag.ttltag);
          if(!tag){
            kbntagGroup.ttltags.push(ttlTag);
          }
         
          return group;
        }, []);
        
        kbnTagGroups.forEach((item: any) => {
          item.ttltags = item.ttltags.sort((a: any, b: any) => a.ttltag.localeCompare(b.ttltag, 'ja-JP-u-co-unihan'));
        });
        
        return kbnTagGroups;
      }
    } catch (ex) {}
    return [];
  }

  insertOrUpdate(request: any) {
    let uri = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.INSERT_OR_UPDATE;
    return this.post(uri, [], request);
  }


  createDsDefaultFromTemplate(request: any) {
    let uri = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.CREATE_DS_DF_FROM_TEMPLATE;
    return this.post(uri, [], request);
  }


  insertOrUpdateDatasourceDf(request: any) {
    let uri = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.INSERT_OR_UPDATE_DS_DF;
    return this.post(uri, [], request);
  }

  syncDataSourceDefaultResult(widgetCd: any,  datasourceCd: any) {
    let uri = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.SYNC_DS_DF_BY_WIDGET+ "/"+ widgetCd+ "/"+datasourceCd;
    return this.post(uri, [],{});
  }
  cloneByConstCd(constCd: string) {
    const params = [constCd];
    let endpoint = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.CLONE;
    return this.get(endpoint, params);
  }

  searchByKeyword(keyword: string) {
    const bodyData = JSON.stringify(keyword);
    let uri = API_APP.DEFAULTVALUESETTING.CONTROLLER + API_APP.DEFAULTVALUESETTING.SEARCH;
    return this.post(uri, [], bodyData);
  }

  async mapModel(data: any) {
    let res: ResponseData<DefaultValueSettingRO[]> = new ResponseData<DefaultValueSettingRO[]>();
    let dataArr:DefaultValueSettingRO[] = [];
    res.statuscode = data.statuscode;
    res.statusmessage = data.statusmessage;
    if (res.statuscode === 200) {
      for await (const element of data.data) {
        let defaultValueSetting = new DefaultValueSettingRO();
        if(element.defaultvaluesetting) {
          defaultValueSetting.defaultValueSetting = new DefaultValueSetting();
          defaultValueSetting.defaultValueSetting.id = element.defaultvaluesetting.id;
          defaultValueSetting.defaultValueSetting.defaultvaluesettingcd = element.defaultvaluesetting.defaultvaluesettingcd;
          defaultValueSetting.defaultValueSetting.defaultvaluesettingname = element.defaultvaluesetting.defaultvaluesettingname;
          defaultValueSetting.defaultValueSetting.officeidlst = element.defaultvaluesetting.officeidlst;
          defaultValueSetting.defaultValueSetting.invalidflg = element.defaultvaluesetting.invalidflg;
          defaultValueSetting.defaultValueSetting.delflg = element.defaultvaluesetting.delflg;
          defaultValueSetting.defaultValueSetting.insstfcd = element.defaultvaluesetting.insstfcd;
          defaultValueSetting.defaultValueSetting.insdate = element.defaultvaluesetting.insdate;
          defaultValueSetting.defaultValueSetting.updstfcd = element.defaultvaluesetting.updstfcd;
          defaultValueSetting.defaultValueSetting.upddate = element.defaultvaluesetting.upddate;
          if(element.listdefaultvaluesettingdetail?.length > 0) {
            defaultValueSetting.listDefaultValueSettingDetail = [];
            element.listdefaultvaluesettingdetail.map((dfDetail: any) => {
              let dfD  = new DefaultValueSettingDetail();
              dfD.id = dfDetail.id
              dfD.defaultvaluesettingcd = dfDetail.defaultvaluesettingcd
              dfD.kbntag = dfDetail.kbntag
              dfD.ttltag = dfDetail.ttltag
              dfD.delflg = dfDetail.delflg
              dfD.insstfcd = dfDetail.insstfcd
              dfD.insdate = dfDetail.insdate
              dfD.updstfcd = dfDetail.updstfcd
              dfD.upddate = dfDetail.upddate
              defaultValueSetting.listDefaultValueSettingDetail?.push(dfD);
            })
          }
          dataArr.push(defaultValueSetting)
        }
      }
    }
    res.data = dataArr;
    return res;
  }
}
