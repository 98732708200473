<div class="ztkitem">
    <div class="ztkitem__left">
        <pivot-zaitaku-treenode-item 
            [isTreeNode]="true" 
            [searchParams]="paramsForTreeNode" 
            [placeHolderFilter]="CK_REPORT.TITLE2" 
            (eventUpdateData)="onUpdateDataCondition($event)" 
            [notValidIds]="notValidIds">
        </pivot-zaitaku-treenode-item>
    </div>
    <div class="ztkitem__center">
        <pivot-search-bar 
            [searchParams]="paramsForComparison" 
            (onFilterData)="onFilterCondition($event)">
        </pivot-search-bar>
    </div>
    <div class="ztkitem__right">
        <pivot-auto-complete-item 
            [data]="itemValues" 
            [officecd]="officecd"
            [selectedItem]="conditionItem.val" 
            [conditionItem]="conditionItem"
            [keyChangeItem]="keyChangeItem"
            (onChangeItemValue)="onChangeItemValue($event)">
        </pivot-auto-complete-item>
    </div>
    <div class="ztkitem__removeitem">
        <i class="pi pi-times" (click)="onRemove(conditionItem.id)"></i>
    </div>
    </div>
