import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, orderBy } from 'lodash';
import { BUTTON, COMMON_TEXT, CREATE_DASHBOARD_TEMPLATE, DIALOG_DASHBOARD_SERVICE } from '../../../../app/const/text-common';
import { ButtonType, SearchType } from '../../../../app/enum/common-enum';
import { FilterItem, ModalTemplate, SearchParams } from '../../../../app/models/common-model';
import { DashboardService } from '../../../../app/services/modules/dashboard.service';
import { FOLDER_TYPE } from '../../../../app/models/response/folder.ro';
import { FolderService } from '../../../../app/services/modules/folder.service';
import { ProcessLoadingService } from '../../../../app/services/loading.service';

@Component({
  selector: 'pivot-dashboard-service',
  templateUrl: './dashboard-service.component.html',
  styleUrls: ['./dashboard-service.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DashboardServiceComponent implements OnInit, AfterContentChecked {
  @Input() selectedDashboardServices: Array<any> = [];
  @Input() showModal: boolean = false;
  @Input() selectedDashboard: any = null;
  @Input() data: any;
  @Output() closeModalHandler = new EventEmitter();
  @Output() selectedOfficeHandler = new EventEmitter();
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  buttonType = ButtonType;
  DIALOG_DASHBOARD_SERVICE = DIALOG_DASHBOARD_SERVICE;
  CREATE_DASHBOARD_TEMPLATE = CREATE_DASHBOARD_TEMPLATE;
  modalTemplate: ModalTemplate = new ModalTemplate();
  searchFolderParams: SearchParams = {
		type: SearchType.combo,
		cssStyle: {},
		readonly: false,
		disabled: false,
		maxLength: 100,
		defaultValue: '',
		comboDisplayText: 'name',
    placeholder: COMMON_TEXT.SELECTED
	};
  searchDashboardParams: SearchParams = {
		type: SearchType.combo,
		cssStyle: {},
		readonly: false,
		disabled: false,
		maxLength: 100,
		defaultValue: '',
		comboDisplayText: 'name',
    placeholder: COMMON_TEXT.SELECTED
	};
  isFilterAll: boolean = true;
  isShowModal: boolean = false;
  filterServices: any[] = [];
  listDashboards: any[] = [];
  listServices: any[] = [];
  loadingTable: boolean = true;
  dashboardData: any[] = [];
  dispalyFilterServices: any[] = [];
  remaningNumber: number = 0;
  filterList: Array<FilterItem> = [];
  filterModalStyle = { width: '400px', height: '300px' };
  isDisplayNumber: boolean = false;
  searchText: string = "";
  // new dashboard
  newDashboard: any = null;
  folderCreate: any = null;
  isDisplayDashboardModal: boolean = false;
  isSupporterAdmin: boolean = false;
  isLoading: boolean = false;
  isDspFolderDashboard: boolean = false;
  isDspDashboardDlg: boolean = false;
  modalFolder: ModalTemplate = new ModalTemplate();
  folderType: number = FOLDER_TYPE.Dashboard;
  folderOwners:any[] = [];
  modalAddDashboard: ModalTemplate = new ModalTemplate();
  folderSelects: any = [];

  constructor(  private folderService: FolderService,
                private loadingService: ProcessLoadingService,
                private dashboardService: DashboardService
  ) { }

    ngAfterContentChecked(): void {
      let item = document.querySelectorAll('.has-items .filter');
      if (item.length > 0) {
        let totalWidth = 0;
        item.forEach(element => {
          let item = element as HTMLElement;
          totalWidth = totalWidth + item.offsetWidth;
        });
        this.isDisplayNumber = totalWidth > 440 ? true : false;
      }
    }
  
    async ngOnInit(): Promise<void> {
      this.modalFolder.style = { 'width' : '400px' };
      this.modalTemplate.style = this.modalFolder.style;
      this.modalAddDashboard.style = { 'width' : '600px' };
      this.modalTemplate.header = COMMON_TEXT.DASHBOARD_SELECTION;
      window.addEventListener('click', (event: any) => {
        let modal = document.getElementById('filter-modal');
        let element = event.target.id;
        if (element === 'checkbox-check') {
          if (this.isShowModal) {
            this.isShowModal = true;
          }
        }
        else {
          if (!modal?.contains(event.target)) {
            let icon = document.getElementById('filter-icon');
            if (!icon?.contains(event.target)) {
              this.isShowModal = false;
            }
          }
        }
      });
      this.isLoading = true;
      if(this.data && this.data?.dashboardFolders?.length > 0) {
        this.folderOwners = this.data?.dashboardFolders || [];
        this.folderSelects = []
        if(this.data?.folderDbSelected) {
          this.folderSelects.push(this.data?.folderDbSelected)
        }
        this.setFolderData();
        this.listDashboards =  this.data?.dashboards || [];
        this.mapDashboardData();
        this.selectedDashboard = this.data?.dashboardSelected;
        this.setDashBoardData();
      }
      else {
        await this.getListFolder(false);
      }
    
      this.isLoading = false;
    }
  
    showModalFilter() {
      this.isShowModal = !this.isShowModal;
    }
  
    closeFormModal() {
      this.closeModalHandler.emit(this.folderOwners);
    }

    setDashBoardData() {
      this.mapFolderData();
      if(this.selectedDashboard) this.searchDashboardParams.selected = this.selectedDashboard?.dashboardCd;
      this.searchDashboardParams = cloneDeep(this.searchDashboardParams);
    }

    setFolderData() {
      this.searchFolderParams.dataSource = this.folderOwners?.map(x => {
        x.value = x.folderCd
        return x;
      });
      if(this.folderSelects[0]?.folderCd) this.searchFolderParams.selected = this.folderSelects[0]?.folderCd;
      this.searchFolderParams = cloneDeep(this.searchFolderParams);
    }

    async getDashboardData(folderCd: string) {
      this.isLoading = true;
      this.listDashboards = await this.dashboardService.getByFolder(folderCd);
      this.setDashBoardData();
      this.mapDashboardData();
      this.isLoading = false;
    }

    async getListFolder(isNew: boolean = false) {
      await this.folderService.getListFolderHavePermissionEdit(FOLDER_TYPE.Dashboard.toString(),this.isSupporterAdmin).then(async data => {
        if (data.statuscode == 200) {
          this.folderOwners = (data.data || []).filter(s => s.act != 1);
          if(!isNew) {
            this.setFolderData();
          } 
        }
      });
    }

    mapDashboardData() {
      this.searchDashboardParams.dataSource = this.listDashboards?.map(x => {
        x.value = x.dashboardCd
        return x;
      });
    }
  
    generateFilterService() {
      this.dispalyFilterServices = this.filterServices.map(item => (
        {
          id: item.id,
          name: item.name
        }));
      this.remaningNumber = this.filterServices.length;
    }

    getSubmitData() {
      return {
        dashboards: this.listDashboards,
        dashboardSelected: this.selectedDashboard,
        dashboardFolders: this.folderOwners,
        folderDbSelected: this.folderSelects[0]
      }
    }
  
    save() {
      let submitData = this.getSubmitData();
      this.selectedOfficeHandler.emit(submitData);
      this.closeModalHandler.emit(this.folderOwners);
    }
  
    cancel() {
      this.closeModalHandler.emit(this.folderOwners);
    }

    async onFilterFolder(folder: any) {
      if(folder) {
        this.folderSelects = [];
        this.folderSelects.push(folder[0])
        await this.getDashboardData(folder[0]?.folderCd || '')
      }
    }

    onFilterDashboard(dashboards: any) {
      if(dashboards?.length > 0) this.selectedDashboard = dashboards[0];
    }

    showDialogAddFolder() {
      this.isDspFolderDashboard = true;
    }

    mapFolderData() {
      this.searchFolderParams.dataSource = this.folderOwners?.map(x => {
        x.value = x.folderCd
        return x;
      });
    }

    async onChangeDataFolder(folder: any) {
      this.isDspFolderDashboard = false;
      if(!folder) return;
      this.isLoading = true;
      await this.getListFolder(true)
      let findFolder = this.folderOwners?.find(s=>s.name == folder?.name);
      this.folderSelects = [];
      if(findFolder) {
        this.mapFolderData();
        findFolder.value = findFolder.folderCd
        this.selectedDashboard = findFolder;
        this.folderSelects.push(findFolder)
        if(findFolder?.folderCd) this.searchFolderParams.selected = findFolder.folderCd;
        this.searchFolderParams = cloneDeep(this.searchFolderParams);
        this.searchDashboardParams.dataSource = [];
        this.searchDashboardParams = cloneDeep(this.searchDashboardParams);
      }
      this.isLoading = false;
    }

    showDialogAddDashboard() {
      this.isDspDashboardDlg = true;
    }

    async onChangeDataDashboard(dashboard: any) {
      this.isDspDashboardDlg = false;
      if(!dashboard) return;
      this.isLoading = true;
      await this.getDashboardData(dashboard.folderCd)
      let findDashboardNew = this.listDashboards?.find(db => db.name == dashboard.name);
      if(findDashboardNew) {
        this.mapDashboardData();
        this.selectedDashboard = findDashboardNew;
        if(this.selectedDashboard) this.searchDashboardParams.selected = this.selectedDashboard?.dashboardCd;
        this.searchDashboardParams = cloneDeep(this.searchDashboardParams);
      }
      this.isLoading = false;
    }
}
