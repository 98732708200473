<div class="default-value-setting-component">
  <div id="default-value-comp">
    <div class="df-pannel-left-content">
      <div class="item-list">
        <pivot-search-bar [searchParams]="searchConfig" class="search-bar">
        </pivot-search-bar>
        <!-- Default value list -->
        <div class="left-table-wrapper" [style]="{'min-height':'calc(100vh - 210px)'}">
          <div class="df-list-label-group">
            <span class="df-list-label">{{SYSTEM_SETTING.DEFAULT_VALUE_SETTING}}</span>
          </div>
          <div class="table-default-format">
            <p-listbox id="listboxRole" optionLabel="teamName" [options]="defaultValueSettingConfig.body"
              [(ngModel)]="selectedItem"  emptyMessage="{{COMMON_TEXT.NO_DATA}}"
              [styleClass]="'df-value-listbox'" [listStyle]="{'height':'calc(100vh - 330px)'}"
              [listStyleClass]="'df-value-style'">
              <!-- Custom row data in listbox -->
              <ng-template #itemOfListBox id="item-of-listbox" let-row pTemplate="item">
                <div class="row-wrapper">
                  <!-- Default row display -->
                  <div class="df-item" *ngIf="!row.isEditing"
                    [ngClass]="{'df-item': true, 'selected-item': row.isEditing}">
                    <div class="row-name-wrapper" (click)="onClickItem(row)">
                      <span ngClass="row-name" class="row-item--name">{{row.defaultvaluesettingname}}</span>
                      <span *ngIf="row?.invalidflg??false" class = "row-valid">{{DEFAULT_VALUE_SETTING.INVALID}}</span>
                      <span *ngIf="!(row?.invalidflg??false)" class = "row-valid">{{DEFAULT_VALUE_SETTING.ENABLED}}</span>
                    </div>
                    
                    <ng-template [ngIf]="row.isChecked">
                      <pivot-icon-button class="pen-btn" [styleClass]="'row-pen-btn'" (onClick)="onShowEdit(row)"
                        icon="assets/icons/row_edit_pen.svg"></pivot-icon-button>
                    </ng-template>
                    <ng-template [ngIf]="!row.isChecked">
                      <pivot-icon-button class="pen-btn" [styleClass]="'row-pen-btn'" class="hover-action"
                        icon="assets/icons/row_edit_pen.svg" (onClick)="onShowEdit(row)"></pivot-icon-button>
                    </ng-template>
                  </div>
                  <!-- Default row display -->

                  <!-- Edit mode display -->
                  <div *ngIf="row.isEditing" class="row-edit-group">
                    <pivot-textbox [inputParams]="inputParams" class="row-edit-textbox"
                      [(inputValue)]="editItem.defaultvaluesettingname" [inputId]="editItem.id ?? ''" (onClick)=resetAllCheckedItem()></pivot-textbox>
                    <div class="row-btn-group">
                      <pivot-icon-button (onClick)="onRowSaveChange()" [styleClass]="'row-function-btn confirm'"
                        [buttonType]="buttonType.SECONDARY" icon="assets/icons/row_confirm.svg"></pivot-icon-button>
                      <pivot-icon-button *ngIf="guards != GuardsMode.ADD" (onClick)="onDeleteTeam(row)"
                        [styleClass]="'row-function-btn danger'" [buttonType]="buttonType.DANGER"
                        icon="assets/icons/row_delete.svg"></pivot-icon-button>
                      <pivot-icon-button (onClick)="rowCancel(row)" [styleClass]="'row-function-btn cancel'"
                        [buttonType]="buttonType.DANGER" icon="assets/icons/row_cancel.svg"></pivot-icon-button>
                    </div>
                    <div id="lastestToScroll"></div>
                  </div>
                  <!-- Default row display -->
                </div>
              </ng-template>
              <!-- Custom row data in listbox -->
            </p-listbox>
          </div>
          <!-- Add button on bottom of list -->
          <div class="add-new-btn-wrapper">
            <pivot-icon-button (onClick)="onAddNewRow()" [styleClass]="'add_new_row_btn'" icon="assets/icons/add_new_row.svg"
              [buttonType]="buttonType.SECONDARY"></pivot-icon-button>
          </div>
        </div>
        <!-- Default value list -->
      </div>
    </div>
 
   
    <div class="df-pannel-right-content">
      <div class="office-selection">
        <div class="office-wrapper">
          <div class="folder-change">
            <span class="bold-text">{{BUTTON.OFFICE_SELECT}}</span>
            <pivot-text-button class="button" (btnClick)="showOfficeModal()" 
              [buttonType]="buttonType.SECONDARY"
              [buttonText]="BUTTON.OFFICE_SELECT"
              [disabled]="!(allOffices && allOffices.length) || guards == GuardsMode.ADD || !defaultValueSettingConfig?.body?.length || isDisableOfficeBtn"
            ></pivot-text-button>
          </div>
          <ul class="offices-list">
            <li *ngFor="let office of officeList" class="office-item" [pTooltip]="office.officeNm!"
              tooltipPosition="bottom">{{office.officeNm}}</li>
          </ul>
        </div>
      </div>
      <div class="conditon-content">
        <div class="body-content">
          <div class="checkbox-content">
            <div class="checkbox-invalid" [ngClass]="{'disabled': guards == GuardsMode.ADD || !defaultValueSettingConfig?.body?.length || isDisableOfficeBtn}" (click)="changeValid()">
              <pivot-checkbox [disabled]="(guards == GuardsMode.ADD) || (!defaultValueSettingConfig?.body?.length) || isDisableOfficeBtn" [value]="inValid"  class="checkbox" ></pivot-checkbox>
              <span class="col-index mobile-view">{{DEFAULT_VALUE_SETTING.CONDITION_INVALID}}</span>
            </div>
          </div>
          <div class="title-content">
            <div class="title-header">
              <div class="title-kbn-tag">
                {{DEFAULT_VALUE_SETTING.TITLE_CATEGORY_TAG}}
              </div>
              <div class="title-tag">
                {{DEFAULT_VALUE_SETTING.TITLE_TAG}}
              </div>
            </div>
            <div class="title-body">
              <div *ngFor="let itemCondition of listItemConditionActive" class="title-element">
                <pivot-default-value-detail [item]="itemCondition" [listkbntag]="kbnTagGroups" (onChangeItem)="onChangeItem($event)" (onDeleteItem)="onDeleteItem($event)"></pivot-default-value-detail>
              </div>
            </div>
            <div class="btn-add-content">
              <pivot-text-button 
                [iconType]="iconTypePlus" 
                [buttonType]="buttonType.SECONDARY" 
                [buttonText]="BUTTON.ADD"
                [disabled]="!officeList.length || guards == GuardsMode.ADD"
                (btnClick)="onClickBtnAdd()"
              >
              </pivot-text-button>
            </div>
          </div>
        </div>
        <div class="content-footer">
          <pivot-text-button [disabled]="guards == GuardsMode.ADD || !defaultValueSettingConfig?.body?.length || isDisableOfficeBtn" [buttonType]="buttonType.SECONDARY" (btnClick)="onClickBtnCancel()" [buttonText]="BUTTON.CANCEL">
          </pivot-text-button>
          <pivot-text-button [disabled]="guards == GuardsMode.ADD || !defaultValueSettingConfig?.body?.length || isDisableOfficeBtn" [buttonType]="buttonType.SECONDARY" (btnClick)="onCloneConstantConfig()" [buttonText]="BUTTON.COPY_DEFAULT_VALUE">
          </pivot-text-button>
          <pivot-text-button [disabled]="isDisableSaveBtn || guards == GuardsMode.ADD" [buttonType]="buttonType.PRIMARY" (btnClick)="onClickBtnSave()" [buttonText]="BUTTON.SAVE">
          </pivot-text-button>
        </div>
      </div>
      <!-- <div *ngIf="isLoading" class="loading">
        <div class="loader"></div>
      </div> -->

    </div>

  </div>
</div>

<pivot-office-modal *ngIf="isShowOffice" 
  [selectedOffices]="officeList" 
  [allOffices]="allOffices"
  (closeModalHandler)="closeOfficeModal()" 
  (selectedOfficeHandler)="changeOffices($event)">
</pivot-office-modal>