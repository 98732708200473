import { ButtonType, DateFormat } from './../../../enum/common-enum';
import { ModalTemplate } from './../../../models/common-model';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BUTTON, COMMON_TEXT } from 'src/app/const/text-common';
import * as moment from 'moment';

@Component({
  selector: 'pivot-range-date-modal',
  templateUrl: './range-date-modal.component.html',
  styleUrls: ['./range-date-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RangeDateModalComponent implements OnInit, AfterViewInit {
  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  @Input() startDate: Date = new Date();
  @Input() endDate: Date = new Date();
  @Input() appendTo: string = '';
  @Input() styleClass: string = '';
  modalTemplate: ModalTemplate = new ModalTemplate();
  buttonType = ButtonType;
  isValidDate: boolean = true;
  @Input() isDisplayEdate: boolean = true;
  minDate: Date = new Date('1900-01-01');
  maxDate: Date = new Date('2499-12-31');
  errorMessage: string = "";
  @Input() isAllowNullEndDate: boolean = false;
  @Output() rangeDateHandler = new EventEmitter<any>();
  @Output() closeModalHandler = new EventEmitter();

  ngOnInit(): void {
    this.modalTemplate.header = "ユーザー定義期間";
    this.startDate.setHours(0, 0, 0);
    this.endDate.setHours(0, 0, 0);
    this.modalTemplate.style = { 'width': 'auto'};
    this.modalTemplate.appendTo = this.appendTo;
    this.modalTemplate.styleClass = this.styleClass;
  }

  ngAfterViewInit(): void {
    this.adjustTo6Rows('rangestartdate');
    this.adjustTo6Rows('rangeenddate');
  }

  closeFormModal() {
    this.closeModalHandler.emit();
  }
  save() {
    let startDate = this.startDate! as Date;
    startDate.setHours(0, 0, 0);
    let endDate = this.endDate! as Date;
    if(endDate != null) {
      endDate.setHours(23, 59, 59);
    }

    if (this.isDisplayEdate) {
      this.rangeDateHandler.emit({ startDate: moment(startDate).format(DateFormat.FULL_DATE_HOUR), endDate: moment(endDate).format(DateFormat.FULL_DATE_HOUR) });
    } else {
      this.rangeDateHandler.emit({ startDate: moment(startDate).format(DateFormat.FULL_DATE_HOUR), endDate: null });
    }
  }
  cancel() {
    this.closeModalHandler.emit();
  }

  onChangeRangeDate(value: any, type: string) {
    if (type == "startDate") this.startDate = value;
    else this.endDate = value;
    this.isValidDate = true;
    this.errorMessage = ""
    if (this.isDisplayEdate) {
      if (!this.isAllowNullEndDate) {
        const diffDays = Math.round(moment(this.endDate, 'YYYY-MM-DD').startOf('day').hour(23).minute(59).seconds(59).diff(moment(this.startDate, 'YYYY-MM-DD').startOf('day'), 'days', true));
        if (diffDays > 365) {
          this.errorMessage = "カスタマイズにて期間を指定する場合、\n1年以内（365日以内）で開始日、終了日\nを指定してください。"
          this.isValidDate = false;
          return;
        }
      }
      const isAfter = moment(this.endDate).isSameOrAfter(this.startDate, 'day');
      if (!isAfter) {
        this.errorMessage = "開始日が終了日より後の日付を指定しています。"
        this.isValidDate = false;
        return;
      }
    }
  }

  adjustTo6Rows(elementId: string) {
    setTimeout(() => {
      let startdateElement = document.getElementById(elementId);
      let startDateContainer = startdateElement?.getElementsByClassName('p-datepicker-calendar-container')[0];
      let startDateTable = startDateContainer?.getElementsByTagName("table")[0];
      let startDateTBody = startDateTable?.getElementsByTagName('tbody')[0];
      let startDateTRow = startDateTBody?.getElementsByTagName("tr").length;
      if (startDateTRow! < 6) {
        let newRow = startDateTBody?.insertRow(-1);
        let newCell = newRow?.insertCell(0);
        newCell!.style.height = "32px"
        newCell!.colSpan = 7;
      }
    }, 0)
  }

  monthChange() {
    this.adjustTo6Rows('rangestartdate');
    this.adjustTo6Rows('rangeenddate');
  }

  setEndDate() {
    this.isDisplayEdate = true;
    this.adjustTo6Rows('rangestartdate');
    this.adjustTo6Rows('rangeenddate');
  }

  unsetEndDate() {
    this.isDisplayEdate = false;
    this.isValidDate = true;
    this.errorMessage = ""
    this.adjustTo6Rows('rangestartdate');
    this.adjustTo6Rows('rangeenddate');
  }
}
