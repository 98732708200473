import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ButtonType, SearchType } from '../../../../app/enum/common-enum';
import { ModalTemplate, SearchParams, TreeViewInputParams } from '../../../../app/models/common-model';
import { BUTTON, COMMON_TEXT } from '../../../../app/const/text-common';
import { cloneDeep } from 'lodash';
import TreeUtils from '../../../../app/util/treeUtils';

@Component({
  selector: 'pivot-dialog-select-dashboard',
  templateUrl: './dialog-select-dashboard.component.html',
  styleUrls: ['./dialog-select-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogSelectDashboardComponent implements OnInit {

  @Input() dashboardTreeData: TreeViewInputParams;
  @Output() onSubmitData = new EventEmitter();
  searchParams: SearchParams = {
    type: SearchType.input,
    placeholder: COMMON_TEXT.SEARCH_TEXT,
    cssStyle: { width: '300px', height: '40px' },
    readonly: false,
    disabled: false,
    maxLength: 100
  };

  COMMON_TEXT = COMMON_TEXT;
  modalTemplate: ModalTemplate = new ModalTemplate();
  dashboardTreeData2Level: TreeViewInputParams;
  BUTTON = BUTTON;
  buttonType = ButtonType;
  submitData: any = null;
  backupData: TreeViewInputParams;

  constructor() { }

  ngOnInit(): void {
    this.modalTemplate.header = COMMON_TEXT.TEMPLATE_SELECTION;
    this.dashboardTreeData2Level = cloneDeep(this.dashboardTreeData)
    this.dashboardTreeData2Level.nodes?.forEach(node => {
      if(node.nodes && node.nodes?.length > 0) {
        node.nodes = node.nodes.map(n => {
          n.isLoaded = true
          return n
        });
      }
    });
  }

  closeFormModal() {

  }

  handleNodeClick(node: any) {
    const isParent = this.dashboardTreeData2Level?.nodes.some(d => d === node);
    if (isParent) {
      return;
    }
    this.submitData = node.id;
  }

  onFilterOutData(searchValue: any) {
    if (!searchValue) {
      this.dashboardTreeData2Level.nodes.forEach(lv1 => {
        lv1.hidden = false;

        lv1.nodes?.forEach(lv2 => lv2.hidden = false);
      });

      return;
    }

    this.dashboardTreeData2Level.nodes.forEach(lv1 => {
      lv1.nodes?.forEach(lv2 => lv2.hidden = (!TreeUtils.checkTreeNodeLabel(lv2, searchValue) && !TreeUtils.checkTreeNodeLabel(lv1, searchValue)));

      if (!lv1.nodes || lv1.nodes.length === 0) {
        lv1.hidden = true;
      } else {
        lv1.hidden = lv1.nodes?.every(n => n.hidden);
      }
    });
  }

  showNoDataText() {
    return this.dashboardTreeData2Level?.nodes?.length === 0 || this.dashboardTreeData2Level?.nodes?.every(n => n.hidden);
  }

  cancel() {
    this.onSubmitData.emit(null);
  }

  save() {
    this.onSubmitData.emit(this.submitData);
  }
}
