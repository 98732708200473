<pivot-modal-template class="dlg-save-modal form-modal" [modalTemplate]="modalTemplate"
    (closeModalHandler)="closeFormModal()">
    <div class="modal-body" modal-body *ngIf="step == 1">
        <label>{{DIALOG_CONFIRM.CONFIGURE_INFOMATION}}</label>
        <div class="row">
            <label class="lbl-text">{{DIALOG_CONFIRM.OFFICE}} </label>: <label class="lbl-content">{{data?.officeLabel}}</label>
        </div>
        <div class="row">
            <label class="lbl-text">{{DIALOG_CONFIRM.PERIOD}} </label>: <label class="lbl-content">{{data?.periodName}}</label>
        </div>
        <div class="row">
            <label class="lbl-text">{{DIALOG_CONFIRM.FOLDER_DATASOURCE}} </label>: <label class="lbl-content">{{'【'+ data?.folderDatasource + '】'}}</label>
        </div>
        <div class="row">
            <label class="lbl-text">{{DIALOG_CONFIRM.DATASOURCE_NAME}} </label>: <label class="lbl-content">{{'【'+ data?.datasourceName + '】'}}</label>
        </div>
        <div class="row">
            <label class="lbl-text">{{DIALOG_CONFIRM.DASHBOARD_NAME}} </label>: <label class="lbl-content">{{'【'+ data?.forderWidget+ '】'}}</label>
        </div>
        <div class="row">
            <label class="lbl-text">{{DIALOG_CONFIRM.WIDGET_NAME}} </label>: <label class="lbl-content">{{'【'+ data?.widgetName+ '】'}}</label>
        </div>
        <div class="row">
            <label class="lbl-text">{{DIALOG_CONFIRM.FOLDER_WIDGET}} </label>: <label class="lbl-content">{{'【'+ data?.dashboardName+ '】'}}</label>
        </div>
    </div>
    <div class="modal-body" modal-body *ngIf="step == 2">
        <label class="radio-label label-top" >
            <pivot-radio-button [styleClass]="'widget-update-radio-btn'" [isChecked]="!isSaveData" (onClick)="changeOption(1)">
            </pivot-radio-button>
            <span>{{DIALOG_CONFIRM.DEFAULT}}</span>
        </label>
        <div class="select-option">
            <div class="option dashboard-icon"></div>
            <label class="label">{{DIALOG_CONFIRM.MOVE_TO_DASHBOARD}}</label>
        </div>
        <label class="radio-label" >
            <pivot-radio-button [styleClass]="'widget-update-radio-btn'" [isChecked]="isSaveData" (onClick)="changeOption(2)">
            </pivot-radio-button>
            <span>{{DIALOG_CONFIRM.CUSTOMIZATION}}</span>
        </label>
        <div class="select-option">
            <div class="option widget-icon"></div>
            <label class="label">{{DIALOG_CONFIRM.MOVE_TO_WIDGET}}</label>
        </div>
    </div>
    <div class="modal-bottom" modal-bottom>
        <pivot-text-button (btnClick)="step == 1? cancel(): back()" [buttonType]="buttonType.SECONDARY" [buttonText]=" step == 1? BUTTON.CANCEL : BUTTON.RETURN">
        </pivot-text-button>
        <pivot-text-button (btnClick)="step == 1? next() : save()" [buttonType]="buttonType.PRIMARY" [buttonText]="step == 1? BUTTON.NEXT :BUTTON.SETTING">
        </pivot-text-button>

    </div>
</pivot-modal-template>